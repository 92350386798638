import { SeeMore } from '../../util/SeeMore'

export class PlayerBioModule extends SeeMore {
  connectedCallback () {
    this.cacheElements()
    this.setListeners()
    this.showContent()
  }

  showContent () {
    const contentHeight = this.content.offsetHeight
    if (contentHeight > 110) {
      this.setAttribute('data-show-content', 'true')
    }

    if (contentHeight <= 110) {
      this.setAttribute('data-show-content', 'false')
    }
  }
}
