export const setStorageItem = (storageType, storageName, storageValue) => {
  if (!storageType.getItem(storageName)) {
    storageType.setItem(storageName, `["${storageValue}"]`)
    return storageName
  } else {
    const storageArray = storageType.getItem(storageName)
      ? JSON.parse(storageType.getItem(storageName))
      : []

    if (storageArray.indexOf(storageValue) === -1) {
      storageArray.push(storageValue)
    }

    storageType.setItem(storageName, JSON.stringify(storageArray))
    return storageArray
  }
}

export const removeStorageItem = (storageType, storageName, storageValue) => {
  if (storageType.getItem(storageName)) {
    const storageArray = JSON.parse(storageType.getItem(storageName))

    const newstorageItems = storageArray.filter(ele => {
      return ele !== storageValue
    })
    storageType.setItem(storageName, JSON.stringify(newstorageItems))
    return newstorageItems
  }
}
