/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */
import React from 'react'
import StatsTableApp from './statstable/StatsTableApp'

export class StatsTable extends HTMLElement {
  connectedCallback () {
    const apiEndpoint = this.getAttribute('data-endpoint-url')
    const baseRound = this.getAttribute('data-default-round')
    const baseCategory = this.getAttribute('data-default-category')
    const ignoreCategory = this.getAttribute('data-ignore-category')

    Promise.all([import('react-dom')])
      .then(([ReactDOM]) => {
        ReactDOM.render(
          <StatsTableApp
            apiEndpoint={apiEndpoint}
            baseRound={baseRound}
            baseCategory={baseCategory}
            ignoreCategory={ignoreCategory}
          />,
          this
        )
      })
      .catch(e => console.warn('Error loading react', e))
  }
}
