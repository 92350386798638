import GetImage from '../util/GetImage'

const PromoHighlight = props => {
  const {
    data,
    bestParam,
    handleModalOpen,
    index,
    selectedVideos,
    setHoleSelect
  } = props

  return (
    <div
      className="PromoHighlight"
      data-url={bestParam ? data.videoUri + bestParam : data.videoUri}
      data-hole={data.hole}
      onClick={() => {
        setHoleSelect(data.hole)
        handleModalOpen({
          hole: data.hole,
          highlightVideo: false,
          bestShotsVideo: selectedVideos === 'best4',
          allVideo: selectedVideos === 'all4',
          videoIndex: index
        })
      }}
    >
      {data && (
        <div className="PromoHighlight-media">
          {data.thumbnail && (
            <svg className="play-overlay">
              <use href="#play-overlay" />
            </svg>
          )}
          {data.thumbnail && (
            <img data-flickity-lazyload={GetImage.src(data.thumbnail)} />
          )}
        </div>
      )}
      {data.title && <div className="PromoHighlight-title">{data.title}</div>}
    </div>
  )
}

export default PromoHighlight
