import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink
} from '@apollo/client'

import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist'
import localForage from 'localforage'
import { sha256 } from 'crypto-hash'
import TickerLeaderboardModuleEntry from './TickerLeaderboardModuleEntryReact'

export class TickerLeaderboardReact extends HTMLElement {
  connectedCallback () {
    const apiEndpoint = this.getAttribute('data-endpoint-url')
    const eventId = this.getAttribute('data-event-id')
    const refreshInterval = this.getAttribute('data-refresh-interval')
    const loadingText = this.getAttribute('data-loading-text')

    const cache = new InMemoryCache()

    persistCache({
      cache,
      storage: new LocalStorageWrapper(localForage),
      maxSize: false,
      trigger: 'write',
      debug: true
    }).then(() => {
      const link = ApolloLink.from([
        createPersistedQueryLink({ sha256, useGETForHashedQueries: true }),
        new HttpLink({
          uri: `${apiEndpoint}`,
          credentials: 'omit',
          withCredentials: true,
          headers: {
            'Accept-Encoding': 'gzip'
          }
        })
      ])
      const client = new ApolloClient({
        link: link,
        cache,
        connectToDevTools: true
      })
      Promise.all([import('react-dom')])
        .then(([ReactDOM]) => {
          ReactDOM.render(
            <ApolloProvider client={client}>
              <TickerLeaderboardModuleEntry
                eventId={eventId}
                refreshInterval={refreshInterval}
                loadingText={loadingText}
              />
            </ApolloProvider>,
            this
          )
        })
        .catch(e => console.warn('Error loading Leaderboard Ticker react', e))
    })
  }
}
