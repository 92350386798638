import React, { useEffect, useRef, useState } from 'react'
import { debounce } from 'debounce'
import Flickity from 'react-flickity-component'
import PromoHighlight from './PromoHighlightReact'

const ListCarousel = props => {
  const flkty = useRef(null)
  const {
    data,
    bestParam,
    selectedVideos,
    handleModalOpen,
    modalScorecard,
    setHoleSelect
  } = props
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth
  })
  const prevNextButtonsShow = data.length > 4 || false
  function resizeCarousel () {
    setScreenSize({ width: window.innerWidth })
    if (typeof flkty !== 'undefined') {
      flkty.current.resize()
    }
  }
  useEffect(() => {
    const debouncedHandleResize = debounce(150, resizeCarousel, true)
    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [screenSize])

  useEffect(() => {
    flkty.current.resize()
  }, [selectedVideos])

  return (
    <React.Fragment>
      <div className="ListCarousel-items" data-length={data && data.length}>
        <Flickity
          className={'ListCarousel'}
          elementType={'div'}
          flickityRef={carouselRef => {
            flkty.current = carouselRef
          }}
          options={{
            pageDots: false,
            lazyLoad: 4,
            adaptiveHeight: true,
            imagesLoaded: true,
            prevNextButtons: prevNextButtonsShow,
            reloadOnUpdate: true,
            cellAlign: 'left'
          }}
        >
          {data &&
            data.map((item, i) => (
              <div className="ListCarousel-item" key={i}>
                <PromoHighlight
                  imageSize="promoHighlight"
                  data={item}
                  bestParam={bestParam || false}
                  handleModalOpen={handleModalOpen}
                  index={i}
                  selectedVideos={selectedVideos}
                  modalScorecard={modalScorecard}
                  setHoleSelect={setHoleSelect}
                />
              </div>
            ))}
        </Flickity>
      </div>
    </React.Fragment>
  )
}

export default ListCarousel
