export class ScorecardStatProgress extends window.HTMLElement {
  connectedCallback () {
    const percentage = this.querySelector(
      '[data-progress-percentage]'
    ).getAttribute('data-progress-percentage')
    if (percentage !== '') {
      const remainderPercentage = 100 - percentage

      this.querySelector('[data-progress-remainder]').setAttribute(
        'data-progress-percentage',
        remainderPercentage
      )
    } else {
      return
    }

    this.querySelectorAll('[data-progress-percentage]').forEach(item => {
      const radius = item.r.baseVal.value
      this.circumference = radius * 2 * Math.PI
      const percentage = item.getAttribute('data-progress-percentage')
      item.style.strokeDasharray = `${this.circumference} ${this.circumference}`
      item.style.strokeDashoffset = `${this.circumference}`
      setTimeout(() => {
        if (item.hasAttribute('data-progress-remainder')) {
          const offsetStroke = this.setProgress(percentage, this.circumference)
          item.style.strokeDashoffset = -offsetStroke
        } else {
          const offsetStroke = this.setProgress(percentage, this.circumference)
          item.style.strokeDashoffset = offsetStroke
        }
      }, 250)
    })
  }

  setProgress (percent, circumference) {
    return circumference - (percent / 100) * circumference
  }

  disconnectedCallback () {
    return false
  }
}
