export class SeeMore extends window.HTMLElement {
  connectedCallback () {
    this.cacheElements()
    this.setListeners()
  }

  cacheElements () {
    this.button = this.querySelector('[data-see-trigger]')
    this.content = this.querySelector('[data-see-content]')
    this.clickHandler = this.buttonClick.bind(this)
  }

  setListeners () {
    this.button.addEventListener('click', this.clickHandler)
  }

  showContent () {
    this.setAttribute('data-show-content', 'true')
  }

  buttonClick () {
    this.showContent()
  }

  disconnectedCallback () {
    this.button.removeEventListener('click', this.clickHandler)
  }
}
