export class TickerPlayer extends window.HTMLElement {
  get leaderboardTicker () {
    return this.closest('.LeaderboardTicker')
  }

  get favorites () {
    return this.leaderboardTicker.querySelectorAll('[data-favorite]')
  }

  get lastFavorite () {
    return this.leaderboardTicker.querySelector('[data-favorite-last]')
  }

  connectedCallback () {
    this.cacheElements()
    this.setListeners()
  }

  cacheElements () {
    this.favoriteBtn = this.querySelector('[data-favorites-trigger]')
    this.playerId = this.getAttribute('data-player-id')
    this.eventId = this.getAttribute('data-event-id')
    this.favoritesStorageName = `playerFavorites-${this.eventId}`
  }

  setListeners () {
    this.favoriteBtn.addEventListener(
      'click',
      (this._bindFavorite = e => {
        e.preventDefault()
        e.stopPropagation()

        if (this.getAttribute('data-favorites-state') === 'true') {
          this.removeFavorite(this.playerId)
          this.setAttribute('data-favorites-state', false)
          this.removeStorageItem(this.favoritesStorageName, this.playerId)
        } else {
          this.setAttribute('data-favorites-state', true)
          const playerIndex = this.getAttribute('data-index')
          const clonedPlayer = this.cloneNode(true)
          this.addFavorite(clonedPlayer, playerIndex)
          this.setStorageItem(this.favoritesStorageName, this.playerId)
        }
      }),
      false
    )
  }

  setStorageItem (storageName, storageValue) {
    const storage = window.localStorage
    if (!storage.getItem(storageName)) {
      storage.setItem(storageName, `["${storageValue}"]`)
      return storageName
    } else {
      const storageArray = storage.getItem(storageName)
        ? JSON.parse(storage.getItem(storageName))
        : []

      if (storageArray.indexOf(storageValue) === -1) {
        storageArray.push(storageValue)
      }

      storage.setItem(storageName, JSON.stringify(storageArray))
      return storageArray
    }
  }

  removeStorageItem (storageName, storageValue) {
    const storage = window.localStorage
    if (storage.getItem(storageName)) {
      const storageArray = JSON.parse(storage.getItem(storageName))

      const newstorageItems = storageArray.filter(function (ele) {
        return ele !== storageValue
      })
      storage.setItem(storageName, JSON.stringify(newstorageItems))
      return newstorageItems
    }
  }

  favoritePlacement (player, playerIndex) {
    this.removeLastFavoriteStyle()

    if (!this.favorites.length) {
      this.leaderboardTicker.carouselEl.insertBefore(
        player,
        this.leaderboardTicker.carouselEl.firstChild
      )
    } else {
      for (const item of this.favorites) {
        if (
          playerIndex <= parseInt(item.firstChild.getAttribute('data-index'))
        ) {
          item.before(player)
          this.setLastFavoriteStyle()
          return
        } else {
          item.after(player)
        }
      }
    }
    this.setLastFavoriteStyle()
  }

  removeLastFavoriteStyle () {
    if (this.lastFavorite !== null) {
      this.lastFavorite.removeAttribute('data-favorite-last')
    }
  }

  setLastFavoriteStyle () {
    if (this.favorites !== null && this.favorites.length !== 0) {
      this.favorites[this.favorites.length - 1].setAttribute(
        'data-favorite-last',
        ''
      )
    }
  }

  wrapPlayerEl (el) {
    el.removeAttribute('data-player')
    const wrappedPlayer = document.createElement('div')
    wrappedPlayer.classList.add('LeaderboardTicker-player')
    wrappedPlayer.setAttribute('data-favorite', '')
    wrappedPlayer.innerHTML = el.outerHTML
    return wrappedPlayer
  }

  addFavorite (player, playerIndex) {
    this.leaderboardTicker.destroyTicker()
    this.favoritePlacement(this.wrapPlayerEl(player), playerIndex)
    this.leaderboardTicker.initCarousel()
  }

  removeFavorite (id) {
    const removedPlayerEls = this.leaderboardTicker.querySelectorAll(
      `[data-favorites-state="true"][data-player-id="${id}"]`
    )
    const favoriteEl = this.leaderboardTicker.querySelector(
      `[data-favorite] [data-player-id="${id}"]`
    ).parentNode
    if (favoriteEl !== null) {
      this.leaderboardTicker.destroyTicker()
      this.removeLastFavoriteStyle()
      // Just hiding it for now because if clicking on the player in the favorite list
      // the element no longer exists so the methods below blow up. We will remove the element
      // after this methods are run
      favoriteEl.setAttribute('data-removed', '')
      favoriteEl.removeAttribute('data-favorite', '')
      if (removedPlayerEls !== null) {
        removedPlayerEls.forEach((item, i) => {
          item.removeAttribute('data-favorites-state')
        })
      }
      this.setLastFavoriteStyle()
      this.leaderboardTicker.initCarousel()
      favoriteEl.remove()
    }
  }

  disconnectedCallback () {
    this.favoriteBtn.removeEventListener('click', this._bindFavorite)
  }
}
