import React from 'react'
const TickerPlayerLoading = () => {
  return (
    <React.Fragment>
      <div className="TickerPlayer">
        <div className="TickerPlayer-main">
          <div className="TickerPlayer-position loading-position" />
          <div className="TickerPlayer-image loading-image" />
          <div className="TickerPlayer-info">
            <React.Fragment>
              <div className="TickerPlayer-name loading-name" />
              <div className="TickerPlayer-data loading-data" />
            </React.Fragment>
          </div>
        </div>
        <div className="Favorite-button">
          <svg className="star-off">
            <use href="#star-inactive" />
          </svg>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TickerPlayerLoading
