export class FitText extends window.HTMLElement {
  connectedCallback () {
    const el = this
    this.options = JSON.parse(this.getAttribute('data-fittext-options'))

    this.settings = Object.assign(
      {
        minSize: 16,
        maxSize: 100,
        multiLine: true
      },
      this.options
    )

    setTimeout(() => {
      this.fitText(el)
    }, 250)

    window.addEventListener(
      'resize',
      (this._resize = e => {
        this.fitText(el)
      }),
      false
    )

    window.addEventListener(
      'orientationchange',
      (this._orientationChange = e => {
        this.fitText(el)
      }),
      false
    )
  }

  computeStyle (el) {
    // get style properties
    const style = window.getComputedStyle(el, null)

    // get current font size in pixels (if we already calculated it, use the calculated version)
    this.settings.currentFontSize = parseInt(
      style.getPropertyValue('font-size'),
      10
    )

    // get display type and wrap mode
    this.settings.display = style.getPropertyValue('display')
    this.settings.whiteSpace = style.getPropertyValue('white-space')
  }

  fitText (el) {
    this.computeStyle(el)
    // get available width from parent node
    this.settings.availableWidth = el.parentNode.clientWidth
    // the space our target element uses
    this.settings.currentWidth = el.scrollWidth
    // remember current font size
    this.settings.previousFontSize = this.settings.currentFontSize

    // calculate the new font size
    this.settings.currentFontSize = Math.min(
      Math.max(
        this.settings.minSize,
        (this.settings.availableWidth / this.settings.currentWidth) *
          this.settings.previousFontSize
      ),
      this.settings.maxSize
    )

    // if allows wrapping, only wrap when at minimum font size (otherwise would break container)
    this.settings.whiteSpace =
      this.settings.multiLine &&
      this.settings.currentFontSize === this.settings.minSize
        ? 'normal'
        : 'nowrap'

    el.style.cssText = `${this.settings.originalStyle};white-space:${
      this.settings.whiteSpace
    };display:${this.settings.display};font-size:${
      this.settings.currentFontSize
    }px`
  }

  disconnectedCallback () {
    window.removeEventListener('resize', this._resize)
    window.removeEventListener('orientationchange', this._orientationChange)
  }
}
