/* eslint-disable no-trailing-spaces */
import React, { useState, useEffect } from 'react'
import Select from './components/Select'

export default function StatsTableApp (props) {
  const [loading, setLoading] = useState(true)
  const [stats, setStats] = useState({ rows: [], selects: [], header: [] })
  const [error, setError] = useState(null)
  const roundParam = '&selectedRound='
  const categoryParam = '&selectedCategory='
  const [roundParamValue, setRoundsParam] = useState(
    props.baseRound ? `${roundParam}${props.baseRound}` : ''
  )
  const [categoryParamValue, setCategoryParam] = useState(
    props.baseCategory ? `${categoryParam}${props.baseCategory}` : ''
  )
  const GET_RESULTS_URL = props.apiEndpoint
  const IGNORE_CATEGORY = props.ignoreCategory

  useEffect(() => {
    fetch(`${GET_RESULTS_URL}${roundParamValue}${categoryParamValue}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(json => {
        setStats(json.statTable)
        for (let i = 0; i < json.statTable.header.length; i++) {
          if (json.statTable.header[i].selected) {
            // sorts the table based on the default selected header
            // after switching rounds or categories
            requestSort(json.statTable.header[i].id, true)
          }
        }
        if (json.error) {
          setError(json.errorMessage)
        }
        if (json.statTable === null) {
          setError(true)
        }
        setLoading(false)
      })
      .catch(errorMsg => {
        setError(errorMsg)
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roundParamValue, categoryParamValue])

  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config)
    const getValue = (row, key) => {
      for (let i = 0; i < row.values.length; i++) {
        const value = row.values[i]
        if (value.columnId === key) {
          return value.sortValue
        }
      }
      return null
    }

    const sortedItems = React.useMemo(() => {
      const sortableItems = [...items]
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          const key = sortConfig.key
          const aSortValue = getValue(a, key)
          const bSortValue = getValue(b, key)
          if (aSortValue < bSortValue) {
            return sortConfig.direction === 'ascending' ? -1 : 1
          }
          if (aSortValue > bSortValue) {
            return sortConfig.direction === 'ascending' ? 1 : -1
          }
          return 0
        })
      }
      return sortableItems
    }, [items, sortConfig])

    const requestSort = (key, selectUsed) => {
      let direction = 'ascending'
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending'
      }

      if (selectUsed) {
        direction = 'ascending'
      }

      setSortConfig({ key, direction })
    }
    return { rows: sortedItems, requestSort, sortConfig }
  }

  const { rows, requestSort, sortConfig } = useSortableData(stats.rows)

  const getClassNamesFor = (id, selected) => {
    // if sortConfig is null we know it's the initial load, so we want to run
    // the request sort to trigger the sort and place the selected class
    if (sortConfig === null && typeof selected !== 'undefined' && selected) {
      requestSort(id)
    }

    if (!sortConfig) {
      return
    }

    return sortConfig.key === id ? sortConfig.direction : undefined
  }

  const handleRoundChange = val => {
    setRoundsParam(`${roundParam}${val}`)
  }

  const handleCategoryChange = val => {
    setCategoryParam(`${categoryParam}${val}`)
  }

  const hasSelects = selects => {
    if (typeof selects !== 'undefined' && selects !== null) {
      return (
        <div className="StatsTable-filters">
          <Select select={selects.rounds} changeParams={handleRoundChange} />
          {IGNORE_CATEGORY !== 'true' ? (
            <Select
              select={selects.categories}
              changeParams={handleCategoryChange}
            />
          ) : null}
        </div>
      )
    }
  }

  if (loading) {
    return (
      <div className="StatsTable-loading">
        <span className="sr-only">Loading</span>
      </div>
    )
  }

  if (error !== null) {
    return (
      <div className="error">
        <p>
          Unfortunately, there was an error. Please try again later.
          <br />
          {error.message && `Error Message: ${error.message}`}
        </p>
      </div>
    )
  }

  return (
    <React.Fragment>
      {hasSelects(stats.selects)}
      <div className="StatsTable-wrapper">
        <table className="StatsTable-table">
          <thead>
            <tr>
              {stats.header.map((header, i) => (
                <th key={i} className={header.name}>
                  <button
                    type="button"
                    onClick={() => requestSort(header.id)}
                    className={getClassNamesFor(header.id, header.selected)}
                  >
                    {header.name}
                  </button>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => (
              <tr key={i}>
                {row.values.map((player, index) => (
                  <td className={player.columnName} key={index}>
                    {player.value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  )
}
