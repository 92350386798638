export class MPXVideoPlayer extends window.HTMLElement {
  connectedCallback () {
    this.playerId = this.getAttribute('data-player-id')
    this.src = this.getAttribute('data-player-src')
    this.debug = this.getAttribute('data-debug')
    this.iframeContainer = this.querySelector('[data-iframe-container]')

    this.generateIframe()
  }

  joinOrCreateParameters (parameterString, key, value) {
    if (typeof parameterString === 'undefined') {
      parameterString = ''
    }

    if (
      typeof key === 'undefined' ||
      key === '' ||
      typeof value === 'undefined' ||
      value === ''
    ) {
      return parameterString
    }

    if (parameterString !== '') {
      parameterString += '&'
    }

    parameterString += key + '=' + value

    return parameterString
  }

  updateQueryStringParameter (uri, key, value) {
    const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
    const separator = uri.indexOf('?') !== -1 ? '&' : '?'
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + '=' + value + '$2')
    } else {
      return uri + separator + key + '=' + value
    }
  }

  generateIframe () {
    const iframe = document.createElement('iframe')

    let parameterString = ''
    if (
      typeof window._fw_vcid2_key !== 'undefined' &&
      window._fw_vcid2_key !== ''
    ) {
      parameterString = this.joinOrCreateParameters(
        parameterString,
        '_fw_vcid2',
        window._fw_vcid2_key
      )
    }

    if (typeof window.csid_key !== 'undefined' && window.csid_key !== '') {
      parameterString = this.joinOrCreateParameters(
        parameterString,
        'csid',
        window.csid_key
      )
    }

    if (typeof window.sfid_key !== 'undefined' && window.sfid_key !== '') {
      parameterString = this.joinOrCreateParameters(
        parameterString,
        'sfid',
        window.sfid_key
      )
    }

    if (typeof this.debug !== 'undefined' && this.debug !== '') {
      parameterString = this.joinOrCreateParameters(
        parameterString,
        'debug',
        this.debug
      )
    }

    if (parameterString !== '') {
      this.src = this.updateQueryStringParameter(
        this.src,
        'params',
        encodeURIComponent(parameterString)
      )
    }

    console.log(this.src)

    iframe.id = this.playerId
    iframe.src = this.src
    iframe.allow = 'autoplay; fullscreen; encrypted-media; picture-in-picture'
    iframe.frameBorder = '0'
    iframe.setAttribute('allowfullscreen', '')
    this.iframeContainer.appendChild(iframe)
  }
}
