import React from 'react'
import MpsAdModule from './MpsAdModuleReact'
import CoachModule from './CoachModuleReact'
import LeaderboardRow from './LeaderboardRowReact'

const liveCoachPromo = document.getElementById('liveCoachingPromo')

const LeaderboardTable = props => {
  const {
    timestamp,
    lastUpdatedTimestamp,
    rows,
    currentRound,
    favorites,
    eventStatus,
    tableCaption,
    // playoffRows,
    eventId,
    favoritesTable,
    dataFavoritesShow,
    disableShotlink,
    disableHeadshots,
    disableScorecardMedia,
    golfers,
    legend,
    round1Tees,
    round2Tees,
    round3Tees,
    round4Tees,
    shotlinkImages,
    multiCourse,
    affiliations,
    myTime
  } = props

  const thead1 = 'Fav'
  const thead2 = 'Pos'
  const thead3 = 'Mov'
  const thead4 = 'Player'
  const thead5 = 'Ovr'
  const thead6 = 'Thru'
  const thead7 = 'Rnd'
  const thead8 = 'R1'
  const thead9 = 'R2'
  const thead10 = 'R3'
  const thead11 = 'R4'
  const thead12 = 'Total'
  const thead13 = 'Video'

  const CoachPromoLive = () => {
    const clonedCoachTemplate = liveCoachPromo.content.cloneNode(true)
    const liveCoachHtml = clonedCoachTemplate.querySelector(
      '.CoachingLivePromo'
    ).innerHTML
    return (
      <div
        className="CoachingLivePromo"
        dangerouslySetInnerHTML={{
          __html: [liveCoachHtml]
        }}
      />
    )
  }

  return (
    <React.Fragment>
      {multiCourse &&
        legend &&
        legend.length > 0 &&
        legend[0] !== null &&
        legend.map((item, i) => (
          <style
            key={i}
            dangerouslySetInnerHTML={{
              __html: [
                `[data-courseid="${
                  item && item.courseId ? item.courseId : null
                }"] .LeaderboardRow-data:before, [data-courseid="${
                  item && item.courseId ? item.courseId : null
                }"]:before {`,
                '  content: " ";',
                `  background-color: ${item && item.color ? item.color : null}`,
                '}'
              ].join('\n')
            }}
          />
        ))}
      <div
        className="LeaderboardTable"
        data-favorites-show={dataFavoritesShow || null}
        data-favorites-source={!favorites.length || null}
        data-favorites-target={favorites.length > 0 || null}
        data-current-round={currentRound || null}
        data-disable-video={disableScorecardMedia}
      >
        <div className="LeaderboardTable-headRow">
          <div className="LeaderboardTable-headData tdcol-1">{thead1}</div>
          <div className="LeaderboardTable-headData tdcol-2">{thead2}</div>
          <div className="LeaderboardTable-headData tdcol-3">{thead3}</div>
          <div className="LeaderboardTable-headData tdcol-4">{thead4}</div>
          <div className="LeaderboardTable-headData tdcol-5">{thead5}</div>
          <div className="LeaderboardTable-headData tdcol-6">{thead6}</div>
          <div className="LeaderboardTable-headData tdcol-7">{thead7}</div>
          <div className="LeaderboardTable-headData tdcol-8">{thead8}</div>
          <div className="LeaderboardTable-headData tdcol-9">{thead9}</div>
          <div className="LeaderboardTable-headData tdcol-10">{thead10}</div>
          <div className="LeaderboardTable-headData tdcol-11">{thead11}</div>
          <div className="LeaderboardTable-headData tdcol-12">{thead12}</div>
          {!disableScorecardMedia && (
            <div className="LeaderboardTable-headData tdcol-13">{thead13}</div>
          )}
        </div>
        <div className="LeaderboardTable-caption">
          <div className="LeaderboardTable-headInfo">
            <span className="LeaderboardTable-captionData1">
              {tableCaption}
            </span>
            {legend &&
              legend.length > 0 &&
              legend[0] !== null &&
              !favoritesTable && (
                <div className="LeaderboardTable-courseKey">
                  <span className="LeaderboardTable-courseKey-label">
                    Course Key:
                  </span>
                  <div className="LeaderboardTable-courseKey-key">
                    {legend.map((item, i) => (
                      <span
                        className="LeaderboardTable-courseKey-name"
                        key={i}
                        data-courseid={
                          item && item.courseId ? item.courseId : null
                        }
                      >
                        {item && item.name ? item.name : null}
                      </span>
                    ))}
                  </div>
                </div>
            )}
          </div>
        </div>
        {rows &&
          rows.map((row, i) => {
            return row.ad ? (
              <div className="LeaderboardRow-ad" data-ad key={i}>
                <div className="LeaderboardRow-ad-row">
                  <div className="LeaderboardRow-ad-data">
                    <MpsAdModule row={row} />
                  </div>
                </div>
              </div>
            ) : row.coachItem ? (
              <div className="LeaderboardRow-coach" key={i}>
                {liveCoachPromo ? (
                  <CoachPromoLive />
                ) : (
                  <CoachModule row={row} />
                )}
              </div>
            ) : row.cutline ? (
              <div className="LeaderboardTable-cutLine" key={i}>
                <strong className="LeaderboardTable-cutText">
                  {row.cutline.text || null}
                </strong>
              </div>
            ) : (
              <LeaderboardRow
                timestamp={timestamp}
                lastUpdatedTimestamp={lastUpdatedTimestamp}
                index={i}
                row={row}
                eventId={eventId}
                winner={row.winner || null}
                key={row.golferId}
                favorites={favorites}
                currentRound={currentRound}
                disableShotlink={disableShotlink}
                disableHeadshots={disableHeadshots}
                disableScorecardMedia={disableScorecardMedia}
                eventStatus={eventStatus}
                round1Tees={round1Tees}
                round2Tees={round2Tees}
                round3Tees={round3Tees}
                round4Tees={round4Tees}
                shotlinkImages={shotlinkImages}
                multiCourse={multiCourse}
                golferStaticAssets={
                  golfers.filter(golfer => golfer.golferId === row.golferId) ||
                  null
                }
                affiliations={affiliations}
                myTime={myTime}
              />
            )
          })}
      </div>
    </React.Fragment>
  )
}

export default LeaderboardTable
