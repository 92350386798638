import React, { useState, useEffect } from 'react'
import Flickity from 'react-flickity-component'
import Canvas from './Canvas'
const ShotLinkHole = props => {
  const { shotData, holeImages, teeInfo } = props

  const [isCourseImageLoaded, setIsCourseImageLoaded] = useState(false)
  const [isGreenImageLoaded, setIsGreenImageLoaded] = useState(false)
  const [greenImage, setGreenImage] = useState(null)
  const [courseImage, setCourseImage] = useState(null)
  const [zoomIn, setZoomIn] = useState(false)

  const courseImageData =
    holeImages && holeImages.holeImage ? holeImages.holeImage.size : {}
  const greenImageData =
    holeImages && holeImages.greenImage ? holeImages.greenImage.size : {}

  useEffect(() => {
    const courseImageToBeLoaded = new Image()
    const greenImageToBeLoaded = new Image()

    courseImageToBeLoaded.src = courseImageData.src
    greenImageToBeLoaded.src = greenImageData.src
    courseImageToBeLoaded.onload = () => {
      setCourseImage(courseImageToBeLoaded)
      setIsCourseImageLoaded(true)
    }

    greenImageToBeLoaded.onload = () => {
      setGreenImage(greenImageToBeLoaded)
      setIsGreenImageLoaded(true)
    }
  }, [holeImages, courseImageData.src, greenImageData.src])
  if (!holeImages) {
    return null
  }

  const filteredShotData = shotData
    ? shotData.filter(item => item.shotType !== 'penalty')
    : null
  const holeCoords = getShots(filteredShotData, 'course')
  const greenCoords = getShots(filteredShotData, 'green')

  const desktopCanvasHeight = 334
  const desktopCanvasWidth = 736

  function getShots (shotList, mapType) {
    const shotCoordinates = []
    let startX = ''
    let startY = ''

    if (!shotList[0]) {
      return null
    }

    if (mapType === 'course') {
      startX = shotList[0].fromX
      startY = shotList[0].fromY
    } else {
      startX = shotList[0].fromGreenX
      startY = shotList[0].fromGreenY
    }

    shotCoordinates.push({
      x: parseFloat(startX),
      y: parseFloat(startY)
    })

    if (filteredShotData) {
      filteredShotData.forEach(shot => {
        let shotX = ''
        let shotY = ''

        if (mapType === 'course') {
          shotX = shot.toX
          shotY = shot.toY
        } else {
          shotX = shot.toGreenX
          shotY = shot.toGreenY
        }

        shotCoordinates.push({
          x: parseFloat(shotX),
          y: parseFloat(shotY)
        })
      })
    }

    return shotCoordinates
  }

  const drawToCourseMap = context => {
    context.drawImage(
      courseImage,
      0,
      0,
      desktopCanvasWidth,
      desktopCanvasHeight
    )
    plotCoordinates(context, holeCoords)
  }

  const drawToGreenMap = context => {
    context.drawImage(greenImage, 0, 0, desktopCanvasWidth, desktopCanvasHeight)
    plotCoordinates(context, greenCoords)
  }

  function plotCoordinates (context, coordinates) {
    if (!coordinates) {
      return null
    }

    context.beginPath()

    const newXCoordinate = AxisCalcCoordinate(
      coordinates[0].x,
      desktopCanvasWidth
    )
    const newYCoordinate = AxisCalcCoordinate(
      coordinates[0].y,
      desktopCanvasHeight
    )

    context.moveTo(newXCoordinate, newYCoordinate)
    coordinates.forEach((item, i) => {
      if (i > 0) {
        drawLine(context, item.x, item.y)
      }
    })

    coordinates.forEach((shot, shotCount) => {
      if (shotCount === 0) {
        drawTee(context, shot.x, shot.y)
      }
      if (shotCount > 0) {
        drawPin(context, shot.x, shot.y)
        drawNumber(context, shot.x, shot.y, shotCount)
      }
    })
  }

  function AxisCalcCoordinate (coordinate, imageDimension) {
    if (coordinate === 0 || imageDimension === 0) {
      return coordinate
    }
    return coordinate * imageDimension
  }

  function drawLine (canvas, xPin, yPin) {
    const newXCoordinate = AxisCalcCoordinate(xPin, desktopCanvasWidth)
    const newYCoordinate = AxisCalcCoordinate(yPin, desktopCanvasHeight)

    canvas.lineWidth = 2
    canvas.lineTo(newXCoordinate, newYCoordinate)
    canvas.strokeStyle = 'black'
    canvas.stroke()
  }

  function drawTee (canvas, xPin, yPin) {
    const newXCoordinate = AxisCalcCoordinate(xPin, desktopCanvasWidth)
    const newYCoordinate = AxisCalcCoordinate(yPin, desktopCanvasHeight)

    canvas.beginPath()
    canvas.moveTo(newXCoordinate, newYCoordinate)
    canvas.arc(newXCoordinate, newYCoordinate, 2, 0, 2 * Math.PI)
    canvas.fillStyle = 'gray'
    canvas.closePath()
    canvas.fill()
  }

  function drawPin (canvas, xPin, yPin) {
    const newXCoordinate = AxisCalcCoordinate(xPin, desktopCanvasWidth)
    const newYCoordinate = AxisCalcCoordinate(yPin, desktopCanvasHeight)

    canvas.save()
    canvas.translate(newXCoordinate, newYCoordinate)
    canvas.beginPath()
    canvas.moveTo(0, 0)
    canvas.bezierCurveTo(3, -15, -30, -37.5, 0, -45)
    canvas.bezierCurveTo(30, -37.5, -3, -15, 0, 0)
    canvas.fillStyle = 'black'
    canvas.fill()
    canvas.strokeStyle = 'black'
    canvas.lineWidth = 1.5
    canvas.stroke()
    canvas.beginPath()
    canvas.arc(0, -21, 3, 0, Math.PI * 2)
    canvas.closePath()
    canvas.fillStyle = 'black'
    canvas.fill()
    canvas.restore()
  }

  function drawNumber (canvas, xPin, yPin, index) {
    const newXCoordinate = AxisCalcCoordinate(xPin, desktopCanvasWidth)
    const newYCoordinate = AxisCalcCoordinate(
      yPin - 30 / desktopCanvasHeight,
      desktopCanvasHeight
    )

    canvas.font = '18px Arial'
    canvas.fillStyle = 'white'
    canvas.textAlign = 'center'
    canvas.textBaseline = 'middle'
    canvas.fillText(index, newXCoordinate, newYCoordinate)
  }

  return (
    <React.Fragment>
      <div className="ShotlinkHole">
        <div className="ShotlinkHole-header">
          <div className="ShotlinkHole-holeInfo">
            {teeInfo && (
              <React.Fragment>
                <span className="ShotlinkHole-par">Par {teeInfo.par}</span>
                <span className="ShotlinkHole-yards">
                  {teeInfo.yardage} yds
                </span>
                <span className="ShotlinkHole-avg" />
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="ShotlinkHole-content">
          {isCourseImageLoaded && (
            <div
              className="ShotlinkHole-maps"
              data-toggle="maps"
              data-toggle-in={zoomIn ? true : null}
            >
              <button
                className="ShotlinkHole-mapsTrigger"
                data-toggle-trigger="maps"
                onClick={() => setZoomIn(!zoomIn)}
              >
                <svg className="zoom-in">
                  <use xlinkHref="#zoom-in-reverse" />
                </svg>
                <svg className="zoom-out">
                  <use xlinkHref="#zoom-out-reverse" />
                </svg>
              </button>
              <div className="ShotlinkHole-course">
                {isCourseImageLoaded ? (
                  <Canvas
                    draw={drawToCourseMap}
                    width={desktopCanvasWidth}
                    height={desktopCanvasHeight}
                  />
                ) : (
                  ''
                )}
              </div>
              <div className="ShotlinkHole-green">
                {isGreenImageLoaded ? (
                  <Canvas
                    draw={drawToGreenMap}
                    width={desktopCanvasWidth}
                    height={desktopCanvasHeight}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          )}
          <Flickity className={'ShotlinkHole-playByplay'} elementType={'ul'}>
            {shotData.map((shot, i) => (
              <li className={'ShotlinkHole-play'} key={i}>
                <div className={'ShotlinkHole-play-text'}>
                  {shot.playByPlay}
                </div>
              </li>
            ))}
          </Flickity>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ShotLinkHole
