import { useQuery } from '@apollo/client'
import MiniLeaderboardModule from './MiniLeaderboardModuleReact'
import LASTUPDATED from './../../../../../graphql/versions/v2/queries/scoring/lastupdated.graphql'
import React from 'react'

const MiniLeaderboardModuleEntry = props => {
  const { loadingText, eventId, loadingImage, numRows } = props
  let refreshInterval = props.refreshInterval ? props.refreshInterval * 1000 : 0

  const {
    error: lastUpdatedError,
    data: lastUpdatedData,
    loading: lastUpdatedLoading,
    stopPolling: stoplastUpdatedPolling
  } = useQuery(LASTUPDATED, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    pollInterval: refreshInterval,
    variables: {
      id: eventId
    }
  })

  if (lastUpdatedLoading) {
    return (
      <div className="LeaderboardModule-loading">
        <img src={loadingImage || null} alt="loading" />
        <p>{loadingText || null}</p>
      </div>
    )
  }

  const { timestamp, status } = lastUpdatedData.lastUpdated

  // if an event is completed, stop polling the lastUpdated query and set refreshInterval to 0
  if (
    (status && status === 'Scores Official') ||
    (status && status === 'Completed')
  ) {
    stoplastUpdatedPolling()
    refreshInterval = 0
  }

  return (
    <React.Fragment>
      {lastUpdatedError && console.error(lastUpdatedError)}
      <MiniLeaderboardModule
        timestamp={timestamp}
        eventId={eventId}
        loadingText={loadingText}
        loadingImage={loadingImage}
        numRows={numRows}
      />
    </React.Fragment>
  )
}

export default MiniLeaderboardModuleEntry
