import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import PlayoffModule from './playoff-react/PlayoffModuleReact'
import Affiliation from './AffiliationReact'
import LeaderboardTable from './LeaderboardTableReact'
import LeaderboardSearch from './LeaderboardSearchReact'
import LEADERBOARD from './../../../../../graphql/versions/v2/queries/scoring/leaderboard.graphql'
import STATICLEADERBOARDASSETS from './../../../../../graphql/versions/v2/queries/scoring/staticleaderboardassets.graphql'
import LeaderboardFilter from './LeaderboardFilterReact'

const LeaderboardModule = props => {
  const {
    timestamp,
    eventId,
    loadingText,
    loadingImage,
    refreshInterval
  } = props

  const favoritesCaption = 'Favorites'
  const fullLeaderboardCaption = 'Full Leaderboard'
  const teamOf20Caption = 'Team Of 20'
  const pastChampionsCaption = 'Past PGA Champions'
  const legendLabel = 'Legend'
  const legendBack = 'Back 9 Start'
  const legendCUT = 'Missed Cut'
  const legendWD = 'Withdrawn'
  const legendDQ = 'Disqualified'
  const legendMDF = 'Made Cut, Did Not Finish'
  const legendDNS = 'Did Not Start'
  const CUTLabel = 'CUT'
  const WDLabel = 'WD'
  const DQLabel = 'DQ'
  const MDFLabel = 'MDF'
  const DNSLabel = 'DNS'
  const simultaneousText = 'Simultaneous Rounds, Playing Previous Round'
  const errorTitle = 'Oops! Something went wrong.'
  const [searchValue, setSearchValue] = useState('')
  const [filter, setFilter] = useState('fullLeaderboard')
  const [caption, setCaption] = useState(fullLeaderboardCaption)
  const [dropdown, setDropdown] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [suggestionsIndex, setSuggestionsIndex] = useState(0)
  const [suggestionsActive, setSuggestionsActive] = useState(false)
  const [open, setOpen] = useState(true)
  const myTimeStorageName = 'pgaMyTime'
  const [myTime, setMyTime] = useState(false)

  const [favorites, setFavorites] = useState(
    JSON.parse(localStorage.getItem('pgaPlayerFavorites')) || []
  )

  const lastTimeUpdatedStorageName = 'lastTimeUpdated'
  const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState(
    localStorage.getItem(lastTimeUpdatedStorageName) !== 'null' &&
      localStorage.getItem(lastTimeUpdatedStorageName) !== 'undefined'
      ? JSON.parse(localStorage.getItem(lastTimeUpdatedStorageName))
      : 0
  )

  const timeToggle = () => {
    setMyTime(!myTime)
    localStorage.setItem(myTimeStorageName, !myTime)
  }

  useEffect(() => {
    function checkFavorites () {
      setFavorites(JSON.parse(localStorage.getItem('pgaPlayerFavorites')) || [])
    }

    window.addEventListener('FavoritePlayer:Clicked', checkFavorites)
    return () => {
      window.removeEventListener('FavoritePlayer:Clicked', checkFavorites)
    }
  }, [favorites])

  useEffect(() => {
    if (localStorage.getItem(myTimeStorageName) === 'true') {
      setMyTime(true)
    }
  }, [myTime])

  const {
    error: leaderboardError,
    data: leaderboardData,
    previousData: previousLeaderboardData,
    loading: leaderboardLoading,
    refetch: leaderboardRefetch
  } = useQuery(LEADERBOARD, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: {
      id: eventId,
      timestamp: timestamp
    }
  })

  const {
    error: staticLeaderboardError,
    data: staticLeaderboardData,
    previousData: previousStaticLeaderboardData,
    loading: staticLeaderboardLoading,
    refetch: staticLeaderboardRefetch
  } = useQuery(STATICLEADERBOARDASSETS, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: {
      id: eventId,
      timestamp: timestamp
    }
  })

  useEffect(() => {
    if (timestamp !== lastUpdatedTimestamp) {
      setLastUpdatedTimestamp(timestamp)
      localStorage.setItem(
        lastTimeUpdatedStorageName,
        JSON.stringify(timestamp)
      )
      leaderboardRefetch()
      staticLeaderboardRefetch()
    }
  }, [
    timestamp,
    lastUpdatedTimestamp,
    leaderboardRefetch,
    staticLeaderboardRefetch
  ])

  console.log('leaderboard data: ' + (leaderboardData ? 'yes' : 'no'))
  console.log(
    'previous leaderboard data: ' + (previousLeaderboardData ? 'yes' : 'no')
  )
  console.log(
    'static leaderboard data: ' + (staticLeaderboardData ? 'yes' : 'no')
  )
  console.log(
    'previous static leaderboard data: ' +
      (previousStaticLeaderboardData ? 'yes' : 'no')
  )
  // we do not have data yet, loading icon
  if (
    (!leaderboardData && !previousLeaderboardData) ||
    (!staticLeaderboardData && !previousStaticLeaderboardData)
  ) {
    if (leaderboardLoading || staticLeaderboardLoading) {
      console.log('Leaderboard Loading State rendering')
      return (
        <div className="LeaderboardModule-loading">
          <img src={loadingImage || null} alt="loading" />
          <p>{loadingText || null}</p>
        </div>
      )
    } else {
      console.log('leaderboard loading state not rendering, but no data')
    }
  } else {
    console.log('We have some leaderboard data')
  }

  if (leaderboardError || staticLeaderboardError) {
    return (
      <div className="LeaderboardModule-error">
        <img src={loadingImage} alt="error" />
        <h3>{errorTitle}</h3>
        <p>
          Try{' '}
          <a
            onClick={() => {
              window.location.reload()
            }}
          >
            refreshing the page
          </a>{' '}
          or come back later.
        </p>
      </div>
    )
  }

  const {
    currentRound,
    disableHeadshots,
    disableScorecardMedia,
    disableShotlink,
    disableLeaderboardFilter,
    multiCourse,
    playoffRows,
    playoffTitle,
    rows,
    status
  } = leaderboardData
    ? leaderboardData.leaderboard
    : previousLeaderboardData
      ? previousLeaderboardData.leaderboard
      : null

  const {
    affiliations,
    golfers,
    legend,
    round1Tees,
    round2Tees,
    round3Tees,
    round4Tees,
    shotlinkImages
  } = staticLeaderboardData
    ? staticLeaderboardData.staticLeaderboardAssets
    : previousStaticLeaderboardData
      ? previousStaticLeaderboardData.staticLeaderboardAssets
      : null

  // edge case where there is an API problem but we do not want the app to crash
  if (!rows) {
    return null
  }

  // if we are complete, hardcode to round 4, no matter what the API returns
  const filteredCurrentRound =
    (status && status === 'Scores Official') ||
    (status && status === 'Completed')
      ? 4
      : currentRound

  function createRowsList (rows) {
    const result = []
    rows.map(
      row =>
        row.golferFirstName &&
        row.golferLastName &&
        result.push(`${row.golferFirstName} ${row.golferLastName}`)
    )
    return result
  }
  const rowsList = createRowsList(rows)

  function handleSearchQuery (e) {
    setOpen(true)
    setSearchValue(e.target.value.toLowerCase())
    if (e.target.value.toLowerCase().length >= 3) {
      const filterSuggestions = rowsList.filter(
        suggestion =>
          suggestion.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      )
      setSuggestions(filterSuggestions)
      setSuggestionsActive(true)
    } else {
      setSuggestionsActive(false)
    }
  }

  const handleClick = e => {
    setSearchValue(e.target.innerText)
    setSuggestions([])
    setSuggestionsActive(false)
  }

  const handleKeyDown = e => {
    if (e.keyCode === 38) {
      if (suggestionsIndex === 0) {
        return
      }
      setSuggestionsIndex(suggestionsIndex - 1)
    } else if (e.keyCode === 40) {
      if (suggestionsIndex - 1 === suggestions.length) {
        return
      }
      setSuggestionsIndex(suggestionsIndex + 1)
    } else if (e.keyCode === 13) {
      setSearchValue(suggestions[suggestionsIndex])
      setSuggestionsIndex(0)
      setSuggestionsActive(false)
    }
  }
  // this function is to filter the golfer type for the dropdown filter
  function filterGolferType (obj) {
    if (filter === 'fullLeaderboard') return true
    return obj[filter] === true
  }
  // filter based both on search value and also dropdown filter
  const getFilteredRows = (searchValue, filterValue, rows) => {
    if (!rows) {
      return null
    }
    if (
      (!searchValue && !filterValue) ||
      (!searchValue && filterValue === 'fullLeaderboard')
    ) {
      return rows
    }
    if (!searchValue && filterValue && filterValue !== 'fullLeaderboard') {
      const filteredGolfers = golfers.filter(filterGolferType)
      const filteredRows = rows.filter(row =>
        filteredGolfers.some(x => x.golferId === row.golferId)
      )
      return filteredRows
    }
    if (searchValue && filterValue) {
      const filteredGolfers = golfers.filter(filterGolferType)
      const filteredRows = rows.filter(row =>
        filteredGolfers.some(x => x.golferId === row.golferId)
      )
      return filteredRows.filter(
        row =>
          (row.golferFirstName &&
            row.golferFirstName
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (row.golferFirstName &&
            row.golferLastName
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (row.golferFirstName &&
            row.golferFirstName.toLowerCase() +
              ' ' +
              row.golferLastName.toLowerCase() ===
              searchValue.toLowerCase())
      )
    }
    return rows.filter(
      row =>
        (row.golferFirstName &&
          row.golferFirstName
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (row.golferFirstName &&
          row.golferLastName
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (row.golferFirstName &&
          row.golferFirstName.toLowerCase() +
            ' ' +
            row.golferLastName.toLowerCase() ===
            searchValue.toLowerCase())
    )
  }

  const filteredRows = getFilteredRows(searchValue, filter, rows)

  const favoriteRows = filteredRows.filter(
    row => row.golferFirstName && favorites && favorites.includes(row.golferId)
  )

  const onChangeValue = e => {
    setFilter(e.target.value)
    setCaption(
      e.target.value === 'fullLeaderboard'
        ? fullLeaderboardCaption
        : e.target.value === 'pastChampion'
          ? pastChampionsCaption
          : e.target.value === 'teamOf20'
            ? teamOf20Caption
            : null
    )
  }

  return (
    <React.Fragment>
      <div
        className="LeaderboardModule-wrapper"
        data-refresh-interval={refreshInterval}
        data-refresh-timestamp={lastUpdatedTimestamp}
      >
        {playoffRows && (
          <PlayoffModule
            rows={playoffRows}
            title={playoffTitle}
            golfers={golfers}
          />
        )}
        <div className="LeaderboardModule">
          <div className="LeaderboardModule-top">
            <div className="LeaderboardModule-startingTimes">
              <div className="LeaderboardModule-attribution">
                <div className="LeaderboardModule-attribution-title">
                  Starting Times
                </div>
              </div>
              <div className="LeaderboardModule-timeToggle">
                <span className="LeaderboardModule-timeToggle-label">
                  Event
                </span>
                <label className="LeaderboardModule-toggleSwitch">
                  <input
                    className="LeaderboardModule-toggleSwitch-input"
                    type="checkbox"
                    role="checkbox"
                    tabIndex="0"
                    onChange={timeToggle}
                    checked={myTime}
                    aria-label="My Time"
                  />
                  <span className="slider" />
                </label>
                <span className="LeaderboardModule-timeToggle-label">
                  My Time
                </span>
              </div>
            </div>
            <div className="LeaderboardModule-search-wrapper">
              <LeaderboardSearch
                handleSearchQuery={handleSearchQuery}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                handleClick={handleClick}
                suggestions={suggestions}
                suggestionsIndex={suggestionsIndex}
                handleKeyDown={handleKeyDown}
                suggestionsActive={suggestionsActive}
                setSuggestionsActive={setSuggestionsActive}
                open={open}
                setOpen={setOpen}
              />
              {!disableLeaderboardFilter && (
                <LeaderboardFilter
                  setDropdown={setDropdown}
                  dropdown={dropdown}
                  onChangeValue={onChangeValue}
                  filter={filter}
                />
              )}
            </div>
          </div>
          {rows && (
            <React.Fragment>
              <div className="LeaderboardModule-tables">
                {favorites.length > 0 ? (
                  <LeaderboardTable
                    className="LeaderFavoritesTable"
                    timestamp={timestamp}
                    lastUpdatedTimestamp={lastUpdatedTimestamp}
                    rows={favoriteRows}
                    currentRound={filteredCurrentRound}
                    favorites={favorites}
                    eventStatus={status}
                    tableCaption={favoritesCaption}
                    playoffRows={playoffRows}
                    eventId={eventId}
                    favoritesTable={true}
                    dataFavoritesShow={true}
                    disableShotlink={disableShotlink}
                    disableHeadshots={disableHeadshots}
                    disableScorecardMedia={disableScorecardMedia}
                    golfers={golfers}
                    legend={legend}
                    round1Tees={round1Tees}
                    round2Tees={round2Tees}
                    round3Tees={round3Tees}
                    round4Tees={round4Tees}
                    shotlinkImages={shotlinkImages}
                    multiCourse={multiCourse}
                    affiliations={affiliations}
                  />
                ) : null}
                <LeaderboardTable
                  className="LeaderboardTable"
                  timestamp={timestamp}
                  lastUpdatedTimestamp={lastUpdatedTimestamp}
                  rows={filteredRows}
                  currentRound={filteredCurrentRound}
                  favorites={favorites}
                  eventStatus={status}
                  tableCaption={caption}
                  playoffRows={playoffRows}
                  eventId={eventId}
                  favoritesTable={false}
                  dataFavoritesShow={null}
                  disableShotlink={disableShotlink}
                  disableHeadshots={disableHeadshots}
                  disableScorecardMedia={disableScorecardMedia}
                  golfers={golfers}
                  legend={legend}
                  round1Tees={round1Tees}
                  round2Tees={round2Tees}
                  round3Tees={round3Tees}
                  round4Tees={round4Tees}
                  shotlinkImages={shotlinkImages}
                  multiCourse={multiCourse}
                  affiliations={affiliations}
                  myTime={myTime}
                />
              </div>
              <div className="LeaderboardModule-legend">
                <h4 className="LeaderboardModule-header">{legendLabel}</h4>
                <ul className="LeaderboardModule-legendItems">
                  {affiliations &&
                    affiliations.length > 0 &&
                    affiliations.map((affiliation, i) => (
                      <li
                        className="LeaderboardModule-legendItem"
                        data-affiliation
                        key={i}
                      >
                        <Affiliation
                          affiliation={affiliation}
                          affiliationDescriptionShow={true}
                        />
                      </li>
                    ))}
                </ul>
                <ul className="LeaderboardModule-legendItems">
                  <li className="LeaderboardModule-legendItem">
                    <span className="LeaderboardModule-textLabel">(*)</span>
                    {legendBack}
                  </li>
                  <li className="LeaderboardModule-legendItem">
                    <span className="LeaderboardModule-textLabel">#</span>
                    {simultaneousText}
                  </li>
                </ul>
                <ul className="LeaderboardModule-legendItems">
                  <li className="LeaderboardModule-legendItem">
                    <span className="LeaderboardModule-textLabel">
                      {CUTLabel}
                    </span>
                    {legendCUT}
                  </li>
                  <li className="LeaderboardModule-legendItem">
                    <span className="LeaderboardModule-textLabel">
                      {WDLabel}
                    </span>
                    {legendWD}
                  </li>

                  <li className="LeaderboardModule-legendItem">
                    <span className="LeaderboardModule-textLabel">
                      {DQLabel}
                    </span>
                    {legendDQ}
                  </li>
                  <li className="LeaderboardModule-legendItem">
                    <span className="LeaderboardModule-textLabel">
                      {MDFLabel}
                    </span>
                    {legendMDF}
                  </li>
                  <li className="LeaderboardModule-legendItem">
                    <span className="LeaderboardModule-textLabel">
                      {DNSLabel}
                    </span>
                    {legendDNS}
                  </li>
                </ul>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default LeaderboardModule
