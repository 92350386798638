/* eslint-disable no-new */
import './All.less'
/* defines resource path for lazy imports */
import './core/page/Page-resource-path.js'

import { GetUrlParams } from './util/UrlUtils'
import { AMPM } from './core/teetimes/ampm'
import { ArticleLongform } from './core/article/ArticleLongform'
import { Banner } from './core/banner/Banner.js'
import { CampaignId } from './core/form/CampaignId.js'
import { Carousel } from 'carousel'
import { CourseCard } from './core/golfcourse/CourseCard'
import { ExpandableModule } from './core/expandablemodule/ExpandableModule'
import { FavoriteButton } from './core/player/FavoriteButton'
import { FitText } from './util/FitText'
import { GalleryPage } from 'galleryPage'
import { GoogleDfp } from 'googleDfp'
import { CookieNotification } from './core/page/CookieNotification'
import { LazyLoadImages } from 'lazyLoadImages'
import { LeaderboardModule } from './core/leaderboard/LeaderboardModule'
import { LeaderboardPlayerFilter } from './core/leaderboard/LeaderboardPlayerFilter'
import { LeaderboardTicker } from './core/leaderboard/LeaderboardTicker'
import { TickerPlayer } from './core/leaderboard/TickerPlayer'
import { ListScheduleCarousel } from './core/list/ListScheduleCarousel'
import { TrendingCarousel } from './core/trendingplayer/TrendingCarousel'
import { ListCarousel } from './core/list/ListCarousel'
import { ListLargeCarousel } from './core/list/ListLargeCarousel'
import { ListImageOnTopCarousel } from './core/list/ListImageOnTopCarousel'
import { ListPeopleCarousel } from './core/list/ListPeopleCarousel'
import { ListLoadMore } from './liveblog/ListLoadMore'
import { ModalHighlight } from './core/modal/ModalHighlight'
import { NewsletterForm } from 'newsletterForm'
import { MPXVideoPlayer } from './mpx/MPXVideoPlayer'
import { PageHeader } from 'pageHeader'
import { PlayerBioModule } from './core/player/PlayerBioModule'
import { PlayerStatsCarousel } from './core/player/PlayerStatsCarousel'
import { PlayerSearch } from 'playerSearch'
import { PsMoneyFormatter } from './util/ps-money-formatter'
import { PsFrameData } from './util/ps-frame-data'
import { PsPagination } from './util/ps-pagination'
import { PsTimeAgo } from './util/ps-time-ago'
import { PsTime } from './util/ps-time'
import { PsToggler } from 'psToggler'
import { PsModal } from './util/ps-modal'
import { PsTabbedGroup } from './util/ps-tabbed-group'
import { SearchFilters } from 'searchFilters'
import { SeeMore } from './util/SeeMore'
import { ScorecardStatProgress } from './core/scorecard/ScorecardStatProgress'
import { ScorecardRound } from './core/scorecard/ScorecardRounds'
import { StatsTable } from './core/stats/StatsTable'
import { LeaderboardFlyoutModule } from './core/leaderboard/LeaderboardFlyoutModule'
import { ScorecardTableRow } from './core/scorecard/ScorecardTableRow'
import { ScrollToAnchor } from './util/ScrollToAnchor'
import { SearchModule } from 'searchModule'
import { Shotlink } from './core/shotlink/Shotlink'
import { LeaderboardReact } from 'leaderboardReact'
import { MiniLeaderboardReact } from 'miniLeaderboardReact'
import { TickerLeaderboardReact } from 'tickerLeaderboardReact'

function registerCustomElements () {
  window.customElements.define('ampm-time', AMPM)
  window.customElements.define('article-longform', ArticleLongform)
  window.customElements.define('ps-banner', Banner)
  window.customElements.define('campaign-id', CampaignId)
  window.customElements.define('list-carousel', ListCarousel)
  window.customElements.define('list-large-carousel', ListLargeCarousel)
  window.customElements.define('image-on-top-carousel', ListImageOnTopCarousel)
  window.customElements.define('list-people-carousel', ListPeopleCarousel)
  window.customElements.define('bs-carousel', Carousel)
  window.customElements.define('ps-coursecard', CourseCard)
  window.customElements.define('expandable-module', ExpandableModule)
  window.customElements.define('favorite-button', FavoriteButton)
  window.customElements.define('ps-fittext', FitText)
  window.customElements.define('ps-gallery-page', GalleryPage)
  window.customElements.define('ps-leaderboard', LeaderboardModule)
  window.customElements.define('ps-leaderboard-filter', LeaderboardPlayerFilter)
  window.customElements.define('ps-leaderboard-flyout', LeaderboardFlyoutModule)
  window.customElements.define('leaderboard-ticker', LeaderboardTicker)
  window.customElements.define('ticker-player', TickerPlayer)
  window.customElements.define('trending-carousel', TrendingCarousel)
  window.customElements.define(
    'ps-list-schedule-carousel',
    ListScheduleCarousel
  )
  window.customElements.define('ps-list-loadmore', ListLoadMore)
  window.customElements.define('modal-highlight', ModalHighlight)
  window.customElements.define('cookie-notification', CookieNotification)
  window.customElements.define('player-search', PlayerSearch)
  window.customElements.define('ps-form-newsletter', NewsletterForm)
  window.customElements.define('mpx-video-player', MPXVideoPlayer)
  window.customElements.define('bs-header', PageHeader)
  window.customElements.define('bs-modal-content', PsModal)
  window.customElements.define('bs-search-filters', SearchFilters)
  window.customElements.define('ps-search-module', SearchModule)
  window.customElements.define('ps-score-symbol', ScorecardTableRow)
  window.customElements.define('ps-circle-animation', ScorecardStatProgress)
  window.customElements.define('ps-frame-data', PsFrameData)
  window.customElements.define('ps-money-formatter', PsMoneyFormatter)
  window.customElements.define('ps-stats-carousel', PlayerStatsCarousel)
  window.customElements.define('player-bio', PlayerBioModule)
  window.customElements.define('ps-pagination', PsPagination)
  window.customElements.define('ps-time', PsTime)
  window.customElements.define('ps-time-ago', PsTimeAgo)
  window.customElements.define('ps-toggler', PsToggler)
  window.customElements.define('see-more', SeeMore)
  window.customElements.define('stats-table', StatsTable)
  window.customElements.define('ps-tabbed-group', PsTabbedGroup)
  window.customElements.define('ps-shotlink', Shotlink)
  window.customElements.define('ps-scorecard-carousel', ScorecardRound)
  window.customElements.define('leaderboard-react', LeaderboardReact)
  window.customElements.define('mini-leaderboard-react', MiniLeaderboardReact)
  window.customElements.define(
    'leaderboard-ticker-react',
    TickerLeaderboardReact
  )

  new GoogleDfp()
  new LazyLoadImages()
  new ScrollToAnchor(190)

  if (GetUrlParams(CampaignId.PARAM_NAME)) {
    window.sessionStorage.setItem(
      CampaignId.SESSION_NAME,
      GetUrlParams(CampaignId.PARAM_NAME)
    )
  }
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', registerCustomElements)
} else {
  registerCustomElements()
}

export default {}
