import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import LEADERBOARD from './../../../../../graphql/versions/v2/queries/scoring/leaderboard.graphql'
import STATICLEADERBOARDASSETS from './../../../../../graphql/versions/v2/queries/scoring/staticleaderboardassets.graphql'
import MiniLeaderboardTable from './MiniLeaderboardTableReact'

const MiniLeaderboardModule = props => {
  const { timestamp, loadingText, eventId, loadingImage, numRows } = props
  const lastTimeUpdatedStorageName = 'lastTimeUpdated'

  const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState(
    localStorage.getItem(lastTimeUpdatedStorageName) !== 'null' &&
      localStorage.getItem(lastTimeUpdatedStorageName) !== 'undefined'
      ? JSON.parse(localStorage.getItem(lastTimeUpdatedStorageName))
      : 0
  )
  const {
    error: leaderboardError,
    data: leaderboardData,
    previousData: previousLeaderboardData,
    loading: leaderboardLoading,
    refetch: leaderboardRefetch
  } = useQuery(LEADERBOARD, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: {
      id: eventId,
      timestamp: timestamp
    }
  })

  const {
    error: staticLeaderboardError,
    data: staticLeaderboardData,
    previousData: previousStaticLeaderboardData,
    loading: staticLeaderboardLoading,
    refetch: staticLeaderboardRefetch
  } = useQuery(STATICLEADERBOARDASSETS, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: {
      id: eventId,
      timestamp: timestamp
    }
  })

  useEffect(() => {
    if (timestamp !== lastUpdatedTimestamp) {
      setLastUpdatedTimestamp(timestamp)
      localStorage.setItem(
        lastTimeUpdatedStorageName,
        JSON.stringify(timestamp)
      )
      leaderboardRefetch()
      staticLeaderboardRefetch()
    }
  }, [
    timestamp,
    lastUpdatedTimestamp,
    leaderboardRefetch,
    staticLeaderboardRefetch
  ])

  console.log('mini leaderboard data: ' + (leaderboardData ? 'yes' : 'no'))
  console.log(
    'previous mini leaderboard data: ' +
      (previousLeaderboardData ? 'yes' : 'no')
  )
  console.log(
    'mini static leaderboard data: ' + (staticLeaderboardData ? 'yes' : 'no')
  )
  console.log(
    'previous mini static leaderboard data: ' +
      (previousStaticLeaderboardData ? 'yes' : 'no')
  )

  if (
    !(leaderboardData && !previousLeaderboardData) ||
    !(staticLeaderboardData && !previousStaticLeaderboardData)
  ) {
    if (leaderboardLoading || staticLeaderboardLoading) {
      console.log('Mini Leaderboard Loading State rendering')
      return (
        <div className="LeaderboardModule-loading">
          <img src={loadingImage || null} alt="loading" />
          <p>{loadingText || null}</p>
        </div>
      )
    } else {
      console.log('Mini leaderboard loading state not rendering, but no data')
    }
  } else {
    console.log('We have some mini leaderboard data')
  }

  const { golfers } = staticLeaderboardData
    ? staticLeaderboardData.staticLeaderboardAssets
    : previousStaticLeaderboardData
      ? previousStaticLeaderboardData.staticLeaderboardAssets
      : null

  const { rows, currentRound } = leaderboardData
    ? leaderboardData.leaderboard
    : staticLeaderboardData
      ? staticLeaderboardData.leaderboard
      : null

  const miniLeaderboardCaption = 'Mini Leaderboard'
  // edge case where there is an API problem but we do not want the app to crash
  if (!rows) {
    return null
  }

  return (
    <React.Fragment>
      {leaderboardError && console.error(leaderboardError)}
      {staticLeaderboardError && console.error(staticLeaderboardError)}
      {rows && (
        <div>
          <MiniLeaderboardTable
            rows={rows.slice(0, numRows)}
            tableCaption={miniLeaderboardCaption}
            currentRound={currentRound}
            golfers={golfers}
          />
        </div>
      )}
    </React.Fragment>
  )
}
export default MiniLeaderboardModule
