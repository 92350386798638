/* global DOMParser */

export class ModalHighlight extends window.HTMLElement {
  get modal () {
    return document.querySelector('.Page-modal')
  }

  connectedCallback () {
    this.body = document.body
    this.url = this.getAttribute('data-url')
    this.holeSelected = this.getAttribute('data-hole')
    this.closeHandler = this.closeModal.bind(this)

    this.addEventListener(
      'click',
      (this._appendModal = e => {
        e.preventDefault()
        if (this.modal !== null) {
          this.modal.remove()
        }
        this.appendModal()
      })
    )
  }

  handleModalEvents () {
    const selectedHole = this.modal.querySelector(
      `[data-hole-row='${this.holeSelected}']`
    )

    if (selectedHole !== null) {
      selectedHole.setAttribute('data-shotlink-selected', '')
    }

    // Remove the modal if the user click outside of the modal
    document
      .querySelectorAll('.Page-modal, .Page-modal-close')
      .forEach(item => {
        item.addEventListener('click', this.closeHandler)
      })

    // stop the propogation of eventbubbling if user clicks on the wrapper,
    // prevents the user from clicking on content below and closing the modal
    document.querySelector('.Page-modal-wrapper').addEventListener(
      'click',
      (this._preventBubbling = event => {
        event.stopPropagation()
      })
    )
  }

  closeModal () {
    this.controller.abort()
    document
      .querySelectorAll('.Page-modal, .Page-modal-close')
      .forEach(item => {
        item.removeEventListener('click', this.closeHandler, true)
      })
    document
      .querySelector('.Page-modal-wrapper')
      .removeEventListener('click', this._preventBubbling, true)
    this.modal.remove()

    if (document.documentElement.hasAttribute('data-modal-highlight')) {
      document.documentElement.removeAttribute('data-modal-highlight')
    }
  }

  appendModal () {
    this.requestContent(this.url).then(
      response => {
        const parser = new DOMParser()
        const doc = parser.parseFromString(response, 'text/html')
        const content = doc.querySelector('.Page-modal')

        this.body.append(content)
        document.documentElement.setAttribute('data-modal-highlight', '')

        this.handleModalEvents()
      },
      failure => {
        this.closeModal()
      }
    )
  }

  requestContent (link) {
    this.controller = new window.AbortController()
    const signal = this.controller.signal

    const FETCH_TIMEOUT = 5000
    return new Promise((resolve, reject) => {
      // Set timeout timer
      const timer = setTimeout(
        () => reject(new Error('Request timed out')),
        FETCH_TIMEOUT
      )

      window
        .fetch(link, {
          headers: { 'Content-Type': 'text/html' },
          signal
        })
        .then(
          response => {
            if (response.status !== 200) {
              // make the promise be rejected if we didn't get a 200 response
              // triggers an error for a 404 or other errors
              throw new Error('Not 200 response')
            } else {
              resolve(response.text())
            }
          },
          err => reject(err)
        )
        .catch(err => {
          reject(err)
        })
        .finally(() => clearTimeout(timer))
    })
  }

  disconnectedCallback () {
    this.removeEventListener('click', this._appendModal)
  }
}
