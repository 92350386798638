import React, { useState, useEffect, useRef } from 'react'
import { useQuery } from '@apollo/client'
import LEADERBOARD from './../../../../../graphql/versions/v2/queries/scoring/leaderboard.graphql'
import STATICLEADERBOARDASSETS from './../../../../../graphql/versions/v2/queries/scoring/staticleaderboardassets.graphql'
import TickerPlayer from './TickerPlayerReact'
import { debounce } from 'debounce'
import Flickity from 'react-flickity-component'
import MediaQueries from 'mediaQueries'
import TickerPlayerLoading from './TickerPlayerLoadingReact'

const LeaderboardTickerModule = props => {
  const { timestamp, eventId, refreshInterval } = props

  const mq = new MediaQueries().mq
  const ATTR_TICKER_LOADED = 'data-leaderboard-ticker-loaded'
  const flkty = useRef(null)

  const arrowShape = 'M 10,50 L 60,100 L 70,90 L 30,50 L 70,10 L 60,0 Z'

  const [favorites, setFavorites] = useState(
    JSON.parse(localStorage.getItem('pgaPlayerFavorites')) || []
  )

  const lastTimeUpdatedStorageName = 'lastTimeUpdated'
  const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState(
    localStorage.getItem(lastTimeUpdatedStorageName) !== 'null' &&
      localStorage.getItem(lastTimeUpdatedStorageName) !== 'undefined'
      ? JSON.parse(localStorage.getItem(lastTimeUpdatedStorageName))
      : 0
  )

  const [showTicker, setShowTicker] = useState(mq['mq-viewport-lg'].matches)

  useEffect(() => {
    function checkFavorites () {
      setFavorites(JSON.parse(localStorage.getItem('pgaPlayerFavorites')) || [])
    }

    window.addEventListener('FavoritePlayer:Clicked', checkFavorites)
    return () => {
      window.removeEventListener('FavoritePlayer:Clicked', checkFavorites)
    }
  }, [favorites])

  useEffect(() => {
    if (showTicker) {
      if (!document.body.hasAttribute(ATTR_TICKER_LOADED)) {
        document.body.setAttribute(ATTR_TICKER_LOADED, '')
        if (
          typeof flkty !== 'undefined' &&
          flkty !== null &&
          flkty.current !== null
        ) {
          flkty.current.resize()
        }
      }
    } else {
      if (document.body.hasAttribute(ATTR_TICKER_LOADED)) {
        document.body.removeAttribute(ATTR_TICKER_LOADED)
      }
    }
  }, [showTicker])

  useEffect(() => {
    function resizeTicker () {
      if (mq['mq-viewport-lg'].matches) {
        setShowTicker(true)
      } else {
        setShowTicker(false)
      }
    }
    const debouncedHandleResize = debounce(150, resizeTicker, true)
    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [mq])

  const {
    error: leaderboardError,
    data: leaderboardData,
    previousData: previousLeaderboardData,
    loading: leaderboardLoading,
    refetch: leaderboardRefetch
  } = useQuery(LEADERBOARD, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: {
      id: eventId,
      timestamp: timestamp
    }
  })

  const {
    error: staticLeaderboardError,
    data: staticLeaderboardData,
    previousData: previousStaticLeaderboardData,
    loading: staticLeaderboardLoading,
    refetch: staticLeaderboardRefetch
  } = useQuery(STATICLEADERBOARDASSETS, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: {
      id: eventId,
      timestamp: timestamp
    }
  })

  useEffect(() => {
    if (timestamp !== lastUpdatedTimestamp) {
      setLastUpdatedTimestamp(timestamp)
      localStorage.setItem(
        lastTimeUpdatedStorageName,
        JSON.stringify(timestamp)
      )
      leaderboardRefetch()
      staticLeaderboardRefetch()
    }
  }, [
    timestamp,
    lastUpdatedTimestamp,
    leaderboardRefetch,
    staticLeaderboardRefetch
  ])

  if (!showTicker) {
    return false
  }

  console.log('ticker leaderboard data: ' + (leaderboardData ? 'yes' : 'no'))
  console.log(
    'previous ticker leaderboard data: ' +
      (previousLeaderboardData ? 'yes' : 'no')
  )
  console.log(
    'ticker static leaderboard data: ' + (staticLeaderboardData ? 'yes' : 'no')
  )
  console.log(
    'previous static ticker leaderboard data: ' +
      (previousStaticLeaderboardData ? 'yes' : 'no')
  )
  if (
    !(leaderboardData && !previousLeaderboardData) ||
    !(staticLeaderboardData && !previousStaticLeaderboardData)
  ) {
    if (leaderboardLoading || staticLeaderboardLoading) {
      console.log('Ticker Leaderboard Loading State rendering')
      const numLoadingCells = [1, 2, 3, 4, 5, 6]
      return (
        <Flickity
          className={'LeaderboardTicker-players'}
          elementType={'div'}
          flickityRef={tickerRef => {
            flkty.current = tickerRef
          }}
          options={{
            arrowShape: arrowShape,
            pageDots: false,
            lazyLoad: 1,
            groupCells: true,
            adaptiveHeight: true,
            imagesLoaded: true,
            prevNextButtons: true,
            reloadOnUpdate: true,
            cellAlign: 'left',
            setGallerySize: false
          }}
        >
          {numLoadingCells.map(cell => {
            return (
              <div className="LeaderboardTicker-player" key={cell}>
                <TickerPlayerLoading />
              </div>
            )
          })}
        </Flickity>
      )
    } else {
      console.log('ticker leaderboard loading state not rendering, but no data')
    }
  } else {
    console.log('We have some ticker leaderboard data')
  }

  const { rows, currentRound, multiCourse } = leaderboardData.leaderboard

  // edge case where there is an API problem but we do not want the app to crash
  if (!rows) {
    setShowTicker(false)
    return null
  }
  const {
    golfers,
    round1Tees,
    round2Tees,
    round3Tees,
    round4Tees
  } = staticLeaderboardData.staticLeaderboardAssets

  const favoriteRows = rows.filter(
    row => row.golferId && favorites && favorites.includes(row.golferId)
  )

  const currentRoundTees =
    currentRound === 1 && round1Tees
      ? round1Tees
      : currentRound === 2 && round2Tees
        ? round2Tees
        : currentRound === 3 && round3Tees
          ? round3Tees
          : currentRound === 4 && round4Tees
            ? round4Tees
            : null

  return (
    <React.Fragment>
      {leaderboardError && console.error(leaderboardError)}
      {staticLeaderboardError && console.error(staticLeaderboardError)}
      <Flickity
        className={'LeaderboardTicker-players'}
        data-carousel
        elementType={'div'}
        flickityRef={tickerRef => {
          flkty.current = tickerRef
        }}
        options={{
          arrowShape: arrowShape,
          pageDots: false,
          lazyLoad: 1,
          groupCells: true,
          adaptiveHeight: true,
          imagesLoaded: true,
          prevNextButtons: true,
          reloadOnUpdate: true,
          cellAlign: 'left',
          setGallerySize: false
        }}
        data-event-id={eventId || false}
        data-refresh-interval={refreshInterval || false}
        data-refresh-timestamp={timestamp || false}
      >
        {favoriteRows &&
          favoriteRows.map((row, i) => {
            if (!row.golferId) {
              return null
            } else {
              return (
                <div
                  className="LeaderboardTicker-player"
                  data-favorite-last={
                    row.golferId &&
                    favorites.includes(row.golferId) &&
                    favoriteRows[favoriteRows.length - 1].golferId ===
                      row.golferId
                      ? true
                      : null
                  }
                  key={i}
                >
                  <TickerPlayer
                    multiCourse={multiCourse}
                    favorites={favorites}
                    setFavorites={setFavorites}
                    row={row}
                    index={i}
                    eventId={eventId}
                    key={i}
                    golfer={
                      golfers &&
                      golfers.filter(golfer => golfer.golferId === row.golferId)
                    }
                    currentRound={currentRound}
                    currentRoundTees={currentRoundTees}
                  />
                </div>
              )
            }
          })}
        {rows &&
          rows.map((row, i) => {
            if (!row.golferId) {
              return null
            } else {
              return (
                <div className="LeaderboardTicker-player" key={i}>
                  <TickerPlayer
                    multiCourse={multiCourse}
                    favorites={favorites}
                    setFavorites={setFavorites}
                    row={row}
                    index={i}
                    eventId={eventId}
                    key={i}
                    golfer={
                      golfers &&
                      golfers.filter(golfer => golfer.golferId === row.golferId)
                    }
                    currentRound={currentRound}
                    currentRoundTees={currentRoundTees}
                  />
                </div>
              )
            }
          })}
      </Flickity>
    </React.Fragment>
  )
}

export default LeaderboardTickerModule
