import { Tracker } from 'eventTracker'
import { debounce } from 'debounce'
import Awesomplete from 'awesomplete'

export class PlayerSearch extends window.HTMLElement {
  connectedCallback () {
    this.listenerTracker = new Tracker()
    this.cacheElements()
    this.handleSearchInput()
  }

  cacheElements () {
    this.searchTextInput = this.getElementsByTagName('input')[0]
    this.body = document.getElementsByTagName('body')[0]
    this.searchModule = this.closest('ps-search-module')
    this.searchLocation = this.hasAttribute('data-search-location')
  }

  handleSearchInput () {
    if (this.body.hasAttribute('data-search-api')) {
      this.awesomplete = new Awesomplete(this.searchTextInput, {
        minChars: 3,
        replace: suggestion => {
          this.awesomplete.input.value = suggestion.label
          this.awesompleteValue = suggestion.value
          // setting a location value to forward user to new document location
          if (suggestion.value && this.searchLocation) {
            this.awesomplete.input.setAttribute(
              'data-search-location',
              suggestion.value
            )
          }
        }
      })
      this.ajaxUrl = this.body.getAttribute('data-search-api')

      this.listenerTracker.addListener(
        this.searchTextInput,
        'keydown',
        this.typeEvent()
      )
      this.listenerTracker.addListener(
        this.searchTextInput,
        'awesomplete-selectcomplete',
        () => {
          this.inputSubmit(this)
        }
      )
    }
  }

  typeEvent () {
    return debounce(150, e => {
      if (e.target.value.length >= '3') {
        const fetchURLParams = `${this.ajaxUrl}?name=${encodeURIComponent(
          e.target.value
        )}`

        if (e.key === 'Enter' && this.searchModule !== 'undefined') {
          this.inputSubmit(this)
        } else if (
          e.keyCode !== 37 &&
          e.keyCode !== 38 &&
          e.keyCode !== 39 &&
          e.keyCode !== 40
        ) {
          this.getNewSearch(fetchURLParams).then(response => {
            const result = response.results.map(a => {
              return { label: a.name, value: a.value }
            })
            this.awesomplete.list = result
          })
        }
      }
    })
  }

  inputSubmit (element) {
    console.log(element.awesomplete.input.value)
    const searchLocation = element.awesomplete.input.getAttribute(
      'data-search-location'
    )
    if (this.closest('bs-header')) {
      document.location = this.awesompleteValue
    } else if (
      this.searchModule !== null &&
      this.searchModule !== 'undefined'
    ) {
      this.searchModule.submitSearch()
    } else if (searchLocation) {
      // if a new location exits forward to url
      document.location = searchLocation
    } else {
      this.searchTextInput.closest('form').submit()
    }
  }

  getNewSearch (apiUrl) {
    return new Promise((resolve, reject) => {
      window
        .fetch(apiUrl, {
          credentials: 'include'
        })
        .then(response => {
          resolve(response.json())
        })
        .catch(() => {
          reject(new Error('there is an error'))
        })
    })
  }

  disconnectedCallback () {
    this.listenerTracker.removeListeners()
  }
}
