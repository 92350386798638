/* eslint-disable no-unused-vars */
import { GetUrlParams, SetUrlParams, ResetUrl } from 'urlUtils'

export class GalleryPage extends window.HTMLElement {
  connectedCallback () {
    window.setTimeout(() => {
      this.initCarousel()
    }, 250)
  }

  initCarousel () {
    const carouselSlides = this.querySelector('.GalleryPage-slides')
    let startSlide = 0
    const startSlideFromHash = parseInt(GetUrlParams('s'))

    if (startSlideFromHash > 0) {
      startSlide = startSlideFromHash - 1
    }

    const carouselOptions = {
      pageDots: false,
      lazyLoad: 1,
      adaptiveHeight: true,
      initialIndex: startSlide,
      imagesLoaded: true
    }

    import(/* webpackChunkName: 'Flickity' */ 'flickity').then(
      ({ default: Flickity }) => {
        this.flickity = new Flickity(carouselSlides, carouselOptions)
        this.handleSlideChange()
      }
    )
  }

  handleSlideChange () {
    this.flickity.on('change', i => {
      if (i > 0) {
        SetUrlParams('s=' + (i + 1))
      } else {
        ResetUrl('s')
      }
    })
  }
}
