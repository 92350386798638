/**
 * Element that handles toggling state for a list of multiple tabbed targets
 * Note: Styling can be handled on an individual need basis by adding a class
 * to the element.
 */
export class PsTabbedGroup extends window.HTMLElement {
  connectedCallback () {
    this.tabbedDataAttribute = 'data-tabbed-target-in'
    this.tabGroup = this.getAttribute('data-tabbed-group')
    this.tabbedTargets = this.querySelectorAll('[data-tabbed-target]')
    this.allMyTabs = this.querySelectorAll(
      `[data-tabbed-group=${this.tabGroup}]`
    )
    this.activeTab = this.querySelector('[data-tabbed-target-in="show-tab"]')

    this.allMyTabs.forEach(item => {
      item.addEventListener(
        'click',
        (this._handleToggle = e => {
          e.preventDefault()
          this.handleTabbedTargets(e)
          this.handleTabbedTriggers(e)
        }),
        false
      )
    })

    this.addLoaderToEmptyTabs(this.tabbedTargets)

    if (this.activeTab) {
      this.initTab()
    }

    this.customEvent = new window.CustomEvent('Ajax:Rendered', {
      bubbles: true
    })
  }

  addLoaderToEmptyTabs (tabs) {
    tabs.forEach(item => {
      if (!item.children.length) {
        item.innerHTML = '<svg class="loader"><use xlink:href="#loader"></use>'
      }
    })
  }

  initTab () {
    const triggerId = this.activeTab.getAttribute('data-tabbed-id')
    const dataRequest = this.activeTab.getAttribute('data-request')
    if (dataRequest) {
      this.requestData(dataRequest).then(response => {
        this.querySelector(
          `[data-tabbed-target-id=${triggerId}]`
        ).innerHTML = response
        this.dispatchEvent(this.customEvent)
      })
    }
  }

  handleTabbedTriggers (event) {
    this.allMyTabs.forEach(item => {
      item.removeAttribute(this.tabbedDataAttribute)
    })
    event.currentTarget.setAttribute(this.tabbedDataAttribute, 'show-tab')
  }

  handleTabbedTargets (e) {
    const target = e.target
    const triggerId = target.getAttribute('data-tabbed-id')
    const triggerGroup = target.getAttribute('data-tabbed-group')
    const dataRequest = target.getAttribute('data-request')
    const tabbedTarget = this.querySelector(
      `[data-tabbed-target-id=${triggerId}]`
    )

    // hide all tabs in the same group
    this.querySelectorAll(`[data-tabbed-target=${triggerGroup}]`).forEach(
      item => {
        item.removeAttribute(this.tabbedDataAttribute)
      }
    )
    // show tabbed content
    tabbedTarget.setAttribute(this.tabbedDataAttribute, 'show-content')
    // dispatch event for lazy loading of images
    this.dispatchEvent(this.customEvent)

    this.dispatchToggled()

    if (tabbedTarget.children[0].nodeName !== 'svg') {
      // exit out without making a request for scorecard data
      return
    }
    // if a request endpoint exits
    if (dataRequest !== undefined) {
      this.requestData(dataRequest).then(response => {
        this.querySelector(
          `[data-tabbed-target-id=${triggerId}]`
        ).innerHTML = response
        this.dispatchEvent(this.customEvent)
      })
    }
  }

  requestData (apiUrl) {
    const FETCH_TIMEOUT = 5000
    return new Promise((resolve, reject) => {
      // Set timeout timer
      const timer = setTimeout(
        () => reject(new Error('Request timed out')),
        FETCH_TIMEOUT
      )
      window
        .fetch(apiUrl, {
          credentials: 'include'
        })
        .then(
          response => {
            if (response.status !== 200) {
              // make the promise be rejected if we didn't get a 200 response
              // triggers an error for a 404 or other errors
              throw new Error('Not 200 response')
            } else {
              resolve(response.text())
            }
          },
          err => reject(err)
        )
        .catch(err => {
          reject(err)
        })
        .finally(() => clearTimeout(timer))
    })
  }

  disconnectedCallback () {
    this.allMyTabs.forEach(item =>
      item.removeEventListener('click', this._handleToggle, true)
    )
  }

  dispatchToggled () {
    const customEvent = new window.CustomEvent('PS-TABBED-TARGETS:Tabbed', {
      bubbles: true
    })

    document.body.dispatchEvent(customEvent)
  }
}
