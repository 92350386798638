/* eslint-disable no-prototype-builtins */
export default class MediaQueries {
  constructor () {
    this.mq = this.constructor.mq
    this.breakPoints = this.constructor.breakPoints
  }

  static get breakPoints () {
    return {
      xs: 320,
      sm: 568,
      md: 768,
      lg: 1024,
      hk: 1240,
      xl: 1440
    }
  }

  static get mediaQueries () {
    const bk = this.breakPoints
    return {
      'mq-viewport-xs': `only screen and (min-width: ${bk.xs}px)`,
      'mq-viewport-sm': `only screen and (min-width: ${bk.sm}px)`,
      'mq-viewport-md': `only screen and (min-width: ${bk.md}px)`,
      'mq-viewport-lg': `only screen and (min-width: ${bk.lg}px)`,
      'mq-viewport-hk': `only screen and (min-width: ${bk.hk}px)`,
      'mq-viewport-xl': `only screen and (min-width: ${bk.xl}px)`
    }
  }

  static get mq () {
    const mq = this.mediaQueries
    const w = window
    const returnee = {}
    for (const key in mq) {
      if (Object.prototype.hasOwnProperty.call(mq, key)) {
        returnee[key] = w.matchMedia(mq[key])
      }
    }
    return returnee
  }
}
