class GetImage {
  static options = {}

  static src (imageObject, helperOptions) {
    const options = Object.assign(this.options, helperOptions)

    let imageSrc = imageObject.src // fallback

    if (imageObject.size) {
      // if there is a sized image, use it
      if (imageObject.size.src) {
        imageSrc = imageObject.size.src
      }

      if (!options.noAltFormats) {
        if (imageObject.size.altFormats) {
          imageObject.size.altFormats.forEach(altFormat => {
            if (altFormat.name === 'webp') {
              imageSrc = altFormat.src
            }
          })
        }
      }

      // temporary for dev because we are getting bad mock data until ids are in for imagesize fragment
      // if (imageSrc === 'Hello World') {
      //   imageSrc = imageObject.src
      // }
      return imageSrc
    }
  }

  static height (imageObject, helperOptions) {
    const options = Object.assign(this.options, helperOptions)

    let imageHeight = imageObject.height // fallback

    if (imageObject.size) {
      // if there is a sized image, use it
      if (imageObject.size.height) {
        imageHeight = imageObject.size.height
      }

      if (!options.noAltFormats) {
        // check to see if webp exists, if so use it instead
        if (imageObject.size.altFormats) {
          imageObject.size.altFormats.forEach(altFormat => {
            if (altFormat.name === 'webp') {
              imageHeight = altFormat.height
              if (imageHeight) {
                return imageHeight
              } else {
                return ''
              }
            }
          })
        }
      }

      if (imageHeight) {
        return imageHeight
      } else {
        return ''
      }
    }
  }

  static width (imageObject, helperOptions) {
    const options = Object.assign(this.options, helperOptions)

    let imageWidth = imageObject.width // fallback

    if (imageObject.size) {
      // if there is a sized image, use it
      if (imageObject.size.width) {
        imageWidth = imageObject.size.width
      }

      if (!options.noAltFormats) {
        // check to see if webp exists, if so use it instead
        if (imageObject.size.altFormats) {
          imageObject.size.altFormats.forEach(altFormat => {
            if (altFormat.name === 'webp') {
              imageWidth = altFormat.width
              if (imageWidth) {
                return imageWidth
              } else {
                return ''
              }
            }
          })
        }
      }

      if (imageWidth) {
        return imageWidth
      } else {
        return ''
      }
    }
  }
}

export default GetImage
