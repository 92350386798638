/* global Image */
/* eslint-disable no-unused-vars, no-undef */
import Flickity from 'flickity'
export class Shotlink extends window.HTMLElement {
  connectedCallback () {
    this.cacheElements()
    window.setTimeout(() => {
      this.init()
    }, 250)

    // needed in case numerous players are toggled open on page load
    // since the content hasn't fully loaded in time for the settimeout
    window.addEventListener('load', () => {
      this.allCarouselsResize()
    })

    document.body.addEventListener(
      'PS-TABBED-TARGETS:Tabbed',
      (this._tabbed = () => {
        window.setTimeout(() => {
          if (this.offsetWidth > 0) {
            if (typeof this.flickity !== 'undefined') {
              this.flickity.resize()
            }
          }
        }, 250)

        this.shotCarouselEl.forEach(item => {
          if (item.children.length > 1) {
            this.shotCarouselResize(this.shotCarouselInit(item))
          }
        })
      })
    )
  }

  cacheElements () {
    this.carouselSlides = this.querySelector('.ShotlinkRound-slides')
    this.shotCarouselEl = this.querySelectorAll('[data-shot-carousel]')
    // waiting until window load to trigger carousels. They still look good before
    // window load, and we have run into race condition and image issues sometimes
    // when we kick it off too fast
    const row = this.closest('.LeaderboardRow')
    this.tab = this.closest('.LeaderboardFlyoutModule-scorecards-tabContent')
    this.holeStart10 = !!row.querySelector('[data-hole-10-start]')
    this.thruHole = parseInt(
      row.querySelector('.LeaderboardRow-thru').textContent
    )
    this.currentRound = parseInt(row.getAttribute('data-current-round'))
    this.canvasImage = new Image()
    this.linecolor = 'black'
    this.mobileCanvasWidth = 250
    this.mobileCanvasHeight = 114
    this.desktopCanvasWidth = 350
    this.desktopCanvasHeight = 159
    this.prev = this.tab.querySelector('[data-prev]')
    this.next = this.tab.querySelector('[data-next]')
  }

  init () {
    this.handleOptions()
    this.flickity = new Flickity(this.carouselSlides, this.carouselOptions)
    this.flickity.resize()

    // to set the selected state on the scorecard
    const holeIndex = this.flickity.selectedElement.getAttribute(
      'data-slide-hole'
    )

    this.shotCarouselEl.forEach(item => {
      if (item.children.length > 1) {
        this.shotCarouselInit(item)
        this.shotCarouselResize(this.shotCarouselInit(item))
      }
    })

    window.addEventListener(
      'resize',
      (this._resizeShots = () => {
        this.shotCarouselEl.forEach(item => {
          if (item.children.length > 1) {
            this.shotCarouselResize(this.shotCarouselInit(item))
          }
        })
      })
    )

    if (holeIndex) {
      this.scorecardSelection(holeIndex)
    }

    // Draw subsequent Canvas slides
    this.flickity.on('change', index => {
      const slideElement = this.flickity.cells[index].element
      this.handleCanvasMaps(slideElement)
      const holeIndex = this.flickity.selectedElement.getAttribute(
        'data-slide-hole'
      )
      this.scorecardSelection(holeIndex, true)
      this.flickity.resize()
    })

    this.flickity.on('resize', index => {
      // Draw Canvas's instance
      const slideElement = this.flickity.cells[this.flickity.selectedIndex]
        .element
      this.handleCanvasMaps(slideElement)
    })

    this.prev.addEventListener(
      'click',
      (this._prevSlide = e => {
        e.preventDefault()
        this.flickity.previous()
      })
    )

    this.next.addEventListener(
      'click',
      (this._nextSlide = e => {
        e.preventDefault()
        this.flickity.next()
      })
    )

    // bind click events to the scorecardround
    this.tab.querySelectorAll('.ScorecardTableRow').forEach(item => {
      item.addEventListener(
        'click',
        (this._shotLinkIndex = event => {
          if (!event.target.classList.contains('ScorecardTableRow-hole')) {
            return
          }
          // remove the selected state
          const isSelected = event.currentTarget.parentNode.querySelector(
            '[data-shotlink-selected]'
          )
          if (isSelected) {
            isSelected.removeAttribute('data-shotlink-selected')
          }
          // figure out the indix of the hole to set the shotlink
          const index = event.currentTarget
            .querySelector('.ScorecardTableRow-hole')
            .textContent.trim()

          const selectedhole = this.flickity.element.querySelector(
            `[data-slide-hole="${index}"]`
          )
          if (selectedhole) {
            // Look up the actual index of the slide since we may not have all the holes
            const actualIndex = Array.prototype.indexOf.call(
              selectedhole.parentElement.childNodes,
              selectedhole
            )
            this.flickity.select(actualIndex, false, true)
            // add the selected state
            event.currentTarget.setAttribute('data-shotlink-selected', '')
          }
        })
      )
    })

    if (window.matchMedia) {
      this.onMQLoad = import(
        /* webpackChunkName: 'MediaQueries' */ 'mediaQueries'
      )
        .then(({ default: MediaQueries }) => {
          this.mq = new MediaQueries().mq
        })
        .catch(e => console.warn('Error loading MediaQueries', e))
    }
  }

  shotCarouselInit (item) {
    return new Flickity(item, {
      pageDots: true,
      wrapAround: false,
      prevNextButtons: true
    })
  }

  shotCarouselDestroy (item) {
    item.destroy()
  }

  shotCarouselResize (item) {
    item.resize()
  }

  allCarouselsResize () {
    if (typeof this.flickity !== 'undefined') {
      this.flickity.resize()
    }

    this.shotCarouselEl.forEach(item => {
      if (item.children.length > 1) {
        this.shotCarouselResize(this.shotCarouselInit(item))
      }
    })
  }

  scorecardSelection (holeIndexInit, shotlinkInit) {
    const prevSelected = this.tab.querySelector(
      '.ScorecardTableRow[data-shotlink-selected]'
    )
    const scorecard = this.tab.querySelector('ps-scorecard-carousel')
    const scorecardHoleNum = this.tab.querySelector('[data-scorecard-hole]')

    this.front = new window.CustomEvent('Front9', {
      bubbles: true
    })
    this.back = new window.CustomEvent('Back9', {
      bubbles: true
    })

    if (prevSelected) {
      prevSelected.removeAttribute('data-shotlink-selected')
    }

    holeIndexInit = holeIndexInit ? holeIndexInit - 1 : 0
    const scorecardRows = this.tab.querySelectorAll('.ScorecardTableRow')
    if (scorecardRows.length) {
      scorecardRows[holeIndexInit].setAttribute('data-shotlink-selected', '')
      const hole = holeIndexInit + 1

      if (shotlinkInit) {
        if (hole >= 10 && !scorecard.hasAttribute('data-toggle-in')) {
          scorecard.dispatchEvent(this.back)
        } else if (hole <= 9 && scorecard.hasAttribute('data-toggle-in')) {
          scorecard.dispatchEvent(this.front)
        }
      }
    }

    scorecardHoleNum.innerHTML = holeIndexInit + 1
  }

  getShots (tee, shotList, attributePrefix) {
    const shotCoordinates = []
    const canvasImage = tee
    if (canvasImage === null) {
      return
    }
    const startX = canvasImage.getAttribute(`${attributePrefix}-x`)
    const startY = canvasImage.getAttribute(`${attributePrefix}-y`)
    shotCoordinates.push({
      x: parseFloat(startX),
      y: parseFloat(startY)
    })

    if (shotList) {
      shotList.forEach(item => {
        const shotX = item.getAttribute(`${attributePrefix}-x`)
        const shotY = item.getAttribute(`${attributePrefix}-y`)

        shotCoordinates.push({
          x: parseFloat(shotX),
          y: parseFloat(shotY)
        })
      })
    }

    return shotCoordinates
  }

  handleCanvasMaps (slideElement) {
    const holeCanvas = slideElement.querySelector('.ShotlinkHole-courseCanvas')
    const holeCoords = this.getShots(
      holeCanvas,
      slideElement.querySelectorAll('.ShotlinkHole-play[data-hole-x]'),
      'data-hole'
    )

    if (holeCoords && !holeCanvas.hasAttribute('data-canvas-loaded')) {
      this.reSizeCanvas(holeCanvas)
      this.drawDatatoCanvas(holeCanvas, holeCoords)
    }

    // Draw green Canvas instance
    const greenCanvas = slideElement.querySelector('.ShotlinkHole-greenCanvas')
    const greenCoords = this.getShots(
      greenCanvas,
      slideElement.querySelectorAll('.ShotlinkHole-play[data-green-x]'),
      'data-green'
    )

    if (greenCoords && !greenCanvas.hasAttribute('data-canvas-loaded')) {
      this.reSizeCanvas(greenCanvas)
      this.drawDatatoCanvas(greenCanvas, greenCoords)
    }
  }

  reSizeCanvas (canvas) {
    if (canvas === null) {
      return
    }

    if (
      !this.mq['mq-viewport-sm'].matches ||
      (this.mq['mq-viewport-lg'].matches && !this.mq['mq-viewport-hk'].matches)
    ) {
      canvas.width = this.mobileCanvasWidth
      canvas.height = this.mobileCanvasHeight
    } else {
      canvas.width = this.desktopCanvasWidth
      canvas.height = this.desktopCanvasHeight
    }
    canvas.removeAttribute('data-canvas-loaded')
  }

  drawDatatoCanvas (currentCanvas, shotCoordinates) {
    if (currentCanvas === null) {
      return
    }

    if (currentCanvas.hasAttribute('data-imgsrc')) {
      this.canvasWidth = currentCanvas.width
      this.canvasHeight = currentCanvas.height
      const imgSrc = currentCanvas.getAttribute('data-imgsrc')
      const canvasContext = currentCanvas.getContext('2d')
      const courseImg = new Image()
      courseImg.src = imgSrc
      currentCanvas.setAttribute('data-canvas-loaded', '')

      // When the image is loaded, draw it
      // only enters in here when the first time when the image loads into canvas
      courseImg.onload = () => {
        canvasContext.drawImage(
          courseImg,
          0,
          0,
          this.canvasWidth,
          this.canvasHeight
        )

        if (shotCoordinates) {
          // shot play
          canvasContext.beginPath()
          const newXCoor = this.AxisCalcCoordinate(
            shotCoordinates[0].x,
            this.canvasWidth
          )
          const newYCoor = this.AxisCalcCoordinate(
            shotCoordinates[0].y,
            this.canvasHeight
          )

          canvasContext.moveTo(newXCoor, newYCoor)
          // Line needs to be drawn first so other canvas elements can be drawn on top of it
          shotCoordinates.forEach((item, i) => {
            if (i > 0) {
              this.drawLine(canvasContext, item.x, item.y)
            }
          })

          shotCoordinates.forEach((item, index) => {
            if (index === 0) {
              this.drawTee(canvasContext, item.x, item.y)
            }
            if (index > 0) {
              this.drawPin(canvasContext, item.x, item.y)
              this.drawNumber(canvasContext, item.x, item.y, index)
            }
          })
        }
      }
    }
  }

  AxisCalcCoordinate (coordinate, imageDimension) {
    if (coordinate === 0 || imageDimension === 0) {
      return coordinate
    }
    return coordinate * imageDimension
  }

  drawLine (canvas, xPin, yPin) {
    if (typeof canvas === 'object' && xPin && yPin) {
      const newXCoor = this.AxisCalcCoordinate(xPin, this.canvasWidth)
      const newYCoor = this.AxisCalcCoordinate(yPin, this.canvasHeight)
      canvas.lineWidth = 2
      canvas.lineTo(newXCoor, newYCoor)
      canvas.strokeStyle = this.linecolor
      canvas.stroke()
    }
  }

  drawPin (canvas, xPin, yPin) {
    if (typeof canvas === 'object') {
      const newXCoor = this.AxisCalcCoordinate(xPin, this.canvasWidth)
      const newYCoor = this.AxisCalcCoordinate(yPin, this.canvasHeight)
      canvas.save()
      canvas.translate(newXCoor, newYCoor)
      canvas.beginPath()
      canvas.moveTo(0, 0)
      canvas.bezierCurveTo(2, -10, -20, -25, 0, -30)
      canvas.bezierCurveTo(20, -25, -2, -10, 0, 0)
      canvas.fillStyle = 'black'
      canvas.fill()
      canvas.strokeStyle = 'black'
      canvas.lineWidth = 1.5
      canvas.stroke()
      canvas.beginPath()
      canvas.arc(0, -21, 3, 0, Math.PI * 2)
      canvas.closePath()
      canvas.fillStyle = 'black'
      canvas.fill()
      canvas.restore()
    }
  }

  drawTee (canvas, xPin, yPin) {
    if (typeof canvas === 'object') {
      const newXCoor = this.AxisCalcCoordinate(xPin, this.canvasWidth)
      const newYCoor = this.AxisCalcCoordinate(yPin, this.canvasHeight)
      canvas.beginPath()
      canvas.moveTo(newXCoor, newYCoor)
      canvas.arc(newXCoor, newYCoor, 2, 0, 2 * Math.PI)
      canvas.fillStyle = 'gray'
      canvas.closePath()
      canvas.fill()
    }
  }

  drawNumber (canvas, xPin, yPin, index) {
    if (typeof canvas === 'object') {
      const newXCoor = this.AxisCalcCoordinate(xPin, this.canvasWidth)
      const newYCoor = this.AxisCalcCoordinate(
        yPin - 20 / this.canvasHeight,
        this.canvasHeight
      )

      canvas.font = '10px Arial'
      canvas.fillStyle = 'white'
      canvas.textAlign = 'center'
      canvas.textBaseline = 'middle'
      canvas.fillText(index, newXCoor, newYCoor)
    }
  }

  // we set or reset options here based on the current media query
  // if a conditional has mq specific options, we check to see if the mq
  // changed, and if it did, we set a global changed var so that we
  // can rebuild the carousel

  handleOptions () {
    this.optionsChanged = false
    this.startSlide = 0

    // if current round exists and is between 1 and 4 we need to see which carousel
    // is actually opened to in the tabs. If the current round matches the carousel
    // being show, that's the one we want to open to whatever the thruHole determines
    if (this.currentRound >= 1 && this.currentRound <= 4) {
      const tabIdAttr = 'data-tabbed-target-id'
      const tab = this.closest(`[${tabIdAttr}]`)
      // Below is how we can tell which tab we are in
      Array.from(tab.parentNode.children).indexOf(tab)
      // Below gives us the number from the data attribute to compare to the current slide
      // ex: data-tabbed-id="scorecard-round4" will give us "4" as an integer
      const carouselIndex = parseInt(tab.getAttribute(tabIdAttr).slice(-1))

      if (carouselIndex === this.currentRound) {
        this.carouselSlides = tab.querySelector('.ShotlinkRound-slides')
        const slideCount = parseInt(
          this.carouselSlides.getAttribute('data-length')
        )
        // if the thru hole is 18, F, or - the slide will start at 0
        if (this.thruHole <= 17) {
          // if holeStart10 is true, and the thru is more than 9
          // Holes 1 - 9 will be at the start the of carousel, so
          // we have to make some calculations of which hole to show
          if (this.holeStart10 && this.thruHole > 9) {
            const thruHoleSub9 = this.thruHole - 9
            const thruHoleSub10 = this.thruHole - 10
            // eslint-disable-next-line
            const holeSlideAfterThru = this.carouselSlides.children[
              thruHoleSub9
            ]

            // We can check if hole slide after the thru hole exists in the DOM
            // by seeing if the data-hole attribute on the slide after the thru hole
            // is equal to (thru hole - 8)
            // EX: (Hole after thru = 2) === (thru 10 - 8 = 2)
            if (
              parseInt(holeSlideAfterThru.getAttribute('data-slide-hole')) ===
              this.thruHole - 8
            ) {
              // if it is, we know we want the start slide index to be 1
              // to show hole 2
              this.startSlide = thruHoleSub9
            } else {
              // if it is, we know we want the start slide index to be 0
              // to show hole 1
              this.startSlide = thruHoleSub10
            }
            // if holeStart10 is true, and the thru is 9, let's see if the first slide's
            // data-hole attribute is 1, and if it is show that first
          } else if (
            this.holeStart10 &&
            this.thruHole === 9 &&
            parseInt(
              this.carouselSlides.children[0].getAttribute('data-slide-hole')
            ) === 1
          ) {
            this.startSlide = 0
          } else {
            // otherwise, we just want the last slide available
            this.startSlide = slideCount - 1
          }
        }
      }
    }

    this.carouselOptions = {
      adaptiveHeight: true,
      imagesLoaded: true,
      pageDots: false,
      wrapAround: false,
      lazyLoad: 2,
      prevNextButtons: false,
      draggable: false,
      initialIndex: this.startSlide
    }
  }

  rebuildCarouselIfChanged () {
    if (this.optionsChanged) {
      this.flickity.destroy()
      this.flickity = new Flickity(this.carouselSlides, this.carouselOptions)
    }
  }

  disconnectedCallback () {
    if (typeof this.flickity !== 'undefined') {
      this.flickity.destroy()

      this.tab.querySelectorAll('.ScorecardTableRow').forEach(item => {
        item.removeEventListener('click', this._shotLinkIndex)
      })

      this.prev.removeEventListener('click', this._prevSlide)
      this.next.removeEventListener('click', this._nextSlide)

      this.shotCarouselEl.forEach(item => {
        if (item.children.length > 1) {
          this.shotCarouselDestroy(this.shotCarouselInit(item))
        }
      })
    }
    document.body.removeEventListener('PS-TABBED-TARGETS:Tabbed', this._tabbed)
    window.removeEventListener('resize', this._resizeShots)
    window.removeEventListener('load', this.allCarouselsResize())
  }
}
