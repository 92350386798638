import React, { useState, useEffect } from 'react'
import Affiliation from './AffiliationReact'
import LeaderboardFlyoutModule from './LeaderboardFlyoutModuleReact'
import GetImage from './util/GetImage'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { setStorageItem, removeStorageItem } from 'storageHooks'
import ModalHighlightVideo from './ModalHighlightVideo'

const LeaderboardRow = props => {
  const {
    timestamp,
    lastUpdatedTimestamp,
    index,
    eventId,
    winner,
    favorites,
    currentRound,
    disableShotlink,
    disableHeadshots,
    disableScorecardMedia,
    eventStatus,
    round1Tees,
    round2Tees,
    round3Tees,
    round4Tees,
    shotlinkImages,
    multiCourse,
    affiliations,
    golferStaticAssets,
    myTime
  } = props

  const {
    overallPar,
    round1GolferRoundScore,
    round2GolferRoundScore,
    round3GolferRoundScore,
    round4GolferRoundScore,
    round1GolferRoundTeeTime,
    round2GolferRoundTeeTime,
    round3GolferRoundTeeTime,
    round4GolferRoundTeeTime,
    position,
    status,
    movement,
    golferFirstName,
    golferId,
    golferLastName,
    totalStrokes // TODO: verify this is the same as calculatedTotalScore
  } = props.row
  const [flyoutOpen, setFlyoutOpen] = useState(false)
  const [videoModalOpen, setVideoModalOpen] = useState(false)
  const openedRowsStorageName = 'pgaFlyout'

  const belowPar = overallPar < 0

  useEffect(() => {
    const storageItemsOpen = JSON.parse(
      sessionStorage.getItem(openedRowsStorageName)
    )

    if (storageItemsOpen && storageItemsOpen.includes(golferId)) {
      setFlyoutOpen(true)
    }
  }, [golferId])

  const affiliationIds =
    golferStaticAssets && golferStaticAssets.length > 0
      ? golferStaticAssets[0].affiliationIds
      : []
  const countryFlag =
    golferStaticAssets && golferStaticAssets[0]
      ? golferStaticAssets[0].countryFlag
      : null
  const image =
    golferStaticAssets && golferStaticAssets[0]
      ? golferStaticAssets[0].image
      : null
  const playerPageUrl =
    golferStaticAssets && golferStaticAssets[0]
      ? golferStaticAssets[0].playerPageUrl
      : null

  // Use this in the leaderboard row. If any round has video, we get an icon
  let hasVideo = false
  hasVideo = round1GolferRoundScore ? round1GolferRoundScore.hasVideo : false
  hasVideo = round2GolferRoundScore ? round2GolferRoundScore.hasVideo : false
  hasVideo = round3GolferRoundScore ? round3GolferRoundScore.hasVideo : false
  hasVideo = round4GolferRoundScore ? round4GolferRoundScore.hasVideo : false

  const currentRoundScore =
    round1GolferRoundScore && currentRound === 1
      ? round1GolferRoundScore.roundScore
      : round2GolferRoundScore && currentRound === 2
        ? round2GolferRoundScore.roundScore
        : round3GolferRoundScore && currentRound === 3
          ? round3GolferRoundScore.roundScore
          : round4GolferRoundScore && currentRound === 4
            ? round4GolferRoundScore.roundScore
            : null

  const currentRoundHole10Start =
    currentRound === 1 && round1GolferRoundTeeTime
      ? round1GolferRoundTeeTime.hole10Start
      : currentRound === 2 && round2GolferRoundTeeTime
        ? round2GolferRoundTeeTime.hole10Start
        : currentRound === 3 && round3GolferRoundTeeTime
          ? round3GolferRoundTeeTime.hole10Start
          : currentRound === 4 && round4GolferRoundTeeTime
            ? round4GolferRoundTeeTime.hole10Start
            : false

  const currentRoundThru =
    round1GolferRoundScore && currentRound === 1 && round1GolferRoundScore.thru
      ? round1GolferRoundScore.thru
      : round2GolferRoundScore &&
        currentRound === 2 &&
        round2GolferRoundScore.thru
        ? round2GolferRoundScore.thru
        : round3GolferRoundScore &&
        currentRound === 3 &&
        round3GolferRoundScore.thru
          ? round3GolferRoundScore.thru
          : round4GolferRoundScore &&
        currentRound === 4 &&
        round4GolferRoundScore.thru
            ? round4GolferRoundScore.thru
            : '-'

  const currentRoundCourseId =
    currentRound === 1 && round1GolferRoundTeeTime
      ? round1GolferRoundTeeTime.courseId
      : currentRound === 2 && round2GolferRoundTeeTime
        ? round2GolferRoundTeeTime.courseId
        : currentRound === 3 && round3GolferRoundTeeTime
          ? round3GolferRoundTeeTime.courseId
          : currentRound === 4 && round4GolferRoundTeeTime
            ? round4GolferRoundTeeTime.courseId
            : null

  const currentRoundTees =
    currentRound === 1
      ? round1Tees
      : currentRound === 2
        ? round2Tees
        : currentRound === 3
          ? round3Tees
          : currentRound === 4
            ? round4Tees
            : null

  const rowHighlightHelper = (round, currentRoundTees) => {
    if (
      currentRound === round &&
      currentRoundCourseId &&
      multiCourse &&
      currentRoundTees &&
      currentRoundTees.roundTees
    ) {
      const highlightCourseId = currentRoundTees.roundTees.filter(
        roundTee => roundTee.courseId === currentRoundCourseId
      )
      if (
        highlightCourseId &&
        highlightCourseId[0] &&
        highlightCourseId[0].courseColor
      ) {
        return highlightCourseId[0].courseColor
      } else {
        return null
      }
    }
  }

  const highlight = rowHighlightHelper(currentRound, currentRoundTees)

  const currentRoundHighlightVideo =
    currentRound === 1 &&
    round1GolferRoundScore &&
    round1GolferRoundScore.highlightVideo
      ? round1GolferRoundScore.highlightVideo
      : currentRound === 2 &&
        round2GolferRoundScore &&
        round2GolferRoundScore.highlightVideo
        ? round2GolferRoundScore.highlightVideo
        : currentRound === 3 &&
        round3GolferRoundScore &&
        round3GolferRoundScore.highlightVideo
          ? round3GolferRoundScore.highlightVideo
          : currentRound === 4 &&
        round4GolferRoundScore &&
        round4GolferRoundScore.highlightVideo
            ? round4GolferRoundScore.highlightVideo
            : null

  const currentRoundFeaturedVideo =
    currentRound === 1 &&
    round1GolferRoundScore &&
    round1GolferRoundScore.featuredVideo
      ? round1GolferRoundScore.featuredVideo
      : currentRound === 2 &&
        round2GolferRoundScore &&
        round2GolferRoundScore.featuredVideo
        ? round2GolferRoundScore.featuredVideo
        : currentRound === 3 &&
        round3GolferRoundScore &&
        round3GolferRoundScore.featuredVideo
          ? round3GolferRoundScore.featuredVideo
          : currentRound === 4 &&
        round4GolferRoundScore &&
        round4GolferRoundScore.featuredVideo
            ? round4GolferRoundScore.featuredVideo
            : null

  let currentRoundTeeTime =
    currentRound === 1 && round1GolferRoundTeeTime
      ? round1GolferRoundTeeTime.teeTimeLocal
      : currentRound === 2 && round2GolferRoundTeeTime
        ? round2GolferRoundTeeTime.teeTimeLocal
        : currentRound === 3 && round3GolferRoundTeeTime
          ? round3GolferRoundTeeTime.teeTimeLocal
          : currentRound === 4 && round4GolferRoundTeeTime
            ? round4GolferRoundTeeTime.teeTimeLocal
            : null

  const currentRoundTeeTimeIso =
    currentRound === 1 && round1GolferRoundTeeTime
      ? round1GolferRoundTeeTime.teeTimeIso
      : currentRound === 2 && round2GolferRoundTeeTime
        ? round2GolferRoundTeeTime.teeTimeIso
        : currentRound === 3 && round3GolferRoundTeeTime
          ? round3GolferRoundTeeTime.teeTimeIso
          : currentRound === 4 && round4GolferRoundTeeTime
            ? round4GolferRoundTeeTime.teeTimeIso
            : null

  // edge case where if currentRound is wrong, but there are tee times, head down the
  // tee time stack to find the earliest tee time
  if (
    currentRoundThru === null &&
    currentRoundTeeTime === null &&
    status === ''
  ) {
    if (round4GolferRoundTeeTime) {
      currentRoundTeeTime = round1GolferRoundTeeTime.teeTimeLocal
    } else {
      if (round3GolferRoundTeeTime) {
        currentRoundTeeTime = round3GolferRoundTeeTime.teeTimeLocal
      } else {
        if (round2GolferRoundTeeTime) {
          currentRoundTeeTime = round2GolferRoundTeeTime.teeTimeLocal
        } else {
          if (round2GolferRoundTeeTime) {
            currentRoundTeeTime = round1GolferRoundTeeTime.teeTimeLocal
          }
        }
      }
    }
  }

  const formattedTime = time => {
    const date = new Date(time)

    let h = date.getHours()
    let m = date.getMinutes()
    const ampm = h >= 12 ? 'PM' : 'AM'
    h = h % 12
    if (h === 0) {
      h = 12
    }
    m = m < 10 ? '0' + m : m
    const strTime = `${h}:${m} ${ampm}`
    return strTime
  }

  const AMPM = props => {
    return (
      <React.Fragment>
        <div className="LeaderboardRow-thru-time">{props.time[0]}</div>
        <div className="LeaderboardRow-thru-ampm">{props.time[1]}</div>
      </React.Fragment>
    )
  }

  // if thru is empty, and we have a current tee time, put that in the thru column
  const thruText = () => {
    if (
      status === 'WD' ||
      status === 'DQ' ||
      status === 'DNS' ||
      status === 'CUT'
    ) {
      return '-'
    }

    if (currentRoundThru === '-' && currentRoundTeeTime !== null) {
      const time = currentRoundTeeTime.split(' ')
      // First check to see if myTime is checked, and we have teeTimeIso
      // so we can format and render the local time the same way we get it
      // from the backend.
      if (myTime && currentRoundTeeTimeIso !== null) {
        return (
          <React.Fragment>
            <AMPM time={formattedTime(currentRoundTeeTimeIso).split(' ')} />
          </React.Fragment>
        )
        // otherwise, we get the time formatted from the backend that's
        // already in the timezone we need. If there's a space we assume it's
        // formatted like '8:30 AM`, so we'll need to split the string to
        // put AM/PM on a separate line.
      } else if (time[1]) {
        return (
          <React.Fragment>
            <AMPM time={time} />
          </React.Fragment>
        )
      } else {
        return currentRoundTeeTime
      }
    } else {
      return (
        <React.Fragment>
          {currentRoundThru}
          {currentRoundThru && currentRoundHole10Start && (
            <span className="LeaderboardRow-hole10Start" data-hole-10-start>
              *
            </span>
          )}
        </React.Fragment>
      )
    }
  }

  const handleFlyout = e => {
    if (
      (e.type === 'click' || e.type === 'keydown') &&
      (!e.key || e.key === 'Enter')
    ) {
      setFlyoutOpen(!flyoutOpen)
      if (!flyoutOpen) {
        setStorageItem(sessionStorage, openedRowsStorageName, golferId)
      } else {
        removeStorageItem(sessionStorage, openedRowsStorageName, golferId)
      }
    }
  }

  const handleVideoModalOpen = e => {
    e.stopPropagation()
    setVideoModalOpen(true)
  }

  const handleVideoModalClose = () => {
    setVideoModalOpen(false)
  }

  // make sure to remove roundScore data for certain statuses, then do a check for 0 = E
  const roundScoreHelper = () => {
    if (
      status === 'WD' ||
      status === 'DQ' ||
      status === 'DNS' ||
      status === 'CUT'
    ) {
      return '-'
    }

    if (currentRoundScore) {
      if (currentRoundScore === '0') {
        return 'E'
      } else {
        return currentRoundScore
      }
    } else {
      return null
    }
  }

  const rowStyle = {
    '--highlight': highlight
  }

  return (
    <React.Fragment>
      <div
        className="LeaderboardRow"
        data-player-name={
          golferFirstName && golferLastName
            ? golferFirstName + ' ' + golferLastName
            : golferFirstName && !golferLastName
              ? golferFirstName
              : !golferFirstName && golferLastName
                  ? golferLastName
                  : ''
        }
        data-rowindex={index || null}
        data-eventid={eventId || null}
        data-courseid={currentRoundCourseId || null}
        data-favorites-state={favorites.includes(golferId)}
        data-playerid={golferId || null}
        data-position={position || null}
        data-score={overallPar || null}
        data-leaderboard-winner={winner || null}
        data-secondary-cut={status === 'CUT' ? true : null}
        data-leaderboard-cut={status === 'CUT' || null}
        data-leaderboard-wd={status === 'WD' || null}
        data-leaderboard-dns={status === 'DNS' || null}
        data-leaderboard-dq={status === 'DQ' || null}
        data-current-round={currentRound || null}
        data-leaderboard-bg // TODO: check if this is still needed
        style={rowStyle}
      >
        <div
          className="LeaderboardRow-row"
          data-featured-video={!!currentRoundFeaturedVideo || null}
          onClick={handleFlyout}
          onKeyDown={handleFlyout}
          tabIndex="0"
        >
          <div className="LeaderboardRow-data tdcol-1">
            <favorite-button
              className="Favorite"
              data-player-id={golferId || null}
            >
              <button
                tabIndex="0"
                className="Favorite-button"
                role="switch"
                aria-checked="false"
                type="button"
              >
                <span className="sr-only">
                  {golferFirstName &&
                    golferLastName &&
                    `${golferFirstName} ${golferLastName}`}
                  Favorite
                </span>
                <svg className="star-off">
                  <use href="#star-inactive" />
                </svg>
                <span className="text-off sr-only"> on</span>
                <svg className="star-on">
                  <use href="#star-active" />
                </svg>
                <span className="text-on sr-only"> off</span>
              </button>
            </favorite-button>
          </div>
          <div className="LeaderboardRow-data tdcol-2">
            <span className="LeaderboardRow-position">
              {status || position}
            </span>
          </div>
          <div className="LeaderboardRow-data tdcol-3">
            {movement === 0
              ? '-'
              : Math.abs(movement) && (
                  <span
                    className="LeaderboardRow-trend"
                    data-trend-up={parseInt(movement) > 0 || null}
                    data-trend-down={parseInt(movement) < 0 || null}
                  >
                    {movement === 0 ? '-' : Math.abs(movement)}
                  </span>
              )}
          </div>
          <div className="LeaderboardRow-data tdcol-4">
            <div className="LeaderboardRow-player">
              {disableHeadshots
                ? null
                : image && (
                    <div className="LeaderboardRow-image">
                      <LazyLoadImage
                        alt={`headshot of ${golferFirstName ||
                          ''} ${golferLastName || ''}`}
                        height="75"
                        src={GetImage.src(image)}
                        width="75"
                      />
                    </div>
                )}
              <div
                className="LeaderboardRow-playerInfo"
                data-has-flag={!!countryFlag}
              >
                {/* imagesize teamFlagSmall */}
                {countryFlag && (
                  <span className="LeaderboardRow-flag">
                    <LazyLoadImage
                      alt={`country flag for ${golferFirstName ||
                        ''} ${golferLastName || ''}`}
                      height="26"
                      src={GetImage.src(countryFlag)}
                      width="40"
                    />
                  </span>
                )}
                <React.Fragment>
                  {golferFirstName && (
                    <span className="LeaderboardRow-firstName">
                      {golferFirstName}
                    </span>
                  )}
                  {golferLastName && (
                    <span className="LeaderboardRow-lastName">
                      {golferLastName}
                    </span>
                  )}
                  {affiliationIds && affiliationIds.length > 0 && (
                    <div className="LeaderboardRow-affiliations">
                      {affiliationIds.map((affiliationId, i) => (
                        <Affiliation
                          affiliation={affiliations.filter(
                            affiliation => affiliation.id === affiliationId
                          )}
                          key={affiliationId}
                          affiliationDescriptionShow={false}
                        />
                      ))}
                    </div>
                  )}
                </React.Fragment>
              </div>
            </div>
          </div>
          <div className="LeaderboardRow-data tdcol-5">
            {overallPar && (
              <span
                className="LeaderboardRow-overallPar"
                data-black={belowPar ? null : true}
              >
                {overallPar}
              </span>
            )}
          </div>
          <div className="LeaderboardRow-data tdcol-6">
            <div className="LeaderboardRow-thru">{thruText()}</div>
          </div>
          <div className="LeaderboardRow-data tdcol-7">
            <span className="LeaderboardRow-today">{roundScoreHelper()}</span>
          </div>
          <div className="LeaderboardRow-data tdcol-8">
            {round1GolferRoundScore &&
              (round1GolferRoundScore.completed ? (
                <span className="LeaderboardRow-round1">
                  {round1GolferRoundScore.roundTotal}
                </span>
              ) : null)}
          </div>
          <div className="LeaderboardRow-data tdcol-9">
            {round2GolferRoundScore &&
              (round2GolferRoundScore.completed ? (
                <span className="LeaderboardRow-round2">
                  {round2GolferRoundScore.roundTotal}
                </span>
              ) : null)}
          </div>
          <div className="LeaderboardRow-data tdcol-10">
            {round3GolferRoundScore &&
              (round3GolferRoundScore.completed ? (
                <span className="LeaderboardRow-round3">
                  {round3GolferRoundScore.roundTotal}
                </span>
              ) : null)}
          </div>
          <div className="LeaderboardRow-data tdcol-11">
            {round4GolferRoundScore &&
              (round4GolferRoundScore.completed ? (
                <span className="LeaderboardRow-round4">
                  {round4GolferRoundScore.roundTotal}
                </span>
              ) : null)}
          </div>
          <div className="LeaderboardRow-data tdcol-12">
            <span className="LeaderboardRow-strokes">
              {totalStrokes > 0 ? totalStrokes : ''}
            </span>
          </div>
          {!disableScorecardMedia && (
            <div
              className="LeaderboardRow-data tdcol-13"
              data-featured-video={!!currentRoundFeaturedVideo || null}
            >
              {currentRoundFeaturedVideo ? (
                <div
                  className="LeaderboardRow-media PromoHighlight-media"
                  onClick={handleVideoModalOpen}
                >
                  {currentRoundFeaturedVideo.thumbnail ? (
                    <svg className="play-overlay">
                      <use href="#play-overlay" />
                    </svg>
                  ) : (
                    <svg className="play-video">
                      <use href="#video-camera" />
                    </svg>
                  )}
                  {currentRoundFeaturedVideo.thumbnail && (
                    <img
                      src={GetImage.src(currentRoundFeaturedVideo.thumbnail)}
                    />
                  )}
                </div>
              ) : hasVideo ? (
                <svg className="play-video">
                  <use href="#video-camera" />
                </svg>
              ) : null}
            </div>
          )}
        </div>
        {flyoutOpen && (
          <div className="LeaderboardRow-flyout">
            <div
              className="LeaderboardRow-scorecard-target"
              data-playerid={golferId || ''}
              data-scorecard-show={flyoutOpen === true ? true : null}
            >
              <LeaderboardFlyoutModule
                timestamp={timestamp}
                lastUpdatedTimestamp={lastUpdatedTimestamp}
                row={props.row}
                currentRound={currentRound}
                round1Tees={round1Tees}
                round2Tees={round2Tees}
                round3Tees={round3Tees}
                round4Tees={round4Tees}
                setFlyoutOpen={setFlyoutOpen}
                flyoutOpen={flyoutOpen}
                eventId={eventId}
                hole10Start={currentRoundHole10Start}
                shotlinkImages={shotlinkImages}
                disableShotlink={disableShotlink}
                disableScorecardMedia={disableScorecardMedia}
                highlightVideo={currentRoundHighlightVideo}
                eventStatus={eventStatus}
                playerPageUrl={playerPageUrl}
                image={image}
                multiCourse={multiCourse}
                rowStyle={rowStyle}
                status={status}
              />
            </div>
          </div>
        )}
      </div>
      {videoModalOpen && (
        <ModalHighlightVideo
          featuredVideo={currentRoundFeaturedVideo}
          handleVideoModalClose={handleVideoModalClose}
        />
      )}
    </React.Fragment>
  )
}
export default LeaderboardRow
