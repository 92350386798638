import { Carousel } from 'carousel'
export class ListScheduleCarousel extends Carousel {
  handleOptions () {
    super.handleOptions()
    this.arrowShape = 'M 10,50 L 60,100 L 70,90 L 30,50 L 70,10 L 60,0 Z'
    this.carouselOptions = {
      arrowShape: this.arrowShape,
      adaptiveHeight: false,
      lazyLoad: 2,
      groupCells: true,
      imagesLoaded: true,
      pageDots: false,
      cellAlign: 'left'
    }
  }
}
