export class NewsletterForm extends window.HTMLElement {
  connectedCallback () {
    this.form = this.querySelector('form')

    this.form.addEventListener('submit', e => {
      e.preventDefault()

      this.submitForm()
    })
  }

  submitForm () {
    const apiUrl = this.form.getAttribute('action') || ''
    const data = new window.FormData(this.form)
    const stringData = Array.from(data, e =>
      e.map(encodeURIComponent).join('=')
    ).join('&')

    window
      .fetch(apiUrl, {
        credentials: 'include',
        method: 'POST',
        body: stringData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(response => {
        if (response.status === 200) {
          this.setFormSuccess()

          // // Tracking event
          // let customEvent = new window.CustomEvent('Newsletter:subscribe', {
          //   bubbles: true,
          //   detail: {
          //     email: data.get('email')
          //   }
          // })
          // this.dispatchEvent(customEvent)
        } else {
          this.setFormError()
        }
      })
      .catch(error => {
        console.log('Newsletter Form Error: ' + error)
        this.setFormError()
      })
  }

  setFormSuccess () {
    this.setAttribute('data-form-success', true)
  }

  setFormError () {
    this.setAttribute('data-form-error', true)
  }

  disconnectedCallback () {
    return false
  }
}
