export class AMPM extends window.HTMLElement {
  connectedCallback () {
    const date = new Date(this.textContent)

    let h = date.getHours()
    let m = date.getMinutes()
    const ampm = h >= 12 ? 'PM' : 'AM'
    h = h % 12
    if (h === 0) {
      h = 12
    }
    m = m < 10 ? '0' + m : m
    const strTime = `${h}:${m} ${ampm}`
    this.textContent = strTime
  }
}
