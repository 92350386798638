export class PsMoneyFormatter extends window.HTMLElement {
  connectedCallback () {
    const parsedNumber = Number(this.textContent.replace(/[^0-9.-]+/g, ''))
    this.textContent = `$${this.moneyFormat(parsedNumber)}`
  }

  moneyFormat (labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? Math.floor((Math.abs(Number(labelValue)) / 1.0e9) * 10) / 10 + 'B'
      : Math.abs(Number(labelValue)) >= 1.0e6 // Six Zeroes for Millions
        ? Math.floor((Math.abs(Number(labelValue)) / 1.0e6) * 10) / 10 + 'M'
        : Math.abs(Number(labelValue)) >= 1.0e3 // Three Zeroes for Thousands
          ? Math.floor((Math.abs(Number(labelValue)) / 1.0e3) * 10) / 10 + 'K'
          : Math.abs(Number(labelValue))
  }

  disconnectedCallback () {}
}
