export class LeaderboardFlyoutModule extends window.HTMLElement {
  connectedCallback () {
    const lastRoundTab = this.querySelector(
      '.LeaderboardFlyoutModule-scorecards-tab:last-child'
    )
    console.log({ lastRoundTab })
    if (lastRoundTab) {
      setTimeout(() => {
        lastRoundTab.click()
      }, 250)
    }
  }
}
