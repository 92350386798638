import { Carousel } from 'carousel'

export class ListImageOnTopCarousel extends Carousel {
  connectedCallback () {
    this.carouselSlides = this.querySelector('[class$="-slides"]') || this

    // we need to do some padding adjustment if inside an expandable module
    if (this.closest('.ExpandableModule-bottom')) {
      this.closest('.ExpandableModule-bottom').setAttribute(
        'data-no-right-margin',
        ''
      )
    }

    // waiting until window load to trigger carousels. They still look good before
    // window load, and we have run into race condition and image issues sometimes
    // when we kick it off too fast
    this.onFlickityLoad = import(/* webpackChunkName: 'Flickity' */ 'flickity')
      .then(({ default: Flickity }) => {
        window.setTimeout(() => {
          this.handleOptions()
          this.flickity = new Flickity(
            this.carouselSlides,
            this.carouselOptions
          )

          this.flickity.on('dragStart', () => {
            this.flickity.slider.style.pointerEvents = 'none'
          })
          this.flickity.on('dragEnd', () => {
            this.flickity.slider.style.pointerEvents = 'auto'
          })
        }, 250)
      })
      .catch(e => console.warn('Error loading Flickity', e))
  }

  handleOptions () {
    this.optionsChanged = false

    this.carouselOptions = {
      pageDots: false,
      lazyLoad: 2,
      groupCells: true,
      adaptiveHeight: true,
      imagesLoaded: true,
      prevNextButtons: true,
      cellAlign: 'left'
    }
  }
}
