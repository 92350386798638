const ScorecardTableRowBasic = props => {
  const { score, par, hole, index, yardage } = props

  function updateScoreSymbol (score, par) {
    if (!score) {
      return ''
    }

    const diff = score - par
    if (diff <= -2) {
      return 'eagle'
    } else if (diff === -1) {
      return 'birdie'
    } else if (diff === 1) {
      return 'bogey'
    } else if (diff >= 2) {
      return 'double'
    } else {
      return ''
    }
  }

  const symbol = updateScoreSymbol(score, par)

  return (
    <div
      className="ScorecardTableRow ScorecardRound-slide"
      data-back-9={index > 9 || false}
      data-hole-row={hole}
    >
      <div className="ScorecardTableRow-cell ScorecardTableRow-hole">
        {hole || '-'}
      </div>
      <div className="ScorecardTableRow-cell ScorecardTableRow-par">
        {par || '-'}
      </div>
      <div className="ScorecardTableRow-cell ScorecardTableRow-yards">
        {yardage || '-'}
      </div>
      <div
        className={`ScorecardTableRow-cell ScorecardTableRow-score ${symbol}`}
      >
        {score || '-'}
      </div>
    </div>
  )
}
export default ScorecardTableRowBasic
