import { Carousel } from 'carousel'
export class TrendingCarousel extends Carousel {
  handleOptions () {
    super.handleOptions()
    this.carouselOptions = {
      adaptiveHeight: false,
      lazyLoad: 2,
      groupCells: true,
      imagesLoaded: true,
      pageDots: true,
      cellAlign: 'left'
    }
  }
}
