import { Throttle } from 'throttle'
export class CourseCard extends window.HTMLElement {
  connectedCallback () {
    this.carouselNav = this.querySelector('[class$="-nav"]')
    this.carouselSlides = this.querySelector('[class$="-slides"]') || this
    this.courseHoles = this.querySelectorAll('[data-coursecard-trigger]')
    // waiting until window load to trigger carousels. They still look good before
    // window load, and we have run into race condition and image issues sometimes
    // when we kick it off too fast

    window.setTimeout(() => {
      this.initCarousels()
    }, 250)
  }

  handleOptions () {
    let startSlide = 0
    const startSlideFromHash = parseInt(window.location.hash.slice(6)) - 1

    if (startSlideFromHash > 0) {
      startSlide = startSlideFromHash
    }

    this.carouselOptions = {
      adaptiveHeight: true,
      imagesLoaded: true,
      pageDots: false,
      wrapAround: true,
      prevNextButtons: false,
      lazyLoad: 1,
      hash: true,
      initialIndex: startSlide
    }

    this.carouselNavOptions = {
      asNavFor: this.carouselSlides,
      contain: true,
      draggable: false,
      pageDots: false
    }
  }

  resizeCarousels () {
    return Throttle(250, () => {
      this.flickity.resize()
      this.flickityNav.resize()
    })
  }

  initCarousels () {
    Promise.all([
      import(/* webpackChunkName: 'Flickity' */ 'flickity'),
      import(/* webpackChunkName: 'FlickityHash' */ 'flickity-hash'),
      import(/* webpackChunkName: 'FlickityAsNavFor' */ 'flickity-as-nav-for')
    ])
      .then(modules => {
        window.setTimeout(() => {
          const { default: Flickity } = modules[0]
          this.handleOptions()
          this.flickity = new Flickity(
            this.carouselSlides,
            this.carouselOptions
          )
          this.flickityNav = new Flickity(
            this.carouselNav,
            this.carouselNavOptions
          )

          window.addEventListener('resize', this.resizeCarousels())

          this.flickityNav.resize()
          this.flickity.on('change', i => {
            this.flickityNav.select(i)
          })
          this.flickityNav.on('change', i => {
            this.flickity.select(i)
            this.flickityNav.resize()
          })

          this.courseHoles.forEach((item, i) => {
            item.addEventListener('click', e => {
              this.flickity.select(i)
            })
          })
        }, 250)
      })
      .catch(e => console.warn('Error loading Flickity, hash, or nav', e))
  }

  disconnectedCallback () {
    if (typeof this.flickity !== 'undefined') {
      this.flickity.destroy()
      window.removeEventListener('resize', this.resizeCarousels())
    }
  }
}
