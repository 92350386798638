/* eslint-disable no-unused-vars, no-undef */
import { debounce } from 'debounce'

const ATTR_TICKER_LOADED = 'data-leaderboard-ticker-loaded'
export class LeaderboardTicker extends window.HTMLElement {
  get carouselEl () {
    return this.querySelector('[data-carousel]')
  }

  get tickerAlreadyLoaded () {
    return document.body.hasAttribute(ATTR_TICKER_LOADED)
  }

  connectedCallback () {
    this.cacheElements()
    this.loadFlickity()

    const refreshEndpoint = this.getAttribute('data-refresh-endpoint')
    const refreshInterval = this.getAttribute('data-refresh-interval')
    const refreshTimestamp = this.getAttribute('data-refresh-timestamp')

    if (refreshInterval && refreshTimestamp && refreshEndpoint) {
      const refreshIntervalMilliseconds = refreshInterval * 1000
      this.calcNewTimestamp(
        refreshIntervalMilliseconds,
        refreshTimestamp,
        refreshEndpoint
      )
    }
  }

  calcNewTimestamp (interval, timestampRequested, refreshEndpoint) {
    const frequency = interval // frequency from Handlebars element in milliseconds
    let timestamp = new Date(parseInt(timestampRequested)) // timestamp from Handlebars element
    const now = new Date()

    // Truncating timestamp to seconds
    timestamp = new Date(Math.floor(timestamp.getTime() / 1000) * 1000)

    // Make sure timestamp is in the future
    if (timestamp < now) {
      // Calculate how many times we have to add the refresh frequency to the timestamp to end up in the future
      const multiplier =
        Math.floor((now.getTime() - timestamp.getTime()) / frequency) + 1
      // New timestamp is: timestamp + frequency * multiplier
      timestamp = new Date(timestamp.getTime() + frequency * multiplier)
    }

    const sleep = milliseconds => {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    this.timestampParameter = `&timestamp=${timestamp.getTime()}`

    sleep(frequency).then(() => {
      // add timestamp to the end point for requested data
      this.refreshModule(`${refreshEndpoint}${this.timestampParameter}`)
    })
  }

  cacheElements () {
    this.arrowShape =
      'M22.4566257,37.2056786 L-21.4456527,71.9511488 C-22.9248661,72.9681457 -24.9073712,72.5311671 -25.8758148,70.9765924 L-26.9788683,69.2027424 C-27.9450684,67.6481676 -27.5292733,65.5646602 -26.0500598,64.5484493 L20.154796,28.2208967 C21.5532435,27.2597011 23.3600078,27.2597011 24.759951,28.2208967 L71.0500598,64.4659264 C72.5292733,65.4829232 72.9450684,67.5672166 71.9788683,69.1217913 L70.8750669,70.8956413 C69.9073712,72.4502161 67.9241183,72.8848368 66.4449048,71.8694118 L22.4566257,37.2056786 Z'
    this.carouselOptions = {
      arrowShape: this.arrowShape,
      adaptiveHeight: false,
      lazyLoad: 1,
      groupCells: true,
      imagesLoaded: true,
      pageDots: false,
      cellAlign: 'left'
    }
    this.loadedEvent = new window.CustomEvent('LeaderboardTicker:Loaded', {
      bubbles: true
    })
    this.ajaxRendered = new window.CustomEvent('Ajax:Rendered', {
      bubbles: true
    })
    this.resizeHandler = debounce(250, this.checkWindowSize.bind(this))
    this.carousel = null
    this.eventId = this.getAttribute('data-event-id')
    this.favoritesStorageName = `playerFavorites-${this.eventId}`
  }

  async loadFlickity () {
    try {
      const { default: Flickity } = await import('flickity')
      this.Flickity = Flickity
      if (this.tickerAlreadyLoaded) {
        this.checkFavorites()
        if (this.isDesktop()) {
          this.initCarousel()
        }
      } else {
        document.body.setAttribute(ATTR_TICKER_LOADED, '')
        window.setTimeout(() => {
          this.checkFavorites()
          if (this.isDesktop()) {
            this.initCarousel()
          }
        }, 250)

        window.setTimeout(() => {
          document.body.dispatchEvent(this.loadedEvent)
        }, 500)
      }
      this.setListeners()
    } catch (e) {
      console.warn('Error loading Flickity', e)
    }
  }

  storageAvailable (type) {
    let storage
    try {
      storage = window[type]
      const x = '__storage_test__'
      storage.setItem(x, x)
      storage.removeItem(x)
      return true
    } catch (e) {
      return (
        e instanceof DOMException &&
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage &&
        storage.length !== 0
      )
    }
  }

  checkFavorites () {
    if (this.storageAvailable('localStorage')) {
      // storage supported
      const storageItemsFavorite = JSON.parse(
        window.localStorage.getItem(this.favoritesStorageName)
      )

      if (storageItemsFavorite) {
        // update the source with selected state and populate favorite target with cloned source
        storageItemsFavorite.forEach(id => {
          const favoriteItem = this.querySelector(`[data-player-id="${id}"]`)

          if (favoriteItem) {
            favoriteItem.setAttribute('data-favorites-state', true)
            const playerIndex = favoriteItem.getAttribute('data-index')
            const clonedPlayer = favoriteItem.cloneNode(true)
            favoriteItem.addFavorite(clonedPlayer, playerIndex)
          }
        })

        this.resizeTicker()
      }
    }
  }

  getMediaQuery () {
    const mqValue =
      window
        .getComputedStyle(document.querySelector('body'), '::before')
        .getPropertyValue('content') || false

    if (mqValue) {
      return mqValue.replace(/["']/g, '')
    } else {
      return false
    }
  }

  initCarousel () {
    this.carousel = new this.Flickity(this.carouselEl, this.carouselOptions)
    this.resizeTicker()
  }

  setListeners () {
    window.addEventListener('resize', this.resizeHandler)
  }

  refreshModule (refreshEndpoint) {
    this.requestContent(refreshEndpoint).then(
      response => {
        const content = document.createElement('div')

        content.innerHTML = response
        const newLeaderboardTicker = content.querySelector('.LeaderboardTicker')
        this.destroyTicker()
        this.replaceWith(newLeaderboardTicker)
        document.body.dispatchEvent(this.ajaxRendered)
      },
      failure => {
        console.log('could not load requested leaderboard data')
      }
    )
  }

  requestContent (apiUrl) {
    this.controller = new window.AbortController()
    const signal = this.controller.signal
    const FETCH_TIMEOUT = 5000
    return new Promise((resolve, reject) => {
      // Set timeout timer
      const timer = setTimeout(
        () => reject(new Error('Request timed out')),
        FETCH_TIMEOUT
      )
      window
        .fetch(apiUrl, {
          headers: { 'Content-Type': 'text/html' },
          signal
        })
        .then(
          response => {
            if (response.status !== 200) {
              // make the promise be rejected if we didn't get a 200 response
              // triggers an error for a 404 or other errors
              throw new Error('Not 200 response')
            } else {
              resolve(response.text())
            }
          },
          err => reject(err)
        )
        .catch(err => {
          reject(err)
        })
        .finally(() => clearTimeout(timer))
    })
  }

  checkWindowSize () {
    if (!this.isDesktop()) {
      this.destroyTicker()
    } else {
      if (this.carousel === null) {
        this.initCarousel()
      } else {
        this.resizeTicker()
      }
    }
  }

  isDesktop () {
    if (
      this.getMediaQuery() !== 'mq-lg' &&
      this.getMediaQuery() !== 'mq-hk' &&
      this.getMediaQuery() !== 'mq-xl'
    ) {
      return false
    } else {
      return true
    }
  }

  resizeTicker () {
    window.setTimeout(() => {
      this.carousel.resize()
    }, 100)
  }

  destroyTicker () {
    if (this.carousel !== null) {
      this.carousel.destroy()
      this.carousel = null
    }
  }

  disconnectedCallback () {
    window.removeEventListener('resize', this.resizeHandler)
  }
}
