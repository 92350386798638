export class PsTime extends window.HTMLElement {
  connectedCallback () {
    this.unixTimeCode = this.getAttribute('data-datetime')
    this.monthList = [
      'January',
      'Feburary',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
    this.renderTimestamp(this.unixTimeCode)
  }

  formatDate (date) {
    const d = new Date(date)
    const hh = d.getHours()
    let m = d.getMinutes()
    let dd = 'AM'
    let h = hh

    if (h >= 12) {
      h = hh - 12
      dd = 'PM'
    }

    if (h === 0) {
      h = 12
    }

    m = m < 10 ? '0' + m : m
    // just show time:minute:abreviation
    return `${h}:${m}${dd}`
  }

  hoursSincePublish () {
    let promoTimestamp = this.unixTimeCode || 0
    const currentTimestamp = new Date().getTime()
    let hoursSincePublish = 0

    promoTimestamp = parseInt(promoTimestamp)

    if (promoTimestamp) {
      hoursSincePublish = Math.floor(
        (currentTimestamp - promoTimestamp) / 60000 / 60
      )

      return hoursSincePublish
    } else {
      return false
    }
  }

  renderTimestamp (date) {
    this.innerHTML = this.formatDate(date)
  }

  disconnectedCallback () {
    return false
  }
}
