//* eslint no-undef: "error"*/
/* eslint-env browser */
import { debounce } from 'debounce'
export class LeaderboardModule extends window.HTMLElement {
  connectedCallback () {
    this.tableRows = this.querySelectorAll('.LeaderboardTable .LeaderboardRow')

    this.tableRows.forEach((item, index) => {
      item.setAttribute('data-rowindex', index)
    })
    this.table = this.querySelector('.LeaderboardTable')

    // cached selectors
    this.favoriteTarget = document.body.querySelector('[data-favorites-target]')
    const eventId = document.body
      .querySelector('[data-favorites-source] [data-eventid]')
      .getAttribute('data-eventid')
    this.favoritesStorageName = `playerFavorites-${eventId}`
    this.showPlayerDataStorageName = `showPlayerData-${eventId}`
    this.hasSecondProjectedCutText = this.table.hasAttribute(
      'data-second-projected-cut-text'
    )
    this.showSecondProjectedCutline = this.table.hasAttribute(
      'data-show-second-projected'
    )

    if (this.table.hasAttribute('data-cut-text')) {
      this.cutText = this.table.getAttribute('data-cut-text')
    }

    if (this.table.hasAttribute('data-projected-cut-text')) {
      this.projectedCutText = this.table.getAttribute('data-projected-cut-text')
    }

    if (this.table.hasAttribute('data-secondary-cut-text')) {
      this.secondaryCutText = this.table.getAttribute('data-secondary-cut-text')
    }

    if (this.hasSecondProjectedCutText && this.showSecondProjectedCutline) {
      this.secondProjectedCutText = this.table.getAttribute(
        'data-second-projected-cut-text'
      )
    }

    this.roundCompleted = this.table.hasAttribute('data-round-completed')

    this.currentRound =
      parseInt(this.table.getAttribute('data-current-round')) || 0

    this.cutLinePos =
      parseInt(this.table.getAttribute('data-cutlinepos')) || null

    this.cutLineScore = this.table.getAttribute('data-cutlinescore')
    this.coachPromo = this.querySelector('[data-coach]')
    // Setting the refresh intervals
    const refreshEndpoint = this.getAttribute('data-refresh-endpoint')
    const refreshInterval = this.getAttribute('data-refresh-interval')
    const refreshTimestamp = this.getAttribute('data-refresh-timestamp')
    if (refreshInterval && refreshTimestamp && refreshEndpoint) {
      const refreshIntervalMilliseconds = refreshInterval * 1000
      this.calcNewTimestamp(
        refreshIntervalMilliseconds,
        refreshTimestamp,
        refreshEndpoint
      )
    }

    // bind event for player scoreboard toggle
    this.querySelectorAll('.LeaderboardTable .LeaderboardRow-row').forEach(
      item => {
        this.bindScorecardData(item)
      }
    )

    if (this.storageAvailable('localStorage')) {
      // storage supported
      const storageItemsFavorite = JSON.parse(
        window.localStorage.getItem(this.favoritesStorageName)
      )

      if (storageItemsFavorite) {
        // update thee source with selected state and populate favorite target with cloned source
        storageItemsFavorite.forEach(id => {
          const favoriteItem = document.body.querySelector(
            `[data-favorites-source] [data-playerid="${id}"]`
          )

          if (favoriteItem) {
            const clonedPlayer = favoriteItem.cloneNode(true)
            this.addFavorite(clonedPlayer)
            favoriteItem.setAttribute('data-favorites-state', true)
          }
        })
      }
      // hide and show the favorite section if items exist
      this.updateFavorites(storageItemsFavorite)

      // bind favoriting toggling for favorites
      this.querySelectorAll(
        '.LeaderboardTable .LeaderboardRow-favorite'
      ).forEach(item => {
        this.bindFavorite(item)
      })
    }

    if (this.storageAvailable('sessionStorage')) {
      // create a local storage item for showing players
      const storageItemsShowPlayerData = JSON.parse(
        window.sessionStorage.getItem(this.showPlayerDataStorageName)
      )

      if (storageItemsShowPlayerData) {
        // update the source with selected state and populate favorite target with cloned source
        storageItemsShowPlayerData.forEach(id => {
          const showPlayerDataItem = document.querySelector(
            `.LeaderboardTable .LeaderboardRow[data-playerid="${id}"]`
          )

          if (showPlayerDataItem) {
            showPlayerDataItem.querySelector('.LeaderboardRow-row').click()

            const favoritePlayer = document.querySelector(
              `.LeaderFavoritesTable .LeaderboardRow[data-playerid="${id}"] .LeaderboardRow-row`
            )

            // if the favorite player exists, open that as well
            if (favoritePlayer) {
              favoritePlayer.click()
            }
          }
        })
      }
    }

    this.ajaxRendered = new window.CustomEvent('Ajax:Rendered', {
      bubbles: true
    })

    if (this.coachPromo !== null) {
      // Append the initial coach loaded
      if (typeof window.clonedCoachPromoSessionEl !== 'undefined') {
        this.coachPromo.replaceWith(window.clonedCoachPromoSessionEl)
      } else {
        // If enableCoachingLive is true we want the coaching live promo
        // to show a percentage of the time, otherwise show coaches promo
        const percent = this.coachPromo.getAttribute('data-percent')
        if (
          this.coachPromo.hasAttribute('data-coaching-live') &&
          percent !== null &&
          Math.random() < parseInt(percent) / 100
        ) {
          this.showCoachPromo()
        } else {
          this.importAlgolia()
        }
      }
    }

    if (this.currentRound === 1 && !this.roundCompleted) {
      return false
    }

    this.insertHeaders()
  }

  calcNewTimestamp (interval, timestampRequested, refreshEndpoint) {
    const frequency = interval // frequency from Handlebars element in milliseconds
    let timestamp = new Date(parseInt(timestampRequested)) // timestamp from Handlebars element
    const now = new Date()

    // Truncating timestamp to seconds
    timestamp = new Date(Math.floor(timestamp.getTime() / 1000) * 1000)

    // Make sure timestamp is in the future
    if (timestamp < now) {
      // Calculate how many times we have to add the refresh frequency to the timestamp to end up in the future
      const multiplier =
        Math.floor((now.getTime() - timestamp.getTime()) / frequency) + 1
      // New timestamp is: timestamp + frequency * multiplier
      timestamp = new Date(timestamp.getTime() + frequency * multiplier)
    }

    const sleep = milliseconds => {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    this.timestampParameter = `&timestamp=${timestamp.getTime()}`

    sleep(frequency).then(() => {
      // add timestamp to the end point for requested data
      this.refreshLeaderboard(`${refreshEndpoint}${this.timestampParameter}`)
    })
  }

  insertHeaders () {
    let secondaryCutHeader = false
    let cutHeader = false
    let projectedCutHeader = false
    const tableBody = document.createElement('tbody')

    if (this.tableRows.length) {
      for (const item of this.tableRows) {
        if (!item.hasAttribute('data-ad')) {
          const position = item.querySelector('.LeaderboardRow-position')
            .textContent
          const secondaryCut = item.hasAttribute('data-secondary-cut')

          // Secondary Cut banner insertion
          if (secondaryCut && !secondaryCutHeader) {
            const tableBody = document.createElement('tbody')
            tableBody.innerHTML = this.getBanner(this.secondaryCutText)
            item.before(tableBody)
            secondaryCutHeader = true
          }

          // CUT banner insertion, Players below this banner got cut
          if (
            position.toUpperCase().trim() === 'CUT' &&
            !cutHeader &&
            !secondaryCut
          ) {
            const tableBody = document.createElement('tbody')
            tableBody.innerHTML = this.getBanner(this.cutText)
            item.before(tableBody)
            cutHeader = true
          }
        }
      }

      // If cutline position exists, and cut header isn't set but showSecondProjectedCutline is false
      // we want to show the projected cutline for PGA... OR
      // If cutline position exists, we and showSecondProjectedCutline is true, we are in PPC and we
      // want to show either the first or second projected cutline.
      if (
        (this.cutLinePos &&
          !projectedCutHeader &&
          !cutHeader &&
          !this.showSecondProjectedCutline) ||
        (this.cutLinePos &&
          !projectedCutHeader &&
          !secondaryCutHeader &&
          this.showSecondProjectedCutline)
      ) {
        // If we already have a cut header and hasSecondProjectedCutText and hasSecondProjectedCutText are true
        // we want to show the 2nd projected cutline
        if (
          this.showSecondProjectedCutline &&
          this.hasSecondProjectedCutText &&
          cutHeader
        ) {
          tableBody.innerHTML = this.getBanner(
            this.secondProjectedCutText,
            'projected'
          )
        } else {
          tableBody.innerHTML = this.getBanner(
            this.projectedCutText,
            'projected'
          )
        }

        const cutlineRows = this.querySelectorAll(
          `[data-position="${this.cutLinePos}"]`
        )

        if (cutlineRows.length > 0) {
          cutlineRows[cutlineRows.length - 1].after(tableBody)
          projectedCutHeader = true
        } else {
          for (let i = this.cutLinePos - 1; i >= 1; i--) {
            if (
              this.querySelectorAll(`[data-position="${i}"]`).length > 0 &&
              !projectedCutHeader
            ) {
              const lowerPositionRows = this.querySelectorAll(
                `[data-position="${i}"]`
              )
              lowerPositionRows[lowerPositionRows.length - 1].after(tableBody)
              projectedCutHeader = true
              return
            }
          }
        }
      } else if (
        // Same logic as above except it's based on cutlineScore instead of cutlinePosition
        (this.cutLineScore &&
          !projectedCutHeader &&
          !cutHeader &&
          !this.showSecondProjectedCutline) ||
        (this.cutLineScore &&
          !projectedCutHeader &&
          !secondaryCutHeader &&
          this.showSecondProjectedCutline)
      ) {
        tableBody.innerHTML = this.getBanner(this.projectedCutText, 'projected')

        if (
          this.showSecondProjectedCutline &&
          this.hasSecondProjectedCutText &&
          cutHeader
        ) {
          tableBody.innerHTML = this.getBanner(
            this.secondProjectedCutText,
            'projected'
          )
        } else {
          tableBody.innerHTML = this.getBanner(
            this.projectedCutText,
            'projected'
          )
        }

        // look up items index against a list of player rows
        const cutlineRows = this.querySelectorAll(
          `[data-score="${this.cutLineScore}"]`
        )

        if (cutlineRows.length > 0) {
          cutlineRows[cutlineRows.length - 1].after(tableBody)
          projectedCutHeader = true
        } else {
          for (let i = 0; i < this.cutLineScore + 1; i++) {
            if (
              this.querySelectorAll(`[data-score="${i}"]`).length > 0 &&
              !projectedCutHeader
            ) {
              const lowerPositionRows = this.querySelectorAll(
                `[data-score="${i}"]`
              )
              lowerPositionRows[lowerPositionRows.length - 1].after(tableBody)
              projectedCutHeader = true
              return
            }
          }
        }
      }
    }
  }

  getBanner (text, styleAttr) {
    return `
    <tr>
      <td class="LeaderboardTable-cutLine" colspan="13" data-${styleAttr}>
        <strong class="LeaderboardTable-cutText">${text}</strong>
      </td>
    </tr>`
  }

  requestContent (apiUrl) {
    this.controller = new window.AbortController()
    const signal = this.controller.signal
    const FETCH_TIMEOUT = 5000
    return new Promise((resolve, reject) => {
      // Set timeout timer
      const timer = setTimeout(
        () => reject(new Error('Request timed out')),
        FETCH_TIMEOUT
      )
      window
        .fetch(apiUrl, {
          headers: { 'Content-Type': 'text/html' },
          signal
        })
        .then(
          response => {
            if (response.status !== 200) {
              // make the promise be rejected if we didn't get a 200 response
              // triggers an error for a 404 or other errors
              throw new Error('Not 200 response')
            } else {
              resolve(response.text())
            }
          },
          err => reject(err)
        )
        .catch(err => {
          reject(err)
        })
        .finally(() => clearTimeout(timer))
    })
  }

  bindFavorite (item) {
    item.addEventListener(
      'click',
      (this._bindFavorite = event => {
        event.preventDefault()
        event.stopPropagation()
        const leaderboardRow = event.currentTarget.closest('.LeaderboardRow')
        const playerId = leaderboardRow.getAttribute('data-playerid')

        if (leaderboardRow.getAttribute('data-favorites-state') === 'true') {
          this.removeFavoritePlayer(playerId)
          const storageItems = this.removeStorageItem(
            this.favoritesStorageName,
            playerId
          )
          this.updateFavorites(storageItems)
          leaderboardRow.setAttribute('data-favorites-state', false)
        } else {
          const clonedPlayer = leaderboardRow.cloneNode(true)
          const clonedTarget = clonedPlayer.querySelector(
            '.LeaderboardRow-scorecard-target'
          )
          // if adding a favorite and it's open, remove the opened state and flyout
          // from the cloned content so the user has to re-init the elements
          // inside of the flyout by clicking on the player opposed to us having to destroy
          // all the carousels, clone, re-inite the carousels, then append the cloned mark up
          if (clonedTarget.hasAttribute('data-scorecard-show')) {
            clonedTarget.children[0].remove()
            clonedTarget.removeAttribute('data-scorecard-show')
          }
          this.addFavorite(clonedPlayer)
          const storageItems = this.setStorageItem(
            this.favoritesStorageName,
            playerId
          )
          this.updateFavorites(storageItems)
          leaderboardRow.setAttribute('data-favorites-state', true)
        }
      }),
      false
    )
  }

  // method to bind removal methods in the favorite target area when user addFavorite
  bindRemoveFavorite (item) {
    item.addEventListener(
      'click',
      (this._bindRemoveFavorite = event => {
        event.preventDefault()
        event.stopPropagation()
        const leaderboardRow = event.currentTarget.closest('.LeaderboardRow')
        const playerId = leaderboardRow.getAttribute('data-playerid')

        if (leaderboardRow.getAttribute('data-favorites-state') === 'true') {
          this.removeFavoritePlayer(playerId)
          const storageItems = this.removeStorageItem(
            this.favoritesStorageName,
            playerId
          )
          this.updateFavorites(storageItems)
          document.body
            .querySelector(
              `[data-favorites-source] [data-playerid="${playerId}"]`
            )
            .setAttribute('data-favorites-state', false)
        }
      }),
      false
    )
  }

  bindScorecardData (item) {
    item.addEventListener(
      'click',
      debounce(
        500,
        (this._bindScorecardData = event => {
          const playerRow = event.currentTarget.querySelector(
            '.LeaderboardRow-player'
          )
          const playerScorecardTarget = playerRow
            .closest('tbody')
            .querySelector('.LeaderboardRow-scorecard-target')
          const playerTotalStrokes = playerRow
            .closest('tbody')
            .querySelector('.LeaderboardRow-overallPar').textContent

          this.toggleScorecard(
            playerScorecardTarget,
            event.currentTarget.closest('tbody')
          )

          if (playerScorecardTarget.children.length) {
            // exit out without making a request for scorecard data
            return
          }

          const playerEndpoint = playerRow.getAttribute('data-scorecard-source')
          const playerEndpointPlusTime =
            typeof this.timestampParameter !== 'undefined'
              ? `${playerEndpoint}${this.timestampParameter}`
              : playerEndpoint

          if (playerEndpoint) {
            this.requestContent(playerEndpointPlusTime).then(
              response => {
                const modalContent = document.createElement('div')
                modalContent.innerHTML = response
                const overallStrokes = modalContent.querySelector(
                  '.PlayerSummary-overallBlock'
                )
                // update the flyout with overall strokes
                if (playerTotalStrokes && overallStrokes !== null) {
                  overallStrokes.innerHTML = playerTotalStrokes
                }
                playerScorecardTarget.appendChild(modalContent)

                // bind event listener for Leaderboard Flyout close buttons, toggle scorecard state
                playerScorecardTarget
                  .querySelector('.LeaderboardFlyoutModule-close')
                  .addEventListener(
                    'click',
                    (this._bindCloseData = event => {
                      this.toggleScorecard(
                        playerScorecardTarget,
                        event.currentTarget.closest('tbody')
                      )
                    })
                  )
              },
              failure => {
                console.log('could not load requested scorecard data')
              }
            )
          }
        }),
        true
      )
    )
  }

  toggleScorecard (playerScorecardTarget, playerTarget) {
    if (playerScorecardTarget.hasAttribute('data-scorecard-show')) {
      playerScorecardTarget.removeAttribute('data-scorecard-show')
      this.removeStorageItem(
        this.showPlayerDataStorageName,
        playerTarget.getAttribute('data-playerid'),
        window.sessionStorage
      )
      return
    }

    this.setStorageItem(
      this.showPlayerDataStorageName,
      playerTarget.getAttribute('data-playerid'),
      window.sessionStorage
    )
    playerScorecardTarget.setAttribute('data-scorecard-show', '')
  }

  removeFavoritePlayer (id) {
    this.favoriteTarget
      .querySelector(`[data-playerid="${id}"] [data-favorites-trigger]`)
      .removeEventListener('click', this._bindRemoveFavorite)
    this.favoriteTarget.querySelector(`[data-playerid="${id}"]`).remove()
  }

  updateFavorites (storedFavorites) {
    if (storedFavorites !== null && storedFavorites.length > 0) {
      this.favoriteTarget.setAttribute('data-favorites-show', '')
    } else {
      if (this.favoriteTarget.hasAttribute('data-favorites-show')) {
        this.favoriteTarget.removeAttribute('data-favorites-show')
      }
    }
  }

  addFavorite (player) {
    if (typeof player === 'object') {
      player.setAttribute('data-favorites-state', true)
      this.bindRemoveFavorite(player.querySelector('.LeaderboardRow-favorite'))
      this.bindScorecardData(player.querySelector('.LeaderboardRow-row'))
      const playerIndex = parseInt(player.getAttribute('data-rowindex'))

      const playerRows = this.favoriteTarget.querySelectorAll('.LeaderboardRow')
      if (playerRows.length <= 0) {
        this.favoriteTarget.appendChild(player)
      } else {
        for (const item of playerRows) {
          if (playerIndex <= parseInt(item.getAttribute('data-rowindex'))) {
            item.before(player)
            return
          } else {
            item.after(player)
          }
        }
      }
    }
  }

  findPlayerFromSource (id) {
    const playerObject = document.body.querySelector(
      `[data-favorites-source] [data-playerid="${id}"]`
    )
    return playerObject
  }

  setStorageItem (storageName, storageValue, storageType) {
    const storage = storageType || window.localStorage
    if (!storage.getItem(storageName)) {
      storage.setItem(storageName, `["${storageValue}"]`)
      return storageName
    } else {
      const storageArray = storage.getItem(storageName)
        ? JSON.parse(storage.getItem(storageName))
        : []

      if (storageArray.indexOf(storageValue) === -1) {
        storageArray.push(storageValue)
      }

      storage.setItem(storageName, JSON.stringify(storageArray))
      return storageArray
    }
  }

  removeStorageItem (storageName, storageValue, storageType) {
    const storage = storageType || window.localStorage
    if (storage.getItem(storageName)) {
      const storageArray = JSON.parse(storage.getItem(storageName))

      const newstorageItems = storageArray.filter(function (ele) {
        return ele !== storageValue
      })
      storage.setItem(storageName, JSON.stringify(newstorageItems))
      return newstorageItems
    }
  }

  storageAvailable (type) {
    let storage
    try {
      storage = window[type]
      const x = '__storage_test__'
      storage.setItem(x, x)
      storage.removeItem(x)
      return true
    } catch (e) {
      return (
        e instanceof DOMException &&
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage &&
        storage.length !== 0
      )
    }
  }

  refreshLeaderboard (refreshEndpoint) {
    this.requestContent(refreshEndpoint).then(
      response => {
        const content = document.createElement('div')
        content.innerHTML = response
        const module = content.querySelector('.LeaderboardModule-wrapper')
        this.replaceWith(module)

        document.body.dispatchEvent(this.ajaxRendered)

        // refresh ads
        if (typeof window._fw_linktag_params !== 'undefined') {
          window._fw_linktag_refresh && window._fw_linktag_refresh()
        }

        if (typeof window.mps !== 'undefined') {
          const mpsAds = this.querySelectorAll('.MpsAd-ad')

          mpsAds.forEach((item, i) => {
            const id = item.getAttribute('id')
            const slot = item.getAttribute('data-ad-slot')

            window.mps.cloneAd(`#${id}`, slot)
          })
        }
      },
      failure => {
        console.log('could not load requested scorecard data')
      }
    )
  }

  async importAlgolia () {
    const appId = this.coachPromo.getAttribute('data-app-id')
    const apiKey = this.coachPromo.getAttribute('data-api-key')
    const indexKey = this.coachPromo.getAttribute('data-index')
    try {
      const { default: Algolia } = await import('algoliasearch')
      const client = Algolia(appId, apiKey)
      const index = client.initIndex(indexKey)
      this.getClosestCoachByIp(index)
    } catch (e) {
      console.warn('Error loading Algolia', e)
    }
  }

  createCoachEl (coach) {
    const coachTemplate = this.coachPromo.querySelector('[data-template]')
    const clonedCoachTemplate = coachTemplate.content.cloneNode(true)
    const cityEl = clonedCoachTemplate.querySelector('[data-city]')
    const stateEl = clonedCoachTemplate.querySelector('[data-state]')
    const firstnameEl = clonedCoachTemplate.querySelectorAll('[data-firstname]')
    const lastnameEl = clonedCoachTemplate.querySelectorAll('[data-lastname]')
    const coachLinkEls = clonedCoachTemplate.querySelectorAll('[data-link]')
    const imageEl = clonedCoachTemplate.querySelector('[data-img]')
    const firstName = coach.firstName ? coach.firstName : ''
    const lastName = coach.lastName ? coach.lastName : ''
    const slug = coach.customSlug ? coach.customSlug : ''
    const profileImage = coach.profilePhoto ? coach.profilePhoto : null
    const coachLink = this.coachPromo.getAttribute('data-href')

    cityEl.textContent = coach.city ? coach.city : ''
    stateEl.textContent = coach.state ? coach.state : ''

    firstnameEl.forEach(item => {
      item.textContent = firstName
    })

    lastnameEl.forEach(item => {
      item.textContent = lastName
    })

    coachLinkEls.forEach(item => {
      item.setAttribute('href', constructedLink)
    })

    const constructedLink = coachLink
      .replace('{customSlug}', slug)
      .replace('{firstName}', firstName)
      .replace('{lastName}', lastName)

    if (profileImage !== null) {
      imageEl.setAttribute('src', profileImage)
      imageEl.setAttribute('alt', `${firstName} ${lastName} headshot`)
    }

    coachLinkEls.forEach(item => {
      item.setAttribute('href', constructedLink)
    })

    this.coachPromo.querySelector('.CoachingLivePromo').remove()
    this.coachPromo.appendChild(clonedCoachTemplate)

    this.showCoachPromo()
  }

  showCoachPromo () {
    this.coachPromo.setAttribute('data-show-coach', '')
    // setting cloned coach to the window so the same coach loaded gets
    // set on refresh of the module.
    window.clonedCoachPromoSessionEl = this.coachPromo.cloneNode(true)
  }

  async getClosestCoachByIp (index) {
    const oneHundredMiles = 100 * 1609 // 160900
    const twentyFiveMiles = 25 * 1609 // 40225
    const getRandomInt = max => Math.floor(Math.random() * max)

    const { hits } = await index.search('', {
      aroundLatLngViaIP: true,
      aroundRadius: oneHundredMiles,
      aroundPrecision: [{ from: 0, value: twentyFiveMiles }],
      getRankingInfo: true
    })

    if (!hits || hits.length === 0) return null

    if (hits.length === 1) return hits[0]

    const hitsWithin25Miles = hits.filter(hit => {
      return hit._rankingInfo.geoDistance === 0
    })

    if (hitsWithin25Miles.length === 0) return hits[0]

    const randomHitNumberWithin25Miles = getRandomInt(hitsWithin25Miles.length)

    this.createCoachEl(hitsWithin25Miles[randomHitNumberWithin25Miles])
  }

  disconnectedCallback () {
    this.removeEventListener(
      'Ajax:Rendered-leaderboardFlyout',
      this._cloneToFavorites
    )

    // bind favoriting toggling for favorites
    this.querySelectorAll('.LeaderboardTable .LeaderboardRow-favorite').forEach(
      item => {
        item.removeEventListener('click', this._bindFavorite)
      }
    )

    // bind event for player scoreboard toggle
    this.querySelectorAll('.LeaderboardTable .LeaderboardRow-row').forEach(
      item => {
        item.removeEventListener('click', this._bindScorecardData)
      }
    )
    // remove event listeners for all Leaderboard Flyout close buttons
    this.querySelectorAll(
      '.LeaderboardRow-scorecard-target .LeaderboardFlyoutModule-close'
    ).forEach(item => {
      item.removeEventListener('click', this._bindCloseData)
    })
  }
}
