export class PlayerStatsCarousel extends window.HTMLElement {
  connectedCallback () {
    this.carouselSlides = this.querySelector('[class$="-slides"]') || this

    this.onFlickityLoad = import(/* webpackChunkName: 'Flickity' */ 'flickity')
      .then(({ default: Flickity }) => {
        this.handleOptions()
        this.flickity = new Flickity(this.carouselSlides, this.carouselOptions)
      })
      .catch(e => console.warn('Error loading Flickity', e))

    document.body.addEventListener(
      'PS-TABBED-TARGETS:Tabbed',
      (this._tabbed = () => {
        window.setTimeout(() => {
          if (typeof this.flickity !== 'undefined') {
            this.flickity.resize()
          }
        }, 250)
      })
    )
  }

  handleOptions () {
    this.carouselOptions = {
      adaptiveHeight: true,
      cellAlign: 'left',
      groupCells: true,
      imagesLoaded: true,
      pageDots: false,
      contain: true
    }
  }

  disconnectedCallback () {
    this.onFlickityLoad.then(({ default: Flickity }) => {
      this.flickity.destroy()
    })
    document.body.removeEventListener('PS-TABBED-TARGETS:Tabbed', this._tabbed)
  }
}
