import ListCarousel from './ListCarouselReact'
import React from 'react'

const ScorecardMediaModule = props => {
  const {
    scorecardMedia,
    handleModalOpen,
    selectedVideos,
    setSelectedVideos,
    setHoleSelect,
    highlightVideo
  } = props

  const bestShotsVideos =
    scorecardMedia && scorecardMedia.bestShotsVideos
      ? scorecardMedia.bestShotsVideos
      : []
  const scorecardVideos =
    scorecardMedia && scorecardMedia.scorecardVideos
      ? scorecardMedia.scorecardVideos
      : []
  const bestShotsText = 'Best Shots'
  const allShotsText = 'All Shots'
  const highlightReelText = 'Highlight Reel'

  const handleVideoSelect = e => {
    const tabbedId = e.currentTarget.getAttribute('data-tabbed-id')
    setSelectedVideos(tabbedId)
  }

  return (
    <React.Fragment>
      {(scorecardVideos.length > 0 ||
        bestShotsVideos.length > 0 ||
        highlightVideo) && (
        <div
          className="LeaderboardFlyoutModule-highlights"
          data-best-reel={highlightVideo ? true : null}
        >
          {bestShotsVideos &&
            bestShotsVideos.length > 0 &&
            (scorecardVideos && scorecardVideos.length > 0) && (
              <div className="LeaderboardFlyoutModule-highlights-tabs">
                <div className="LeaderboardFlyoutModule-highlights-tabButtons">
                  {highlightVideo && (
                    <div className="LeaderboardFlyoutModule-highlights-reel">
                      <a
                        href={highlightVideo.videoUri || '#'}
                        onClick={e => {
                          e.preventDefault()

                          handleModalOpen({
                            hole: null,
                            highlightVideo: true,
                            bestShotsVideo: false,
                            allVideo: false,
                            videoIndex: null
                          })
                        }}
                      >
                        <svg className="play-video">
                          <use href="#video-camera" />
                        </svg>
                        {highlightReelText}
                      </a>
                    </div>
                  )}
                  {bestShotsVideos.length > 0 ? (
                    <button
                      className="LeaderboardFlyoutModule-highlights-tab"
                      data-tabbed-target-in={
                        selectedVideos === 'best4' ? 'show-tab' : null
                      }
                      data-tabbed-id="best4"
                      // data-tabbed-group="highlights"
                      onClick={handleVideoSelect}
                    >
                      {bestShotsText}
                    </button>
                  ) : null}
                  {bestShotsVideos.length > 0 ? (
                    <button
                      className="LeaderboardFlyoutModule-highlights-tab"
                      data-tabbed-target-in={
                        selectedVideos === 'all4' ? 'show-tab' : null
                      }
                      data-tabbed-id="all4"
                      // data-tabbed-group="highlights"
                      onClick={handleVideoSelect}
                    >
                      {allShotsText}
                    </button>
                  ) : null}
                </div>
                <div
                  className="LeaderboardFlyoutModule-highlights-tabContent"
                  data-tabbed-target-in={
                    selectedVideos === 'best4' ? 'show-content' : null
                  }
                  data-tabbed-target-id="best4"
                  data-tabbed-target="highlights"
                >
                  {bestShotsVideos && bestShotsVideos.length > 0 && (
                    <ListCarousel
                      data={bestShotsVideos}
                      bestParam={'&best=true'}
                      selectedVideos={selectedVideos}
                      handleModalOpen={handleModalOpen}
                      setHoleSelect={setHoleSelect}
                    />
                  )}
                </div>
                <div
                  className="LeaderboardFlyoutModule-highlights-tabContent"
                  data-tabbed-target-in={
                    selectedVideos === 'all4' ? 'show-content' : null
                  }
                  data-tabbed-target-id="all4"
                  data-tabbed-target="highlights"
                >
                  {scorecardVideos && scorecardVideos.length > 0 && (
                    <ListCarousel
                      data={scorecardVideos}
                      bestParam={'&best=true'}
                      selectedVideos={selectedVideos}
                      handleModalOpen={handleModalOpen}
                      setHoleSelect={setHoleSelect}
                    />
                  )}
                </div>
              </div>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export default ScorecardMediaModule
