import { Throttle } from 'throttle'
import { debounce } from 'debounce'

// widths determined by designs
export class PageHeader extends window.HTMLElement {
  connectedCallback () {
    this.cacheElements()
    this.handleEventListeners()

    this.setMoreNav()
    window.setTimeout(() => {
      // prevents css transistions from animating on page load
      this.classList.remove('preload')
      this.checkForHeaderSticky()
      if (this.allItems.length > 0) {
        this.linksAdapt()
      }
    }, 500)

    if (document.querySelector('.ArticleLongform')) {
      this.updateProgressBar()

      window.addEventListener(
        'scroll',
        Throttle(50, () => {
          this.updateProgressBar()
        })
      )
    }

    window.addEventListener(
      'resize',
      (this._resizeMenu = e => {
        debounce(250, () => {
          if (this.allItems.length > 0) {
            this.linksAdapt()
          }
        }).call()
      })
    )

    const searchButton = document.querySelector('.Page-header-search-button')
    if (searchButton) {
      searchButton.addEventListener('click', event => {
        const toggledState = event.currentTarget.closest('[data-search-toggle]')
        if (this.searchInputField.value.length === 0) {
          event.preventDefault()
        }

        this.querySelector('.Page-header-search-input').focus()
        toggledState.setAttribute('data-search-toggle', true)
        window.setTimeout(() => {
          this.linksAdapt()
        }, 300)
      })

      document
        .querySelector('.Page-header-search-close')
        .addEventListener('click', e => {
          e.currentTarget
            .closest('[data-search-toggle]')
            .setAttribute('data-search-toggle', false)
          window.setTimeout(() => {
            this.linksAdapt()
          }, 300)
        })
    }
  }

  cacheElements () {
    this.banner = this.querySelector('.Banner')
    this.searchInputField = this.querySelector('input.Page-header-search-input')
    this.childEls = this.querySelectorAll(
      '.Page-header-navigation .Navigation-items-item'
    )
    this.childStack = Array.from(this.childEls)

    this.menuButton = this.querySelector('.Page-header-menu-trigger')
    this.closeBtn = this.querySelector('.Page-header-menu-close')
    this.hamburgerMenu = this.querySelector('.Page-header-hamburger-menu')

    this.navWrapper = this.querySelector('.Page-header-navigation')
    this.container = this.querySelector('.Page-header-navigation .Navigation')
    this.allItems = this.querySelectorAll(
      '.Page-header-navigation .Navigation-items-item'
    )
    this.primary = this.querySelector('.Page-header-navigation')
    this.primaryItems = Array.from(
      this.querySelectorAll(
        '.Page-header-navigation .Navigation-items-item:not([data-more-trigger])'
      )
    )
    this.moreLi = this.querySelector('[data-more-trigger]')
    this.moreBtn = this.querySelector('[data-more-trigger] [data-more-items]')
    this.subNavMenu = this.querySelector(
      '.Page-header-navigation [data-more-trigger] [data-more-subnav]'
    )
    this.progressBar = this.querySelector('[data-progress-bar]')
    this.mainContent = document.querySelector('main[class$="-main"]')
    this.promoUpNext = document.querySelector('.ArticleLongform-upNext')
    this.upNextPercentage = parseInt(
      document.body.getAttribute('data-upnext-percent')
    )
  }

  handleEventListeners () {
    this.menuButton.addEventListener(
      'click',
      (this._openMenu = e => {
        e.preventDefault()

        if (!this.isMenuOpen()) {
          this.openMenu()
          return
        }

        this.closeMenu()
      })
    )

    this.closeBtn.addEventListener(
      'click',
      (this._closeMenu = e => {
        e.preventDefault()
        this.closeMenu()
      })
    )

    // if user is tabbing it will change the header color on the homepage
    if (document.documentElement.className === 'HomePage') {
      document.addEventListener('keydown', e => {
        if (e.key === 'Tab') {
          if (
            !this.hasAttribute('data-header-focused') &&
            document.activeElement.closest('.Page-header-main')
          ) {
            this.setAttribute('data-header-focused', '')
          }

          if (
            this.hasAttribute('data-header-focused') &&
            !document.activeElement.closest('.Page-header-main')
          ) {
            this.removeAttribute('data-header-focused')
          }
        }
      })
    }
  }

  openMenu () {
    document.documentElement.setAttribute(
      'data-toggle-header',
      'hamburger-menu'
    )
    this.setAttribute('data-toggle-header', 'hamburger-menu')
    this.menuButton.setAttribute('aria-expanded', 'true')
    this.hamburgerMenu.focus()
  }

  closeMenu () {
    if (this.isMenuOpen()) {
      document.documentElement.removeAttribute('data-toggle-header')
      this.removeAttribute('data-toggle-header')
      this.menuButton.setAttribute('aria-expanded', 'false')
    }
  }

  isMenuOpen () {
    if (
      document.documentElement.getAttribute('data-toggle-header') ===
      'hamburger-menu'
    ) {
      return true
    } else {
      return false
    }
  }

  checkForHeaderSticky () {
    const self = this

    function checkHeaderLocation () {
      if (window.scrollY > 0) {
        document.documentElement.setAttribute('data-header-sticky', '')
      } else if (
        document.documentElement.hasAttribute('data-header-sticky') &&
        !self.hasAttribute('data-toggle-header')
      ) {
        document.documentElement.removeAttribute('data-header-sticky')
      }
    }

    window.addEventListener(
      'scroll',
      Throttle(50, () => {
        checkHeaderLocation()
      })
    )

    checkHeaderLocation()
  }

  updateProgressBar () {
    const windowScroll = window.scrollY || window.pageYOffset
    const contentHeight = this.mainContent.offsetHeight
    const scrollPercentage = Math.ceil((windowScroll / contentHeight) * 100)
    this.progressBar.style.width = `${scrollPercentage}%`

    // If in article longform we want to show up next at a certain
    // percentage of the page
    if (
      this.promoUpNext !== null &&
      scrollPercentage >= this.upNextPercentage &&
      scrollPercentage < 99
    ) {
      this.promoUpNext.setAttribute('data-visible', '')
    } else if (
      (this.promoUpNext !== null && scrollPercentage < this.upNextPercentage) ||
      (this.promoUpNext !== null && scrollPercentage >= 99)
    ) {
      this.promoUpNext.removeAttribute('data-visible')
    }
  }

  // returns the total width of the items in the array
  getChildWidths (array) {
    let width = 0

    for (let i = 0; i < array.length; i++) {
      const style = window.getComputedStyle(array[i])
      width +=
        array[i].getBoundingClientRect().width +
        parseInt(style.marginLeft) +
        parseInt(style.marginRight)
    }

    return width
  }

  linksAdapt () {
    // reveal all items for the calculation
    this.allItems.forEach(item => {
      item.removeAttribute('data-hide')
    })

    this.secondaryItems.forEach(item => {
      item.removeAttribute('data-show')
    })

    const primaryWidth = this.primary.offsetWidth - 62
    this.moreLi.setAttribute('data-hide', '')
    if (this.getChildWidths(this.primaryItems) > primaryWidth) {
      this.container.setAttribute('data-more-state', '')
    } else {
      this.container.removeAttribute('data-more-state')
      this.navWrapper.setAttribute('data-show-nav', '')
      return
    }

    for (let i = this.primaryItems.length - 1; i >= 0; i--) {
      const array = this.primaryItems.slice(0, i)
      const arrayWidths = this.getChildWidths(array)
      this.primaryItems[i].setAttribute('data-hide', '')
      if (arrayWidths < primaryWidth) {
        let diff = this.primaryItems.length - array.length
        while (diff > 0) {
          if (
            this.secondaryItems[this.secondaryItems.length - diff].nodeName !==
            'SPAN'
          ) {
            this.secondaryItems[this.secondaryItems.length - diff].setAttribute(
              'data-show',
              ''
            )
            this.moreLi.removeAttribute('data-hide')
          }
          diff--
        }
        this.navWrapper.setAttribute('data-show-nav', '')
        break
      }
    }
  }

  setMoreNav () {
    const array = this.childStack.slice(0, this.childStack.length - 1)
    array.forEach(child => {
      this.subNavMenu.innerHTML += child.outerHTML
    })
    if (this.subNavMenu) {
      this.secondaryItems = this.subNavMenu.querySelectorAll(
        '.Navigation-items-item'
      )
    }
  }

  disconnectedCallback () {
    this.menuButton.removeEventListener('click', this._openMenu)
    this.closeBtn.removeEventListener('click', this._closeMenu)
    window.removeEventListener('resize', this._resizeMenu)
  }
}
