import GetImage from './util/GetImage'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const MiniLeaderboardRow = props => {
  const { row, currentRound, golfer } = props
  const belowPar = row.overallParInt < 0
  const golferStaticInfo = golfer && golfer[0] ? golfer[0] : null

  const roundScore =
    row && row.round1GolferRoundScore && currentRound === 1
      ? row.round1GolferRoundScore.roundScore
      : row && row.round2GolferRoundScore && currentRound === 2
        ? row.round2GolferRoundScore.roundScore
        : row && row.round3GolferRoundScore && currentRound === 3
          ? row.round3GolferRoundScore.roundScore
          : row && row.round4GolferRoundScore && currentRound === 4
            ? row.round4GolferRoundScore.roundScore
            : null

  const thru =
    row && row.round1GolferRoundScore && currentRound === 1
      ? row.round1GolferRoundScore.thru
      : row && row.round2GolferRoundScore && currentRound === 2
        ? row.round2GolferRoundScore.thru
        : row && row.round3GolferRoundScore && currentRound === 3
          ? row.round3GolferRoundScore.thru
          : row && row.round4GolferRoundScore && currentRound === 4
            ? row.round4GolferRoundScore.thru
            : null

  const hole10Start =
    currentRound === 1 && row && row.round1GolferRoundTeeTime
      ? row.round1GolferRoundTeeTime.hole10Start
      : currentRound === 2 && row && row.round2GolferRoundTeeTime
        ? row.round2GolferRoundTeeTime.hole10Start
        : currentRound === 3 && row && row.round3GolferRoundTeeTime
          ? row.round3GolferRoundTeeTime.hole10Start
          : currentRound === 4 && row && row.round4GolferRoundTeeTime
            ? row.round4GolferRoundTeeTime.hole10Start
            : false

  return (
    <div
      className="LeaderboardMiniRow"
      // data-inprogress={!roundCompleted ? true : null} TODO: Verify if this data attribute is needed
      data-leaderboard-winner={row.winner || null}
    >
      <div className="LeaderboardMiniRow-row">
        <div className="LeaderboardMiniRow-data">
          <span className="LeaderboardMiniRow-position">
            {row.position && row.position}
          </span>
        </div>
        <div className="LeaderboardMiniRow-data">
          <div className="LeaderboardMiniRow-player">
            {golferStaticInfo && golferStaticInfo.image && (
              <a
                href={
                  golferStaticInfo && golferStaticInfo.playerPageUrl
                    ? golferStaticInfo.playerPageUrl
                    : null
                }
                target="_blank"
                rel="noreferrer"
                className="LeaderboardMiniRow-playerLink"
              >
                {golferStaticInfo && golferStaticInfo.image && (
                  <div className="LeaderboardMiniRow-image">
                    <LazyLoadImage
                      alt={`headshot of ${golferStaticInfo.firstName ||
                        ''} ${golferStaticInfo.lastName || ''}`}
                      height="57"
                      src={GetImage.src(golferStaticInfo.image)}
                      width="57"
                    />
                  </div>
                )}
              </a>
            )}
            <a
              href={
                golferStaticInfo && golferStaticInfo.playerPageUrl
                  ? golferStaticInfo.playerPageUrl
                  : null
              }
              target="_blank"
              rel="noreferrer"
              className="LeaderboardMiniRow-playerInfo"
            >
              {golferStaticInfo && golferStaticInfo.countryFlag && (
                // Image Size teamFlagSmall
                <span className="LeaderboardMiniRow-flag">
                  <LazyLoadImage
                    className="Image"
                    src={GetImage.src(golferStaticInfo.countryFlag)}
                    alt={`flag for ${
                      golferStaticInfo.firstName
                        ? golferStaticInfo.firstName
                        : ''
                    } ${
                      golferStaticInfo.lastName ? golferStaticInfo.lastName : ''
                    }`}
                    height="27"
                    width="42"
                  />
                </span>
              )}
              {golferStaticInfo && golferStaticInfo.firstName && (
                <span className="LeaderboardMiniRow-firstName">
                  {golferStaticInfo.firstName}
                </span>
              )}
              {golferStaticInfo && golferStaticInfo.lastName && (
                <span
                  className="LeaderboardMiniRow-lastName"
                  data-initial={
                    golferStaticInfo.firstInitial
                      ? golferStaticInfo.firstInitial
                      : null
                  }
                >
                  {golferStaticInfo.lastName}
                </span>
              )}
            </a>
          </div>
        </div>
        <div className="LeaderboardMiniRow-data">
          {row.overallPar && (
            <span
              className="LeaderboardMiniRow-overallPar"
              data-black={belowPar ? null : true}
            >
              {row.overallPar}
            </span>
          )}
        </div>
        <div className="LeaderboardMiniRow-data">
          {thru && (
            <div className="LeaderboardMiniRow-thru">
              {thru}
              {(thru !== '-' || thru !== null) && hole10Start ? (
                <span
                  className="LeaderboardMiniRow-hole10Start"
                  data-hole-10-start
                >
                  *
                </span>
              ) : null}
            </div>
          )}
        </div>
        <div className="LeaderboardMiniRow-data">
          {roundScore && (
            <span className="LeaderboardMiniRow-today">{roundScore}</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default MiniLeaderboardRow
