import React from 'react'
import ShotLinkHole from './ShotlinkHoleReact'

const ShotlinkModule = props => {
  const {
    shotlinks,
    holeSelect,
    setHoleSelect,
    shotlinkHoleImages,
    teeData
  } = props

  const hole = 'Hole'
  const shotlinkShots = shotlinks
  const shotlinkHoles = []
  const tees = teeData

  const teeInfo = []

  for (let i = 1; i < 19; i++) {
    if (shotlinkShots['hole' + i + 'Shots']) {
      shotlinkHoles.push(shotlinkShots['hole' + i + 'Shots'])
    }

    if (tees && tees[`hole${i}`]) {
      teeInfo.push(tees[`hole${i}`])
    }
  }

  const handlePreviousSelect = () => {
    if (holeSelect === 1) {
      return holeSelect
    } else if (holeSelect <= 10) {
      setHoleSelect(holeSelect - 1)
    } else setHoleSelect(holeSelect - 1)
  }

  const handleNextSelect = () => {
    if (holeSelect === 18) {
      return holeSelect
    } else if (holeSelect >= 9) {
      setHoleSelect(holeSelect + 1)
    } else setHoleSelect(holeSelect + 1)
  }

  return (
    <React.Fragment>
      <div className="LeaderboardFlyoutModule-shotracker">
        <div className="ShotlinkRound">
          <div className="ShotlinkRound-tableHeader">
            <div className="ShotlinkRound-nav">
              <button
                className="flickity-button flickity-prev-next-button previous"
                data-prev
                type="button"
                aria-label="Previous"
                onClick={handlePreviousSelect}
              >
                <svg className="flickity-button-icon" viewBox="0 0 100 100">
                  <path
                    d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
                    className="arrow"
                  />
                </svg>
              </button>
              <div className="ShotlinkRound-currentHole">
                {hole} <span data-scorecard-hole>{holeSelect}</span>
              </div>
              <button
                className="flickity-button flickity-prev-next-button next"
                data-next
                type="button"
                aria-label="Next"
                onClick={handleNextSelect}
              >
                <svg className="flickity-button-icon" viewBox="0 0 100 100">
                  <path
                    d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
                    className="arrow"
                    transform="translate(100, 100) rotate(180) "
                  />
                </svg>
              </button>
            </div>
          </div>
          <div
            className="ShotlinkRound-slides"
            data-length={shotlinkHoles.length}
          >
            <ShotLinkHole
              shotData={shotlinkHoles[holeSelect - 1]}
              holeImages={shotlinkHoleImages[holeSelect - 1]}
              teeInfo={
                teeInfo && teeInfo.length > 0 ? teeInfo[holeSelect - 1] : null
              }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ShotlinkModule
