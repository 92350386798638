/**
 * Element that handles toggling state for another element on the page.
 * Note: Styling can be handled on an individual need basis by adding a class
 * to the element.
 */
export class PsPagination extends window.HTMLElement {
  connectedCallback () {
    this.onTrackerLoad = import(
      /* webpackChunkName: 'Tracker' */ 'eventTracker'
    )
      .then(({ Tracker }) => {
        this.listenerTracker = new Tracker()
        this.e = this.parentElement
        this.code = ''
        this.paginationURL = window.location.href
        this.hasParams = this.paginationURL.split('?')
        this.pageParam = this.getAttribute('data-page-parameter')
        this.searchModule = this.closest('ps-search-module')
        this.init(this, {
          size: parseInt(this.getAttribute('data-page-count'), 10), // pages size
          page: parseInt(this.getAttribute('data-page-number'), 10), // selected page
          step: parseInt(this.getAttribute('data-page-step'), 10) // pages before and after current
        })
      })
      .catch(e => console.warn('Error loading Tracker', e))
  }

  extend (data) {
    data = data || {}
    this.size = data.size || 300
    this.page = data.page || 1
    this.step = data.step || 3
  }

  getPaginationURL (page) {
    // remove the pagination param if it exists
    const cleanURL = this.paginationURL.replace(/(\?|&)p=[\d]+/gi, '')
    // check to see if other params exits and to determine the proper marker
    return `${cleanURL}${cleanURL.split('?').length > 1 ? '&' : '?'}p=${page}`
  }

  // add pages by number (from [s] to [f])
  add (s, f) {
    for (let i = s; i < f; i++) {
      this.code +=
        this.page === i
          ? `<div class="pagination-item"><span data-selected>${i}</span></div>`
          : `<div class="pagination-item"><a href="${this.getPaginationURL(
              i
            )}" class="Link" data-page-num>${i}<input data-page-input type="checkbox" name="${
              this.pageParam
            }" value="${i}"/></a></div>`
    }
  }

  // add last page with separator
  last () {
    this.code += `<div class="pagination-item"><i>...</i></div><div class="pagination-item"><a href="${this.getPaginationURL(
      this.size
    )}" class="Link" data-page-num>${
      this.size
    }<input data-page-input type="checkbox" name="${this.pageParam}" value="${
      this.size
    }"/></a></div>`
  }

  // add first page with separator
  first () {
    this.code += `<div class="pagination-item"><a class="Link" href="${this.getPaginationURL(
      1
    )}" data-page-num>1<input data-page-input type="checkbox" name="${
      this.pageParam
    }" value="1"/></a></div><div class="pagination-item"><i>...</i></div>`
  }

  // --------------------
  // Handlers
  // --------------------

  // change page
  click (e) {
    e.preventDefault()
    e.target.querySelector('[data-page-input]').checked = true
  }

  // previous page
  prev () {
    this.page--
    if (this.page < 1) {
      this.page = 1
    }
    this.start()
  }

  // next page
  next () {
    this.page++
    if (this.page > this.size) {
      this.page = this.size
    }
    this.start()
  }

  // --------------------
  // Script
  // --------------------

  // binding pages
  bind () {
    const a = this.e.querySelectorAll('.pagination-item a')
    for (let i = 0; i < a.length; i++) {
      if (+a[i].innerHTML === this.page) a[i].setAttribute('data-selected', '')
      this.listenerTracker.addListener(a[i], 'click', this.click.bind(this))
    }
  }

  // write this
  finish () {
    this.innerHTML = this.code
    this.code = ''
    this.bind()
  }

  // find this type
  start () {
    if (this.size < this.step * 2 + 5) {
      this.add(1, this.size + 1)
    } else if (this.page < this.step * 2 + 1) {
      this.add(1, this.step * 2 + 3)
      this.last()
    } else if (this.page > this.size - this.step * 2) {
      this.first()
      this.add(this.size - this.step * 2 - 2, this.size + 1)
    } else {
      this.first()
      this.add(this.page - this.step, this.page + this.step + 1)
      this.last()
    }
    this.finish()
  }

  // init
  init (e, data) {
    this.extend(data)
    this.start()
  }

  disconnectedCallback () {
    this.onTrackerLoad.then(() => this.listenerTracker.removeListeners())
  }
}
