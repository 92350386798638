import React from 'react'
import ReactDOM from 'react-dom'
import GetImage from './util/GetImage'
import ScoreCardRound from './scorecard-react/ScorecardRoundReact'

const ModalHighlight = props => {
  const {
    allVideos,
    bestShotsVideos,
    highlightVideo,
    modalOpen,
    handleModalOpen,
    handleModalClose,
    firstName,
    lastName,
    image,
    overallPar,
    round,
    thruValues,
    hole10Start,
    holeSelect,
    scorecard,
    scorecardMedia,
    teeData,
    setHoleSelect,
    roundGolferRoundScore
  } = props

  const initialModalOptions = {
    hole: null,
    highlightVideo: false,
    bestShotsVideo: false,
    allVideo: false,
    videoIndex: null
  }

  const modalOptions = Object.assign(initialModalOptions, modalOpen)
  if (modalOpen === false) {
    return null
  }

  const videos =
    modalOptions.allVideo === true
      ? allVideos
      : modalOptions.bestShotsVideo === true
        ? bestShotsVideos
        : null

  const video = (function () {
    if (modalOptions.highlightVideo) {
      return highlightVideo
    } else {
      if (videos) {
        if (modalOptions.videoIndex !== null) {
          return videos[modalOptions.videoIndex]
        } else {
          const index = videos.findIndex(x => x.hole === modalOptions.hole)
          if (index < 0) {
            return null
          } else {
            modalOptions.videoIndex = index
            return videos[index]
          }
        }
      }
    }
  })()

  const playNext = () => {
    if (modalOptions.videoIndex === videos.length - 1) return
    setHoleSelect(videos[modalOptions.videoIndex + 1].hole)
    modalOptions.videoIndex = modalOptions.videoIndex + 1
    handleModalOpen(modalOptions)
  }

  const playPrevious = () => {
    if (modalOptions.videoIndex === 0) return
    setHoleSelect(videos[modalOptions.videoIndex - 1].hole)
    modalOptions.videoIndex = modalOptions.videoIndex - 1
    handleModalOpen(modalOptions)
  }

  const selectCurrentVideo = index => {
    modalOptions.videoIndex = index
    setHoleSelect(videos[index].hole)
    handleModalOpen(modalOptions)
  }

  return ReactDOM.createPortal(
    <div className="Page-modal" onClick={handleModalClose}>
      <div className="Page-modal-wrapper" data-modal-highlight>
        <div
          className="Page-modal-content"
          data-highlight
          onClick={e => e.stopPropagation()}
        >
          <button
            className="Page-modal-close"
            aria-label="close"
            onClick={handleModalClose}
          >
            <svg className="close-x">
              <use href="#close-x" />
            </svg>
          </button>
          <div className="ModalHighlights">
            <div className="ModalHighlights-top">
              <div className="ModalHighlights-playerInfo">
                {image && (
                  <div className="ModalHighlights-image">
                    <img
                      src={GetImage.src(image)}
                      alt={`headshot of ${firstName || ''} ${lastName || ''}`}
                    />
                  </div>
                )}
                {firstName && lastName && (
                  <div className="ModalHighlights-playerName">
                    <div className="ModalHighlights-firstname">{firstName}</div>
                    <div className="ModalHighlights-lastname">{lastName}</div>
                  </div>
                )}
              </div>
              {overallPar && (
                <div className="ModalHighlights-info">
                  <div className="LeaderboardRow-overallPar">{overallPar}</div>
                </div>
              )}
            </div>
            <div className="ModalHighlights-middle" data-media="true">
              <div className="ModalHighlights-shots">
                {videos &&
                  videos.map((item, i) => (
                    <div
                      key={i}
                      className="ModalHighlights-shot"
                      data-url={item.videoUri}
                      data-hole={item.hole}
                      data-current-shot={
                        modalOptions.videoIndex === i ? true : null
                      }
                      onClick={() => selectCurrentVideo(i)}
                      aria-label={`video ${i + 1} of ${scorecardMedia
                        .scorecardVideos.length - 1}`}
                    />
                  ))}
              </div>
              {video && (
                <React.Fragment>
                  <div className="ModalHighlights-title">{video.title}</div>
                  <div className="ModalHighlights-player">
                    <div className="MPXVideoPlayer">
                      <div
                        className="MPXVideoPlayer-screen"
                        data-aspect-ratio="16x9"
                      >
                        <div className="MPXVideoPlayer-iframe-container">
                          <iframe
                            src={video.videoUri}
                            style={{ border: '0' }}
                            allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
                            allowFullScreen
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <ScoreCardRound
                holeSelect={holeSelect}
                round={round}
                handleModalOpen={handleModalOpen}
                setHoleSelect={setHoleSelect}
                modalScorecard={true}
                thruValues={thruValues}
                hole10Start={hole10Start}
                scorecard={scorecard}
                teeData={teeData}
                openedFromModal={true}
                roundGolferRoundScore={roundGolferRoundScore}
              />
            </div>
          </div>
          {videos && modalOptions.videoIndex > 0 && (
            <div>
              <button
                onClick={() => playPrevious()}
                className="flickity-button flickity-prev-next-button previous"
                aria-label="Previous"
              >
                <svg className="flickity-button-icon" viewBox="0 0 100 100">
                  <path
                    d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
                    className="arrow"
                  />
                </svg>
              </button>
            </div>
          )}
          {videos && modalOptions.videoIndex < videos.length - 1 && (
            <div
              data-url={
                videos[modalOptions.videoIndex + 1]
                  ? videos[modalOptions.videoIndex + 1].videoUri
                  : null
              }
              data-hole={
                videos[modalOptions.videoIndex + 1]
                  ? videos[modalOptions.videoIndex + 1].hole
                  : null
              }
            >
              <button
                className="flickity-button flickity-prev-next-button next"
                aria-label="Next"
                onClick={() => playNext()}
              >
                <svg className="flickity-button-icon" viewBox="0 0 100 100">
                  <path
                    d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
                    className="arrow"
                    transform="translate(100, 100) rotate(180) "
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>,
    document.body
  )
}

export default ModalHighlight
