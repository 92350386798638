import { GetUrlParams } from '../../util/UrlUtils'

export class CampaignId extends window.HTMLElement {
  static PARAM_NAME = 'utm_cid'
  static SESSION_NAME = 'bsp.utm'

  connectedCallback () {
    const sessionItem = window.sessionStorage.getItem(CampaignId.SESSION_NAME)
    const campaignInput = this.querySelector('[name="Campaign_ID"]')

    if (GetUrlParams(CampaignId.PARAM_NAME) && campaignInput) {
      campaignInput.setAttribute('value', GetUrlParams(CampaignId.PARAM_NAME))
    } else if (sessionItem && campaignInput) {
      campaignInput.setAttribute('value', sessionItem)
    }
  }
}
