import { setStorageItem, removeStorageItem } from 'storageHooks'

export class FavoriteButton extends window.HTMLElement {
  connectedCallback () {
    this.cacheElements()
    this.checkFavorites()
    this.setListeners()
  }

  get favoriteBtns () {
    return document.querySelectorAll(
      `favorite-button[data-player-id='${this.playerId}'] button`
    )
  }

  cacheElements () {
    this.favoriteBtn = this.getElementsByTagName('button')[0]
    this.playerId = this.getAttribute('data-player-id')
    this.favoritesStorageName = 'pgaPlayerFavorites'
    this.checkedAttr = 'aria-checked'
    this.clickHandler = this.buttonClick.bind(this)
    this.favoriteEvent = new window.CustomEvent('FavoritePlayer:Clicked', {
      bubbles: true
    })
  }

  checkFavorites () {
    const storageItemsFavorite = JSON.parse(
      window.localStorage.getItem(this.favoritesStorageName)
    )
    // if the player favorites storage item exists, see if the
    // player is in there so we can set active state for the star
    if (storageItemsFavorite) {
      for (let i = 0; i < storageItemsFavorite.length; i++) {
        if (storageItemsFavorite[i] === this.playerId) {
          this.favoriteBtn.setAttribute(this.checkedAttr, 'true')
        }
      }
    }
  }

  setListeners () {
    this.favoriteBtn.addEventListener('click', this.clickHandler)
  }

  buttonClick (e) {
    e.stopPropagation()
    // player page has more than one favorite button, so we are only
    // setting and removing the local storage item once per click
    // but making sure all of the buttons get the active/inactive state
    if (e.currentTarget.getAttribute(this.checkedAttr) === 'true') {
      for (let i = 0; i < this.favoriteBtns.length; i++) {
        this.favoriteBtns[i].setAttribute(this.checkedAttr, 'false')
      }
      removeStorageItem(
        window.localStorage,
        this.favoritesStorageName,
        this.playerId
      )
    } else {
      for (let i = 0; i < this.favoriteBtns.length; i++) {
        this.favoriteBtns[i].setAttribute(this.checkedAttr, 'true')
      }
      setStorageItem(
        window.localStorage,
        this.favoritesStorageName,
        this.playerId
      )
    }
    document.body.dispatchEvent(this.favoriteEvent)
  }

  disconnectedCallback () {
    this.favoriteBtn.removeEventListener('click', this.clickHandler)
  }
}
