/**
 * Useful for either getting the value of a single parameter from URL
 * or getting URLSearchParams object in order to accomplish more
 * complex tasks.
 * @param param - optional parameter to extract from URL
 * @returns {URLSearchParams}
 */
export const GetUrlParams = param => {
  return param
    ? new window.URLSearchParams(window.location.search).get(param)
    : new window.URLSearchParams(window.location.search)
}

/**
 * Useful for setting URL parameters when passed a URLSearchParams object
 * with desired key and value pairs.
 * @param params - URLSearchParams object containing param keys and values
 */
export const SetUrlParams = params => {
  const loc = window.location
  window.history.replaceState(
    {},
    '',
    params.toString() ? `${loc.pathname}?${params}` : loc.pathname
  )
}

/**
 * Useful for resetting URL after modifying with some parameter. Passed in parameters
 * will be removed from URL while remaining parameters will be kept unmodified.
 * @param deleteParams - single string parameter or array of string parameters
 */
export const ResetUrl = deleteParams => {
  const params = GetUrlParams()

  // array of parameters passed in
  if (Array.isArray(deleteParams)) {
    params.forEach(param => params.delete(param))
  } else {
    // single parameter passed in
    params.delete(deleteParams)
  }

  SetUrlParams(params)
}
