import { useRef, useEffect } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
const LeaderboardSearch = props => {
  const {
    handleSearchQuery,
    handleClick,
    suggestions,
    setSuggestionsActive,
    handleKeyDown,
    suggestionsActive,
    searchValue,
    open,
    setOpen
  } = props

  const searchPlaceholder = 'Search by Player'
  const suggestionsRef = useRef()
  const inputRef = useRef()

  function useOnClickOutside (ref1, ref2, handler) {
    useEffect(() => {
      const listener = e => {
        if (
          !ref1.current ||
          ref1.current.contains(e.target) ||
          (!ref2.current || ref2.current.contains(e.target))
        ) {
          return
        }
        handler(e)
      }
      document.addEventListener('mousedown', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
      }
    }, [ref1, ref2, handler])
  }

  useOnClickOutside(suggestionsRef, inputRef, e => {
    setOpen(false)
    setSuggestionsActive(false)
  })

  const Suggestions = () => {
    return (
      <div className="Suggestions" ref={suggestionsRef}>
        <CSSTransition
          in={suggestionsActive}
          timeout={2000}
          classNames="Suggestions-list"
          unmountOnExit
        >
          <ul role="listbox" className="Suggestions-list">
            {suggestions.map((suggestion, i) => {
              return (
                <li className="Suggestions-item" key={i} onClick={handleClick}>
                  {suggestion}
                </li>
              )
            })}
          </ul>
        </CSSTransition>
      </div>
    )
  }

  return (
    <div className="LeaderboardModule-search-form">
      <div>
        <input
          ref={inputRef}
          onChange={handleSearchQuery}
          type="text"
          autoComplete="off"
          aria-expanded={suggestionsActive && open}
          aria-owns="Suggestions-list"
          role="combobox"
          className="LeaderboardModule-search-input"
          name="q"
          value={searchValue}
          placeholder={searchPlaceholder}
          onKeyDown={handleKeyDown}
        />
        <button className="LeaderboardModule-search-button" tabIndex="2">
          <svg className="icon-magnify">
            <use href="#icon-magnify" />
          </svg>
        </button>
      </div>
      <TransitionGroup component={Suggestions}>
        {suggestionsActive && open && <Suggestions />}
      </TransitionGroup>
    </div>
  )
}

export default LeaderboardSearch
