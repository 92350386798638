import { Carousel } from 'carousel'

export class ListLargeCarousel extends Carousel {
  handleOptions () {
    this.optionsChanged = false

    this.carouselOptions = {
      pageDots: true,
      lazyLoad: 1,
      adaptiveHeight: true,
      imagesLoaded: true,
      prevNextButtons: true,
      cellAlign: 'left'
    }
  }
}
