import { Carousel } from 'carousel'
export class ListPeopleCarousel extends Carousel {
  carouselEvents () {
    this.updateBeforeAfter(this.flickity.selectedIndex)
    this.flickity.on('change', index => {
      this.updateBeforeAfter(index)
    })
  }

  updateBeforeAfter (index) {
    // add the sibling data attr once if asibling
    if (index > 0) {
      this.flickity.cells[index - 1].element.setAttribute('data-is-sibling', '')
    }
    // remove the sibling data attr once no longer a sibling
    if (index > 1) {
      this.flickity.cells[index - 2].element.removeAttribute('data-is-sibling')
    }
    // add the sibling data attr once if asibling
    if (index < this.flickity.cells.length - 1) {
      this.flickity.cells[index + 1].element.setAttribute('data-is-sibling', '')
    }
    // remove the sibling data attr once no longer a sibling
    if (index < this.flickity.cells.length - 2) {
      this.flickity.cells[index + 2].element.removeAttribute('data-is-sibling')
    }
  }

  handleOptions () {
    super.handleOptions()
    this.carouselOptions = {
      adaptiveHeight: true,
      cellAlign: 'center',
      wrapAround: false,
      groupCells: false,
      imagesLoaded: true,
      pageDots: false,
      contain: false,
      initialIndex: 0
    }
  }
}
