const LeaderboardLegend = props => {
  const { playerPageUrl } = props
  const ctaText = 'Player Profile'

  return (
    <div className="LeaderboardFlyoutModule-scorecards-legend">
      <div className="ScorecardLegend">
        <div className="ScorecardLegend-item eagle">Eagle+</div>
        <div className="ScorecardLegend-item birdie">Birdie</div>
        <div className="ScorecardLegend-item bogey">Bogey</div>
        <div className="ScorecardLegend-item double">Double+</div>
      </div>
      {playerPageUrl ? (
        <div className="Player-cta">
          <a
            className="Link"
            href={playerPageUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {ctaText}
          </a>
        </div>
      ) : null}
    </div>
  )
}

export default LeaderboardLegend
