export class SearchModule extends window.HTMLElement {
  get favoritedBts () {
    return this.querySelectorAll('.Favorite-button')
  }

  connectedCallback () {
    this.onTrackerLoad = import(
      /* webpackChunkName: 'Tracker' */ 'eventTracker'
    )
      .then(({ Tracker }) => {
        this.listenerTracker = new Tracker()
        this.cacheElements()
        this.favorites = this.hasAttribute('data-player-favorites')
        if (this.favorites) {
          this.handleFavorites()

          document.body.addEventListener(
            'FavoritePlayer:Clicked',
            this.favoriteEventHandler
          )
        }
        this.handleFormSubmit()
        // the page number mark up is generated from another component
        // so we are settign a timeout in order for that to run before
        // iterating over the number links to make them ajaxable
        setTimeout(() => {
          this.handlePageLinks()
        }, 250)
      })
      .catch(e => console.warn('Error loading Tracker', e))
  }

  cacheElements () {
    this.searchForm = this.querySelector('[data-form]')
    this.searchResults = this.querySelector('[data-ajax]')
    this.pageLinkHandler = this.pageLinksSubmit.bind(this)
    this.favoriteEventHandler = () => {
      this.handleFavorites()
    }
  }

  checkListAttributes (listItem) {
    if (!listItem.hasAttribute('data-hide')) {
      listItem.setAttribute('data-hide', 'true')
    } else {
      listItem.removeAttribute('data-hide')
    }
  }

  handleFavorites () {
    for (let i = 0; i < this.favoritedBts.length; i++) {
      const favoriteBtn = this.favoritedBts[i]
      const listItem = favoriteBtn.closest('.ListPlayerGrid-items-item')
      // If the favorite button isn't checked, we want to hide the li
      // containing the player card, so we add the attr
      if (
        favoriteBtn.getAttribute('aria-checked') === 'false' &&
        !listItem.hasAttribute('data-hide')
      ) {
        listItem.setAttribute('data-hide', 'true')
      }

      // If the favorite button is checked, we want to show the li
      // containing the player card, so we remove the attr
      if (
        favoriteBtn.getAttribute('aria-checked') === 'true' &&
        listItem.hasAttribute('data-hide')
      ) {
        listItem.removeAttribute('data-hide')
      }
    }
  }

  // showFavorites () {
  //   for (let i = 0; i < this.favoritedBts.length; i++) {
  //     const favoriteBtn = this.favoritedBts[i]
  //     const listItem = favoriteBtn.closest('.ListPlayerGrid-items-item')
  //     // If the favorite button is checked, we want to show the li
  //     // containing the player card
  //     if (
  //       favoriteBtn.getAttribute('aria-checked') === 'true' &&
  //       !listItem.hasAttribute('data-hide')
  //     ) {
  //       listItem.removeAttribute('data-hide', 'true')
  //     }
  //   }
  // }

  handleFormSubmit () {
    this.listenerTracker.addListener(
      this.searchForm,
      'submit',
      this.formSubmit.bind(this)
    )
  }

  formSubmit (e) {
    e.preventDefault()

    import(/* webpackChunkName: 'Serialize' */ 'serialize')
      .then(({ Serialize }) => {
        const formUrl = Serialize(this.searchForm)
        const ajaxUrl = window.location.pathname + '?' + formUrl
        window.history.replaceState({}, document.title, ajaxUrl)
        this.setLoadingState()

        this.getNewSearch(ajaxUrl).then(response => {
          this.clearSearchForm()
          this.renderSearchResults(response)
          document.body.dispatchEvent(
            new window.CustomEvent('Search:onSearchUpdate', {
              detail: {
                responseMarkup: response
              }
            })
          )
        })
      })
      .catch(e => console.warn('Error loading Serialize', e))
  }

  /* External API to submit the search, which just triggers this event, which kicks
     off the Ajax stuffs */
  submitSearch () {
    let submitEvent

    if (typeof Event === 'function') {
      submitEvent = new window.Event('submit')
    } else {
      submitEvent = document.createEvent('Event')
      submitEvent.initEvent('submit', true, true)
    }

    if (document.body.hasAttribute('data-filters-open')) {
      document.body.removeAttribute('data-filters-open')
    }

    this.searchForm.dispatchEvent(submitEvent)
  }

  getNewSearch (apiUrl) {
    return new Promise((resolve, reject) => {
      window
        .fetch(apiUrl, {
          credentials: 'include'
        })
        .then(response => {
          resolve(response.text())
        })
        .catch(() => {
          reject(new Error('there is an error in the SearchModule.js'))
        })
    })
  }

  clearSearchForm () {
    this.searchResults.innerHTML = ''
    this.handlePageLinks(true)
  }

  setLoadingState () {
    this.searchResultMain = this.querySelector('[data-main]')
    this.searchResultMain.innerHTML = ''
    const loadingIcon = document.createElement('div')
    loadingIcon.classList.add('loading-icon')

    this.searchResultMain.appendChild(loadingIcon)
    this.setAttribute('data-loading', true)
  }

  renderSearchResults (response) {
    // filter HTML response
    const filterDiv = document.createElement('div')
    filterDiv.innerHTML = response
    const searchResultsFromResponse = filterDiv.querySelector('[data-ajax]')
      .innerHTML

    if (searchResultsFromResponse) {
      this.searchResults.innerHTML = searchResultsFromResponse
      if (this.favorites) {
        this.handleFavorites()
      }
      this.handlePageLinks()
    }

    // refresh ads
    window._fw_linktag_refresh && window._fw_linktag_refresh()

    this.dispatchRendered()

    window.scrollTo(0, this.getBoundingClientRect().top)
  }

  dispatchRendered () {
    const lazyLoaded = new window.CustomEvent('Ajax:Rendered', {
      bubbles: true
    })

    document.body.dispatchEvent(lazyLoaded)
  }

  handlePageLinks (removeEventListeners) {
    this.pageLinks = this.querySelectorAll('[data-page-num]')
    if (this.pageLinks !== null) {
      this.pageLinks.forEach(item => {
        if (removeEventListeners) {
          item.removeEventListener('click', this.pageLinkHandler)
        } else {
          item.addEventListener('click', this.pageLinkHandler)
        }
      })
    }
  }

  pageLinksSubmit (e) {
    e.preventDefault()
    e.target.querySelector('[data-page-input]').checked = true
    this.submitSearch()
  }

  disconnectedCallback () {
    this.onTrackerLoad.then(() => this.listenerTracker.removeListeners())

    if (this.favorites) {
      document.body.removeEventListener(
        'FavoritePlayer:Clicked',
        this.favoriteEventHandler
      )
    }
  }
}
