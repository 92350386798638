export class ScrollToAnchor {
  constructor (offset) {
    this.HISTORY_SUPPORT = !!(window.history && window.history.pushState)
    this.anchorScrolls = {
      ANCHOR_REGEX: /^#[^ ]+$/,
      OFFSET_HEIGHT_PX: offset || 20
    }
    setTimeout(() => {
      this.init()
    }, 250)
  }

  /**
   * Establish events, and fix initial scroll position if a hash is provided.
   */
  init () {
    this.scrollToCurrent()
    window.addEventListener('hashchange', event => {
      this.scrollToCurrent()
    })
    document.body.addEventListener('click', event => {
      this.delegateAnchors(event)
    })
  }

  /**
   * Return the offset amount to deduct from the normal scroll position.
   * Modify as appropriate to allow for dynamic calculations
   */
  getFixedOffset () {
    return this.anchorScrolls.OFFSET_HEIGHT_PX
  }

  /**
   * If the provided href is an anchor which resolves to an element on the
   * page, scroll to it.
   * @param  {String} href
   * @return {Boolean} - Was the href an anchor.
   */
  scrollIfAnchor (href, pushToHistory) {
    let rect, anchorOffset

    if (!this.anchorScrolls.ANCHOR_REGEX.test(href)) {
      return false
    }

    const match = document.getElementById(href.slice(1))
    if (match) {
      rect = match.getBoundingClientRect()
      anchorOffset = window.pageYOffset + rect.top - this.getFixedOffset()
      window.scrollTo(window.pageXOffset, anchorOffset)
      // Add the state to history as-per normal anchor links
      if (this.HISTORY_SUPPORT && pushToHistory) {
        window.history.pushState(
          {},
          document.title,
          window.location.pathname + href
        )
      }
    }

    return !!match
  }

  /**
   * Attempt to scroll to the current location's hash.
   */
  scrollToCurrent () {
    this.scrollIfAnchor(window.location.hash)
  }

  /**
   * If the click event's target was an anchor, fix the scroll position.
   */
  delegateAnchors (e) {
    const elem = e.target

    if (
      elem.nodeName === 'A' &&
      this.scrollIfAnchor(elem.getAttribute('href'), true)
    ) {
      e.preventDefault()
    }
  }
}
