import { useEffect, useRef } from 'react'

const LeaderboardFilter = props => {
  const { setDropdown, dropdown, onChangeValue, filter } = props
  const buttonRef = useRef(null)
  const filterRef = useRef(null)
  const filterText = 'filter'

  useEffect(() => {
    if (!dropdown) {
      return
    }
    function checkIfClickedOutside (e) {
      if (
        dropdown &&
        filterRef.current &&
        !filterRef.current.contains(e.target)
      ) {
        setDropdown(false)
        filterRef.current.blur()
      }
    }
    function checkIfTabbedOutside (e) {
      if (dropdown && buttonRef.current && e.key === 'Escape') {
        buttonRef.current.blur()
        setDropdown(false)
      }
    }
    window.addEventListener('mousedown', checkIfClickedOutside)
    window.addEventListener('keydown', checkIfTabbedOutside)
    return () => {
      window.removeEventListener('mousedown', checkIfClickedOutside)
      window.removeEventListener('keydown', checkIfTabbedOutside)
    }
  }, [dropdown, setDropdown])

  return (
    <div className="LeaderboardModule-filter" ref={filterRef}>
      <button
        aria-label="filter players dropdown menu"
        tabIndex={0}
        className="LeaderboardModule-filter-button"
        onClick={() => setDropdown(!dropdown)}
        ref={buttonRef}
      >
        <svg className="filter">
          <use xlinkHref="#filter-2" />
        </svg>
        <span>{filterText}</span>
      </button>
      {dropdown && (
        <div className="LeaderboardModule-filter-dropdown" role="dropdown">
          <ul>
            <li>
              <input
                role="dropdown-item"
                tabIndex={0}
                id="fullLeaderboard"
                type="radio"
                value="fullLeaderboard"
                name="golfers"
                checked={filter === 'fullLeaderboard'}
                onChange={onChangeValue}
              />
              <label htmlFor="fullLeaderboard">Full Leaderboard</label>
            </li>
            <li>
              <input
                role="dropdown-item"
                tabIndex={0}
                id="pastChampion"
                type="radio"
                value="pastChampion"
                name="golfers"
                checked={filter === 'pastChampion'}
                onChange={onChangeValue}
              />
              <label htmlFor="pastChampion">Past PGA Champions</label>
            </li>
            <li>
              <input
                role="dropdown-item"
                tabIndex={0}
                id="teamOf20"
                type="radio"
                value="teamOf20"
                name="golfers"
                checked={filter === 'teamOf20'}
                onChange={onChangeValue}
              />
              <label htmlFor="teamOf20">Team of 20</label>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}

export default LeaderboardFilter
