import { debounce } from 'debounce'

const CLASS_PAGE_HEADER = '.Page-header'
const CLASS_PAGE_HEADER_ABOVE = '.Page-header-above'
const ATTR_SHOW = 'data-show'
const ATTR_BANNER_SHOW = 'data-banner-show'
const BANNER_STATUS = 'bannerStatus'
const NO_SHOW = 'no show'
export class Banner extends window.HTMLElement {
  connectedCallback () {
    this.closeButton = this.querySelector('[data-toggle-trigger]')
    this.header = this.closest(CLASS_PAGE_HEADER)
    this.pageHeaderAbove = this.header.querySelector(CLASS_PAGE_HEADER_ABOVE)

    this.isHomePage = () => {
      return document.documentElement.classList.contains('HomePage')
    }
    this.checkSessionStorage()

    window.addEventListener(
      'load',
      (this._loadHeight = event => {
        this.offsetBodyPadding()
      })
    )

    window.addEventListener(
      'resize',
      (this._resizeHeight = e => {
        debounce(250, () => {
          this.offsetBodyPadding()
        }).call()
      })
    )

    document.body.addEventListener(
      'LeaderboardTicker:Loaded',
      (this._leaderboardLoaded = () => {
        this.offsetBodyPadding()
      })
    )
  }

  offsetBodyPadding () {
    if (this.isHomePage()) {
      document.body.style.paddingTop = `${
        this.closest(CLASS_PAGE_HEADER_ABOVE).offsetHeight
      }px`
    } else if (window.sessionStorage.getItem(BANNER_STATUS) !== NO_SHOW) {
      document.body.style.paddingTop = `${this.header.offsetHeight}px`
    }
  }

  isMenuOpen () {
    if (
      document.documentElement.getAttribute('data-toggle-header') ===
      'hamburger-menu'
    ) {
      return true
    } else {
      return false
    }
  }

  checkSessionStorage () {
    if (window.sessionStorage.getItem(BANNER_STATUS) !== NO_SHOW) {
      this.setAttribute(ATTR_SHOW, '')
      document.body.setAttribute(ATTR_BANNER_SHOW, '')
      this.closeButton.addEventListener('click', () => {
        this.removeAttribute(ATTR_SHOW)
        document.body.removeAttribute(ATTR_BANNER_SHOW)
        // reset the padding when the banner is removed
        this.offsetBodyPadding()

        window.sessionStorage.setItem(BANNER_STATUS, NO_SHOW)
      })
    }
  }

  disconnectedCallback () {
    window.removeEventListener('load', this._loadHeight)
    window.removeEventListener('resize', this._resizeHeight)
    document.body.removeEventListener('resize', this._leaderboardLoaded)
  }
}
