import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import GetImage from '../util/GetImage'

const PlayoffRow = props => {
  const { row, golfer } = props

  const winnerLabel = 'Winner'

  return (
    <tr
      className="PlayoffRow"
      data-playerid={row.golferId || null}
      data-leaderboard-winner={row.playoffWinner ? true : null}
    >
      <td className="PlayoffRow-data">
        <div className="PlayoffRow-player">
          <div className="PlayoffRow-image">
            {/* {imageSize='playerProfileSm'} */}
            {golfer[0] && golfer[0].image && (
              <LazyLoadImage
                alt={`headshot of ${golfer[0].firstName || ''} ${golfer[0]
                  .lastName || ''}`}
                height="70"
                src={GetImage.src(golfer[0].image)}
                width="70"
              />
            )}
          </div>
          <div className="PlayoffRow-fullName">
            {golfer[0] && golfer[0].firstName && golfer[0].lastName && (
              <React.Fragment>
                <span className="PlayoffRow-firstName">
                  {golfer[0].firstName}
                </span>
                <span
                  className="PlayoffRow-lastName"
                  data-initial={
                    golfer[0].firstName && golfer[0].firstName.split('')[0]
                  }
                >
                  {golfer[0].lastName}
                </span>
              </React.Fragment>
            )}
          </div>
          {row && row.playoffWinner && (
            <div className="PlayoffRow-winnerLabel">{winnerLabel}</div>
          )}
        </div>
      </td>
      {row &&
        row.playoffHoles &&
        row.playoffHoles.map((hole, i) => (
          <td className="PlayoffRow-data" key={i}>
            <span className="PlayoffRow-score">{hole.strokes}</span>
          </td>
        ))}
    </tr>
  )
}

export default PlayoffRow
