export class Carousel extends window.HTMLElement {
  connectedCallback () {
    this.carouselSlides = this.querySelector('[class$="-slides"]') || this

    // waiting until window load to trigger carousels. They still look good before
    // window load, and we have run into race condition and image issues sometimes
    // when we kick it off too fast

    this.onFlickityLoad = import(/* webpackChunkName: 'Flickity' */ 'flickity')
      .then(({ default: Flickity }) => {
        window.setTimeout(() => {
          this.handleOptions()
          this.flickity = new Flickity(
            this.carouselSlides,
            this.carouselOptions
          )
          this.carouselEvents()
        }, 250)
      })
      .catch(e => console.warn('Error loading Flickity', e))
  }

  getMediaQuery () {
    const mqValue =
      window
        .getComputedStyle(document.querySelector('body'), '::before')
        .getPropertyValue('content') || false

    if (mqValue) {
      return mqValue.replace(/["']/g, '')
    } else {
      return false
    }
  }

  // we set or reset options here based on the current media query
  // if a conditional has mq specific options, we check to see if the mq
  // changed, and if it did, we set a global changed var so that we
  // can rebuild the carousel

  handleOptions () {
    this.optionsChanged = false

    this.carouselOptions = {
      pageDots: false,
      lazyLoad: 1,
      adaptiveHeight: true,
      imagesLoaded: true
    }
  }

  carouselEvents () {}

  disconnectedCallback () {
    this.onFlickityLoad.then(({ default: Flickity }) => {
      this.flickity.destroy()
    })
  }
}
