/* eslint-disable dot-notation */
import React, { useEffect, useState, useRef } from 'react'
import ScorecardTableRow from './ScorecardTableRowReact'
import MediaQueries from 'mediaQueries'
import Flickity from 'react-flickity-component'
import { debounce } from 'debounce'

const ScoreCardRound = props => {
  const mq = new MediaQueries().mq
  const flkty = useRef(null)

  const {
    holeSelect,
    hole10Start,
    round,
    handleModalOpen,
    disableScorecardMedia,
    setHoleSelect,
    openedFromModal,
    scorecard,
    teeData,
    roundGolferRoundScore,
    disableShotlink
  } = props

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth
  })

  useEffect(() => {
    const debouncedHandleResize = debounce(
      150,
      function handleResize () {
        setScreenSize({ width: window.innerWidth })
      },
      true
    )
    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [screenSize])

  const scoresAndVideos = []
  const parseScoresAndVideos = () => {
    for (let i = 1; i <= 18; i++) {
      if (scorecard) {
        if (Object.prototype.hasOwnProperty.call(scorecard, 'hole' + i)) {
          const obj = {}
          obj['hasVideo'] = scorecard['hole' + i]
            ? scorecard['hole' + i].hasVideo
            : false
          obj['score'] = scorecard['hole' + i]
            ? scorecard['hole' + i].strokes
            : null
          scoresAndVideos.push(obj)
        }
      }
    }
  }
  parseScoresAndVideos()

  const pars = [
    teeData && teeData.hole1 ? teeData.hole1.par : null,
    teeData && teeData.hole2 ? teeData.hole2.par : null,
    teeData && teeData.hole3 ? teeData.hole3.par : null,
    teeData && teeData.hole4 ? teeData.hole4.par : null,
    teeData && teeData.hole5 ? teeData.hole5.par : null,
    teeData && teeData.hole6 ? teeData.hole6.par : null,
    teeData && teeData.hole7 ? teeData.hole7.par : null,
    teeData && teeData.hole8 ? teeData.hole8.par : null,
    teeData && teeData.hole9 ? teeData.hole9.par : null,
    teeData && teeData.hole10 ? teeData.hole10.par : null,
    teeData && teeData.hole11 ? teeData.hole11.par : null,
    teeData && teeData.hole12 ? teeData.hole12.par : null,
    teeData && teeData.hole13 ? teeData.hole13.par : null,
    teeData && teeData.hole14 ? teeData.hole14.par : null,
    teeData && teeData.hole15 ? teeData.hole15.par : null,
    teeData && teeData.hole16 ? teeData.hole16.par : null,
    teeData && teeData.hole17 ? teeData.hole17.par : null,
    teeData && teeData.hole18 ? teeData.hole18.par : null
  ]

  const inPar =
    pars[9] +
    pars[10] +
    pars[11] +
    pars[12] +
    pars[13] +
    pars[14] +
    pars[15] +
    pars[16] +
    pars[17]
  const outPar =
    pars[0] +
    pars[1] +
    pars[2] +
    pars[3] +
    pars[4] +
    pars[5] +
    pars[6] +
    pars[7] +
    pars[8]

  const yardage = [
    teeData && teeData.hole1 && teeData.hole1.yardage,
    teeData && teeData.hole2 && teeData.hole2.yardage,
    teeData && teeData.hole3 && teeData.hole3.yardage,
    teeData && teeData.hole4 && teeData.hole4.yardage,
    teeData && teeData.hole5 && teeData.hole5.yardage,
    teeData && teeData.hole6 && teeData.hole6.yardage,
    teeData && teeData.hole7 && teeData.hole7.yardage,
    teeData && teeData.hole8 && teeData.hole8.yardage,
    teeData && teeData.hole9 && teeData.hole9.yardage,
    teeData && teeData.hole10 && teeData.hole10.yardage,
    teeData && teeData.hole11 && teeData.hole11.yardage,
    teeData && teeData.hole12 && teeData.hole12.yardage,
    teeData && teeData.hole13 && teeData.hole13.yardage,
    teeData && teeData.hole14 && teeData.hole14.yardage,
    teeData && teeData.hole15 && teeData.hole15.yardage,
    teeData && teeData.hole16 && teeData.hole16.yardage,
    teeData && teeData.hole17 && teeData.hole17.yardage,
    teeData && teeData.hole18 && teeData.hole18.yardage
  ]

  const inYards =
    yardage[0] +
    yardage[1] +
    yardage[2] +
    yardage[3] +
    yardage[4] +
    yardage[5] +
    yardage[6] +
    yardage[7] +
    yardage[8]
  const outYards =
    yardage[9] +
    yardage[10] +
    yardage[11] +
    yardage[12] +
    yardage[13] +
    yardage[14] +
    yardage[15] +
    yardage[16] +
    yardage[17]

  const totalPar = outPar + inPar
  const totalYards = outYards + inYards

  const hole = 'Hole'
  const par = 'Par'
  const score = 'Score'
  const totalAbv = 'Tot'
  const inAbv = 'In'
  const outAbv = 'Out'
  const yardsAbv = 'Yds'
  const startSlide = hole10Start ? 1 : 0

  return (
    <div data-toggle="scorecard" className="ScorecardRound-table">
      <div className="ScorecardRound-tableBody">
        <div className="ScorecardRound-tableHead">
          <div className="ScorecardRound-tableHead-label">
            <span className="ScorecardRound-tableHead-label-text">{hole}</span>
          </div>
          <div className="ScorecardRound-tableHead-label">
            <span className="ScorecardRound-tableHead-label-text">{par}</span>
          </div>
          <div className="ScorecardRound-tableHead-label yards">
            <span className="ScorecardRound-tableHead-label-text">
              {yardsAbv}
            </span>
          </div>
          <div className="ScorecardRound-tableHead-label score">
            <span className="ScorecardRound-tableHead-label-text">{score}</span>
          </div>
        </div>
        {!mq['mq-viewport-md'].matches ? (
          <Flickity
            className={
              'ScorecardRound-tablesScores ScorecardRound-slides flickity-enabled'
            }
            elementType={'div'}
            flickityRef={carouselRef => {
              flkty.current = carouselRef
            }}
            options={{
              adaptiveHeight: true,
              cellAlign: 'left',
              wrapAround: false,
              groupCells: 10,
              imagesLoaded: true,
              pageDots: true,
              prevNextButtons: false,
              contain: true, // false is creating a gap on right side
              initialIndex: startSlide,
              reloadOnUpdate: true
            }}
          >
            {scoresAndVideos &&
              scoresAndVideos.map((item, i) =>
                i === 8 ? (
                  <React.Fragment key={i}>
                    <ScorecardTableRow
                      holeSelect={holeSelect}
                      setHoleSelect={setHoleSelect}
                      score={item.score}
                      par={pars[i]}
                      key={i}
                      index={i}
                      hole={i + 1}
                      yardage={yardage[i]}
                      disableScorecardMedia={disableScorecardMedia}
                      handleModalOpen={handleModalOpen}
                      openedFromModal={openedFromModal}
                      disableShotlink={disableShotlink}
                    />
                    <div className="ScorecardRound-tableColumn-out ScorecardRound-slide">
                      <div className="ScorecardRound-tableColumn-data">
                        {outAbv || '-'}
                      </div>
                      <div className="ScorecardRound-tableColumn-data">
                        {outPar || '-'}
                      </div>
                      <div className="ScorecardRound-tableColumn-data ScorecardRound-tableColumn-yards">
                        {outYards || '-'}
                      </div>
                      <div className="ScorecardRound-tableColumn-data">
                        {roundGolferRoundScore && roundGolferRoundScore.roundOut
                          ? roundGolferRoundScore.roundOut
                          : '-'}
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <ScorecardTableRow
                    holeSelect={holeSelect}
                    setHoleSelect={setHoleSelect}
                    score={item.score}
                    par={pars[i]}
                    key={i}
                    index={i}
                    hole={i + 1}
                    yardage={yardage[i]}
                    disableScorecardMedia={disableScorecardMedia}
                    hasVideoForHole={item.hasVideo}
                    handleModalOpen={handleModalOpen}
                    openedFromModal={openedFromModal}
                    disableShotlink={disableShotlink}
                  />
                )
              )}
            <div className="ScorecardRound-tableColumn-in">
              <div className="ScorecardRound-tableColumn-data">{inAbv}</div>
              <div className="ScorecardRound-tableColumn-data">
                {inPar || '-'}
              </div>
              <div className="ScorecardRound-tableColumn-data ScorecardRound-tableColumn-yards">
                {inYards || '-'}
              </div>
              <div className="ScorecardRound-tableColumn-data">
                {roundGolferRoundScore && roundGolferRoundScore.roundIn
                  ? roundGolferRoundScore.roundIn
                  : '-'}
              </div>
            </div>
          </Flickity>
        ) : (
          <div className="ScorecardRound-tablesScores ScorecardRound-slides">
            {scoresAndVideos &&
              scoresAndVideos.map((item, i) =>
                i === 8 ? (
                  <React.Fragment key={i}>
                    <ScorecardTableRow
                      holeSelect={holeSelect}
                      setHoleSelect={setHoleSelect}
                      score={item.score}
                      par={pars[i]}
                      key={i}
                      index={i}
                      hole={i + 1}
                      yardage={yardage[i]}
                      round={round}
                      disableScorecardMedia={disableScorecardMedia}
                      handleModalOpen={handleModalOpen}
                      openedFromModal={openedFromModal}
                      disableShotlink={disableShotlink}
                    />
                    <div className="ScorecardRound-tableColumn-out ScorecardRound-slide">
                      <div className="ScorecardRound-tableColumn-data">
                        {outAbv}
                      </div>
                      <div className="ScorecardRound-tableColumn-data">
                        {outPar || '-'}
                      </div>
                      <div className="ScorecardRound-tableColumn-data ScorecardRound-tableColumn-yards">
                        {outYards || '-'}
                      </div>
                      <div className="ScorecardRound-tableColumn-data">
                        {roundGolferRoundScore && roundGolferRoundScore.roundOut
                          ? roundGolferRoundScore.roundOut
                          : '-'}
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <ScorecardTableRow
                    holeSelect={holeSelect}
                    setHoleSelect={setHoleSelect}
                    score={item.score}
                    par={pars[i]}
                    key={i}
                    index={i}
                    hole={i + 1}
                    yardage={yardage[i]}
                    disableScorecardMedia={disableScorecardMedia}
                    hasVideoForHole={item.hasVideo}
                    handleModalOpen={handleModalOpen}
                    openedFromModal={openedFromModal}
                    disableShotlink={disableShotlink}
                  />
                )
              )}
            <div className="ScorecardRound-tableColumn-in">
              <div className="ScorecardRound-tableColumn-data">{inAbv}</div>
              <div className="ScorecardRound-tableColumn-data">
                {inPar || '-'}
              </div>
              <div className="ScorecardRound-tableColumn-data ScorecardRound-tableColumn-yards">
                {inYards}
              </div>
              <div className="ScorecardRound-tableColumn-data">
                {roundGolferRoundScore && roundGolferRoundScore.roundIn
                  ? roundGolferRoundScore.roundIn
                  : '-'}
              </div>
            </div>
          </div>
        )}
        <div className="ScorecardRound-tableColumn-total">
          <div className="ScorecardRound-tableColumn-data">{totalAbv}</div>
          <div className="ScorecardRound-tableColumn-data">
            {totalPar || '-'}
          </div>
          <div className="ScorecardRound-tableColumn-data ScorecardRound-tableColumn-yards">
            {totalYards || '-'}
          </div>
          <div className="ScorecardRound-tableColumn-data">
            {roundGolferRoundScore && roundGolferRoundScore.roundTotal
              ? roundGolferRoundScore.roundTotal
              : '-'}
          </div>
        </div>
      </div>
    </div>
  )
}
export default ScoreCardRound
