const ScorecardTableRow = props => {
  const {
    score,
    par,
    hole,
    index,
    yardage,
    holeSelect,
    setHoleSelect,
    hasVideoForHole,
    disableScorecardMedia,
    handleModalOpen,
    openedFromModal,
    disableShotlink
  } = props

  function updateScoreSymbol (score, par) {
    if (!score) {
      return ''
    }

    const diff = score - par
    if (diff <= -2) {
      return 'eagle'
    } else if (diff === -1) {
      return 'birdie'
    } else if (diff === 1) {
      return 'bogey'
    } else if (diff >= 2) {
      return 'double'
    } else {
      return ''
    }
  }

  const symbol = updateScoreSymbol(score, par)
  const shotlinkDiv = document.querySelector('.ShotlinkHole-maps')

  return (
    <div
      className="ScorecardTableRow ScorecardRound-slide"
      data-back-9={index > 9 || false}
      data-hole-row={hole}
      onClick={() => {
        if (!openedFromModal) {
          setHoleSelect(hole)
          if (document.body.getAttribute('data-toggle-in') === 'maps') {
            document.body.removeAttribute('data-toggle-in', 'maps')
            shotlinkDiv.removeAttribute('data-toggle-in', 'maps')
          }
        }
      }}
      data-shotlink-selected={
        hole === holeSelect && !disableShotlink ? true : null
      }
    >
      <div className="ScorecardTableRow-cell ScorecardTableRow-hole">
        {hole || '-'}
      </div>
      <div className="ScorecardTableRow-cell ScorecardTableRow-par">
        {par || '-'}
      </div>
      <div className="ScorecardTableRow-cell ScorecardTableRow-yards">
        {yardage || '-'}
      </div>
      <div
        className={`ScorecardTableRow-cell ScorecardTableRow-score ${symbol}`}
      >
        {score || '-'}
        {!disableScorecardMedia &&
          (hasVideoForHole && (
            <div className="ScorecardTableRow-video">
              <a
                href="#"
                onClick={e => {
                  e.preventDefault()
                  setHoleSelect(hole)
                  handleModalOpen({
                    hole: hole,
                    highlightVideo: false,
                    bestShotsVideo: false,
                    allVideo: true,
                    videoIndex: null
                  })
                }}
              >
                <svg className="play-video">
                  <use href="#video-camera" />
                </svg>
              </a>
            </div>
          ))}
      </div>
    </div>
  )
}
export default ScorecardTableRow
