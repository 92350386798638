// Updates the target frame with data fetched from the targeted links
export class PsFrameData extends window.HTMLElement {
  connectedCallback () {
    this.cacheElements()
    this.setHandlers()
  }

  cacheElements () {
    this.frameTargetAttr = this.getAttribute('data-frame-target')
    this.documentTarget = document.querySelector(
      `[data-frame-target="${this.frameTargetAttr}"]`
    )
    this.selectEl = this.querySelector('[data-round-select]')
    this.changeHandler = this.selectChange.bind(this)
    this.timeHandler = this.timeToggle.bind(this)
    this.timeToggler = this.querySelector(
      '.LeaderboardModule-toggleSwitch-input'
    )
    this.myTimeStorageName = 'pgaMyTime'
    this.myTimeAttrName = 'data-my-time'
  }

  setHandlers () {
    if (localStorage.getItem(this.myTimeStorageName) === 'true') {
      this.timeToggler.checked = true
      this.setAttribute(this.myTimeAttrName, true)
    }
    this.selectEl.addEventListener('change', this.changeHandler)
    this.timeToggler.addEventListener('change', this.timeHandler)
  }

  selectChange (e) {
    const ajaxUrl = e.currentTarget.value
    this.requestData(ajaxUrl).then(response => {
      this.renderFrameResults(response)
    })
  }

  timeToggle (e) {
    const myTime = e.currentTarget.checked
    this.setAttribute(this.myTimeAttrName, myTime)
    localStorage.setItem(this.myTimeStorageName, myTime)
  }

  requestData (apiUrl) {
    const FETCH_TIMEOUT = 5000
    return new Promise((resolve, reject) => {
      // Set timeout timer
      const timer = setTimeout(
        () => reject(new Error('Request timed out')),
        FETCH_TIMEOUT
      )
      window
        .fetch(apiUrl, {
          credentials: 'include'
        })
        .then(
          response => {
            if (response.status !== 200) {
              // make the promise be rejected if we didn't get a 200 response
              // triggers an error for a 404 or other errors
              throw new Error('Not 200 response')
            } else {
              resolve(response.text())
            }
          },
          err => reject(err)
        )
        .catch(err => {
          reject(err)
        })
        .finally(() => clearTimeout(timer))
    })
  }

  renderFrameResults (response) {
    const responseDiv = document.createElement('div')
    responseDiv.innerHTML = response
    let resultsResponse = responseDiv.outerHTML
    const responseTarget = responseDiv.querySelector(
      `[data-frame-target="${this.frameTargetAttr}"]`
    )
    if (responseTarget) {
      resultsResponse = responseTarget.outerHTML
    }

    if (resultsResponse) {
      this.documentTarget.outerHTML = resultsResponse
    }

    this.dispatchRendered()
  }

  dispatchRendered () {
    const customEvent = new window.CustomEvent('Ajax:Rendered', {
      bubbles: true
    })

    document.body.dispatchEvent(customEvent)
  }

  disconnectedCallback () {
    this.selectEl.removeEventListener('change', this.changeHandler)
  }
}
