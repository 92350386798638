/* global DOMParser */
export class PsModal extends window.HTMLElement {
  connectedCallback () {
    this.modalTemplate = document.querySelectorAll(
      'template[data-psmodal-template]'
    )[0]

    this.addEventListener(
      'click',
      (this._handleModal = event => {
        event.preventDefault()
        this.handleModal(event)
      })
    )
  }

  handleModal (event) {
    this.sourceURL = event.currentTarget.querySelector('.Link').href
    this.srcSelector = event.currentTarget.getAttribute('data-psmodal-selector')

    if (this.sourceURL !== undefined) {
      this.showModal(this.modalTemplate)
      this.requestContent(this.sourceURL).then(
        response => {
          const modalContent = document.createElement('div')
          modalContent.innerHTML = response

          if (this.srcSelector) {
            const parser = new DOMParser()
            const doc = parser.parseFromString(response, 'text/html')
            modalContent.innerHTML = doc.querySelector(
              `.${this.srcSelector}`
            ).outerHTML
          }
          this.swapOutContent(modalContent)
        },
        failure => {
          this.closeModal()
        }
      )
      return
    }

    if (this.modalTemplate !== undefined) {
      this.showModal(this.modalTemplate)
      this.swapOutContent(event.currentTarget.children)
    }
  }

  showModal (templateContent) {
    const clonedContent = templateContent.content.cloneNode(true)
    document.body.appendChild(clonedContent)

    // Remove the modal if the user click outside of the modal
    document
      .querySelectorAll('.Page-modal, .Page-modal-close')
      .forEach(item => {
        item.addEventListener(
          'click',
          (this._closeModal = event => {
            this.controller.abort()
            this.closeModal()
          })
        )
      })

    // stop the propogation of eventbubbling if user clicks on the wrapper,
    // prevents the user from clicking on content below and closing the modal
    document.querySelector('.Page-modal-wrapper').addEventListener(
      'click',
      (this._preventBubbling = event => {
        event.stopPropagation()
      })
    )
  }

  closeModal () {
    const modal = document.querySelector('.Page-modal')
    modal.removeEventListener('click', this._closeModal, true)
    document
      .querySelector('.Page-modal-wrapper')
      .removeEventListener('click', this._preventBubbling, true)
    modal.remove()
  }

  swapOutContent (content) {
    const modal = document.querySelector('.Page-modal')
    const contentArea = document.querySelector('[data-psmodal-target]')
    contentArea.innerHTML = ''

    if (content !== undefined) {
      contentArea.appendChild(content.cloneNode(true))
      setTimeout(() => {
        modal.removeAttribute('data-psmodal-notloaded')
      }, 250)
    }
  }

  requestContent (link) {
    this.controller = new window.AbortController()
    const signal = this.controller.signal

    const FETCH_TIMEOUT = 5000
    return new Promise((resolve, reject) => {
      // Set timeout timer
      const timer = setTimeout(
        () => reject(new Error('Request timed out')),
        FETCH_TIMEOUT
      )

      window
        .fetch(link, {
          headers: { 'Content-Type': 'text/html' },
          signal
        })
        .then(
          response => {
            if (response.status !== 200) {
              // make the promise be rejected if we didn't get a 200 response
              // triggers an error for a 404 or other errors
              throw new Error('Not 200 response')
            } else {
              resolve(response.text())
            }
          },
          err => reject(err)
        )
        .catch(err => {
          reject(err)
        })
        .finally(() => clearTimeout(timer))
    })
  }

  disconnectedCallback () {
    this.removeEventListener('click', this._handleModal)
  }
}
