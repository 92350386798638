/* eslint-disable no-new */
export class SearchFilters extends window.HTMLElement {
  connectedCallback () {
    this.onTrackerLoad = import(
      /* webpackChunkName: 'Tracker' */ 'eventTracker'
    )
      .then(({ Tracker }) => {
        this.listenerTracker = new Tracker()
        this.cacheElements()
        this.handleFiltersOverlay()
        this.handleFiltersApply()
        this.handleFiltersSelect()
      })
      .catch(e => console.warn('Error loading Tracker', e))
  }

  cacheElements () {
    this.searchModule = this.closest('[data-search-results]')
    this.searchSorts = this.querySelectorAll('.SearchSorterInput input')
    this.filtersOpenButton = this.querySelector('[data-filters-open]')
    this.filtersOverlay = this.querySelector('[data-filters-overlay]')
    this.filtersApply = this.querySelector('[data-apply-btn]')
    this.filters = this.querySelectorAll('.SearchFilter-select')
  }

  // If we are on a bigger media query, autosubmit the form when we change filters
  handleFiltersSelect () {
    this.filters.forEach(item => {
      this.listenerTracker.addListener(
        item,
        'change',
        this.filterSelectSubmit.bind(this)
      )
    })
  }

  filterSelectSubmit (e) {
    if (
      this.getMediaQuery() === 'mq-xl' ||
      this.getMediaQuery() === 'mq-md' ||
      this.getMediaQuery() === 'mq-lg' ||
      this.getMediaQuery() === 'mq-hk'
    ) {
      // Using API of search results module
      this.searchModule.submitSearch()
    }
  }

  // Submit the form on apply button, AJAX is handled by the form itself
  handleFiltersApply () {
    this.listenerTracker.addListener(
      this.filtersApply,
      'click',
      this.filterApply.bind(this)
    )
  }

  filterApply (e) {
    e.preventDefault()
    // Using API of search results module
    this.searchModule.submitSearch()
  }

  // open and close the filters overlay using our own API
  handleFiltersOverlay () {
    this.listenerTracker.addListener(
      this.filtersOpenButton,
      'click',
      this.filtersOverlayEvent.bind(this)
    )
  }

  filtersOverlayEvent (e) {
    e.preventDefault()

    if (this.filtersOverlay.hasAttribute('data-filters-open')) {
      this.closeOverlay()
      return
    }

    this.openOverlay()
  }

  openOverlay () {
    this.filtersOverlay.setAttribute('data-filters-open', true)
    this.searchModule.setAttribute('data-filters-open', true)
  }

  closeOverlay () {
    this.filtersOverlay.removeAttribute('data-filters-open')
    this.searchModule.removeAttribute('data-filters-open')
  }

  // TODO: Move this to its own utility file/function
  getMediaQuery () {
    const mqValue =
      window
        .getComputedStyle(document.querySelector('body'), '::before')
        .getPropertyValue('content') || false

    if (mqValue) {
      return mqValue.replace(/["']/g, '')
    } else {
      return false
    }
  }

  disconnectedCallback () {
    this.onTrackerLoad.then(() => this.listenerTracker.removeListeners())
  }
}
