import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist'
import localForage from 'localforage'
import { sha256 } from 'crypto-hash'
import LeaderboardModuleEntry from './LeaderboardModuleEntryReact'

export class LeaderboardReact extends HTMLElement {
  connectedCallback () {
    const apiEndpoint = this.getAttribute('data-endpoint-url')
    const eventId = this.getAttribute('data-event-id')
    const refreshInterval = this.getAttribute('data-refresh-interval')
    const loadingText = this.getAttribute('data-loading-text')
    const loadingImage = this.getAttribute('data-loading-image')

    const cache = new InMemoryCache()

    persistCache({
      cache,
      storage: new LocalStorageWrapper(localForage),
      maxSize: false,
      trigger: 'write',
      debug: true
    }).then(() => {
      const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          )
        }
        if (networkError) {
          console.error(`[Network error messsage]: ${networkError}`)
        }
      })
      const link = ApolloLink.from([
        createPersistedQueryLink({ sha256, useGETForHashedQueries: true }),
        errorLink,
        new HttpLink({
          uri: `${apiEndpoint}`,
          credentials: 'omit',
          withCredentials: true,
          headers: {
            'Accept-Encoding': 'gzip'
          }
        })
      ])

      const client = new ApolloClient({
        link: link,
        cache,
        connectToDevTools: true
      })

      Promise.all([import('react-dom')])
        .then(([ReactDOM]) => {
          ReactDOM.render(
            <ApolloProvider client={client}>
              <LeaderboardModuleEntry
                eventId={eventId}
                refreshInterval={refreshInterval}
                loadingImage={loadingImage}
                loadingText={loadingText}
              />
            </ApolloProvider>,
            this
          )
        })
        .catch(e =>
          console.warn('Error getting the Leaderboard react page to load', e)
        )
    })
  }
}
