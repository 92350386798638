import React from 'react'
import ReactDOM from 'react-dom'

const ModalHighlightVideo = props => {
  const { handleVideoModalClose, featuredVideo } = props

  return ReactDOM.createPortal(
    <div className="Page-modal" onClick={handleVideoModalClose}>
      <div
        className="Page-modal-wrapper"
        data-modal-highlight
        onClick={handleVideoModalClose}
        onKeyDown={handleVideoModalClose}
      >
        <div
          className="Page-modal-content"
          data-highlight
          onClick={e => e.stopPropagation()}
        >
          <button
            className="Page-modal-close"
            aria-label="close"
            onClick={handleVideoModalClose}
          >
            <svg className="close-x">
              <use href="#close-x" />
            </svg>
          </button>
          <div className="ModalHighlights">
            <div className="ModalHighlights-middle">
              {featuredVideo && (
                <React.Fragment>
                  <div className="ModalHighlights-title">
                    {featuredVideo.title}
                  </div>
                  <div className="ModalHighlights-player">
                    <div className="MPXVideoPlayer">
                      <div
                        className="MPXVideoPlayer-screen"
                        data-aspect-ratio="16x9"
                      >
                        <div className="MPXVideoPlayer-iframe-container">
                          <iframe
                            src={featuredVideo.videoUri}
                            style={{ border: '0' }}
                            allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
                            allowFullScreen
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  )
}

export default ModalHighlightVideo
