/* eslint-disable dot-notation */
import React, { useState } from 'react'
import GetImage from './util/GetImage'
import ModalHighlightBasic from './ModalHighlightBasicReact'

const TickerPlayer = props => {
  const [modalOpen, setModalOpen] = useState(false)

  const {
    row,
    eventId,
    currentRound,
    favorites,
    currentRoundTees,
    golfer,
    multiCourse
  } = props

  const golferStaticInfo = golfer && golfer[0] ? golfer[0] : null
  const belowPar = row.overallParInt < 0

  const hole10Start =
    currentRound === 1 && row && row.round1GolferRoundTeeTime
      ? row.round1GolferRoundTeeTime.hole10Start
      : currentRound === 2 && row && row.round2GolferRoundTeeTime
        ? row.round2GolferRoundTeeTime.hole10Start
        : currentRound === 3 && row && row.round3GolferRoundTeeTime
          ? row.round3GolferRoundTeeTime.hole10Start
          : currentRound === 4 && row && row.round4GolferRoundTeeTime
            ? row.round4GolferRoundTeeTime.hole10Start
            : false

  const teeTime =
    currentRound === 1 && row && row.round1GolferRoundTeeTime
      ? row.round1GolferRoundTeeTime.teeTimeLocal
      : currentRound === 2 && row && row.round2GolferRoundTeeTime
        ? row.round2GolferRoundTeeTime.teeTimeLocal
        : currentRound === 3 && row && row.round3GolferRoundTeeTime
          ? row.round3GolferRoundTeeTime.teeTimeLocal
          : currentRound === 4 && row && row.round4GolferRoundTeeTime
            ? row.round4GolferRoundTeeTime.teeTimeLocal
            : false

  const thru =
    currentRound === 1 &&
    row.round1GolferRoundScore &&
    row.round1GolferRoundScore.thru !== '-'
      ? row.round1GolferRoundScore.thru
      : currentRound === 2 &&
        row.round2GolferRoundScore &&
        row.round2GolferRoundScore.thru !== '-'
        ? row.round2GolferRoundScore.thru
        : currentRound === 3 &&
        row.round3GolferRoundScore &&
        row.round3GolferRoundScore.thru !== '-'
          ? row.round3GolferRoundScore.thru
          : currentRound === 4 &&
        row.round4GolferRoundScore &&
        row.round4GolferRoundScore.thru !== '-'
            ? row.round4GolferRoundScore.thru
            : null

  const highlightReel =
    currentRound === 1 &&
    row.round1GolferRoundScore &&
    row.round1GolferRoundScore.highlightVideo
      ? row.round1GolferRoundScore.highlightVideo
      : currentRound === 2 &&
        row.round2GolferRoundScore &&
        row.round2GolferRoundScore.highlightVideo
        ? row.round2GolferRoundScore.highlightVideo
        : currentRound === 3 &&
        row.round3GolferRoundScore &&
        row.round3GolferRoundScore.highlightVideo
          ? row.round3GolferRoundScore.highlightVideo
          : currentRound === 4 &&
        row.round4GolferRoundScore &&
        row.round4GolferRoundScore.highlightVideo
            ? row.round4GolferRoundScore.highlightVideo
            : null

  let golferCourseId = null
  if (currentRound === 1 && row.round1GolferRoundTeeTime) {
    golferCourseId = row.round1GolferRoundTeeTime.courseId
  }
  if (currentRound === 2 && row.round2GolferRoundTeeTime) {
    golferCourseId = row.round2GolferRoundTeeTime.courseId
  }

  if (currentRound === 3 && row.round3GolferRoundTeeTime) {
    golferCourseId = row.round3GolferRoundTeeTime.courseId
  }
  if (currentRound === 4 && row.round4GolferRoundTeeTime) {
    golferCourseId = row.round4GolferRoundTeeTime.courseId
  }

  let golferCourseTee = null

  if (
    !golferCourseId &&
    !multiCourse &&
    currentRoundTees &&
    currentRoundTees.roundTees &&
    currentRoundTees.roundTees[0]
  ) {
    golferCourseTee = currentRoundTees.roundTees[0]
  } else if (
    golferCourseId &&
    multiCourse &&
    currentRoundTees &&
    currentRoundTees.roundTees &&
    currentRoundTees.roundTees.length > 1
  ) {
    golferCourseTee = currentRoundTees.roundTees.filter(
      roundTee => roundTee.courseId === golferCourseId
    )
  } else {
    golferCourseTee = null
  }

  const handleModalOpen = () => {
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  return (
    <React.Fragment>
      <div
        className="TickerPlayer"
        data-player-id={row && row.golferId ? row.golferId : null}
        data-event-id={eventId || false}
        data-player
        data-favorites-state={
          row && row.golferId && favorites.includes(row.golferId)
        }
        data-index={row && row.positionInt ? row.positionInt : null}
      >
        {row && row.golferId && (
          <div className="TickerPlayer-main">
            {row.position && (
              <div className="TickerPlayer-position">{row.position}</div>
            )}
            {golferStaticInfo &&
            golferStaticInfo.image &&
            highlightReel &&
            highlightReel.videoUri ? (
              <div
                className="TickerPlayer-image"
                data-highlight={highlightReel || null}
                onClick={handleModalOpen}
              >
                <a href="#">
                  <img
                    src={GetImage.src(golferStaticInfo.image)}
                    alt={`headshot of ${row.golferFirstName ||
                      ''} ${row.golferLastName || ''}`}
                  />
                </a>
              </div>
                ) : golferStaticInfo && golferStaticInfo.image && !highlightReel ? (
              <div className="TickerPlayer-image">
                <a
                  href={golferStaticInfo && golferStaticInfo.playerPageUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={GetImage.src(golferStaticInfo.image)}
                    alt={`headshot of ${row.golferFirstName ||
                      ''} ${row.golferLastName || ''}`}
                  />
                </a>
              </div>
                ) : null}
            {row && row.golferFirstName && row.golferLastName && (
              <div className="TickerPlayer-info">
                <React.Fragment>
                  <div className="TickerPlayer-name">
                    {/* MAX 10 CHAR */}
                    {`${row.golferFirstName.split('')[0]}. ${
                      row.golferLastName
                    }`}
                  </div>
                  <div className="TickerPlayer-data">
                    {row.overallPar && (
                      <span
                        className="TickerPlayer-overallPar"
                        data-black={belowPar ? null : true}
                      >
                        {row.overallPar}
                      </span>
                    )}
                    {thru ? (
                      <span className="TickerPlayer-thru">
                        {thru}
                        {hole10Start && (
                          <sup className="TickerPlayer-hole10Start">*</sup>
                        )}
                      </span>
                    ) : teeTime ? (
                      <span className="TickerPlayer-thru">
                        {teeTime}
                        {hole10Start && (
                          <sup className="TickerPlayer-hole10Start">*</sup>
                        )}
                      </span>
                    ) : null}
                  </div>
                </React.Fragment>
              </div>
            )}
          </div>
        )}
        <favorite-button
          className="Favorite"
          data-player-id={row && row.golferId ? row.golferId : null}
        >
          <button
            tabIndex="0"
            className="Favorite-button"
            role="switch"
            aria-checked="false"
            type="button"
          >
            <span className="sr-only">
              {row &&
                row.golferFirstName &&
                row.golferLastName &&
                `${row.golferFirstName} ${row.golferLastName}`}
              Favorite
            </span>
            <svg className="star-off">
              <use href="#star-inactive" />
            </svg>
            <span className="text-off sr-only"> on</span>
            <svg className="star-on">
              <use href="#star-active" />
            </svg>
            <span className="text-on sr-only"> off</span>
          </button>
        </favorite-button>
      </div>
      {modalOpen !== false && (
        <ModalHighlightBasic
          video={highlightReel}
          handleModalOpen={handleModalOpen}
          handleModalClose={handleModalClose}
          firstName={row && row.golferFirstName ? row.golferFirstName : null}
          lastName={row && row.golferLastName ? row.golferLastName : null}
          image={
            golferStaticInfo && golferStaticInfo.image
              ? golferStaticInfo.image
              : null
          }
          overallPar={row && row.overallPar ? row.overallPar : null}
          teeData={golferCourseTee}
          golferId={row && row.golferId ? row.golferId : null}
          eventId={eventId}
          round={currentRound}
        />
      )}
    </React.Fragment>
  )
}

export default TickerPlayer
