import { Tracker } from 'eventTracker'
import { debounce } from 'debounce'
import Awesomplete from 'awesomplete'

export class LeaderboardPlayerFilter extends window.HTMLElement {
  connectedCallback () {
    this.listenerTracker = new Tracker()
    this.cacheElements()
    this.handleSearchInput()
  }

  cacheElements () {
    this.searchTextInput = this.getElementsByTagName('input')[0]
    this.body = document.getElementsByTagName('body')[0]
    this.searchModule = this.closest('ps-search-module')
    this.searchLocation = this.hasAttribute('data-search-location')
  }

  handleSearchInput () {
    if (this.body.hasAttribute('data-search-api')) {
      this.awesomplete = new Awesomplete(this.searchTextInput, {
        minChars: 3,
        replace: suggestion => {
          this.awesomplete.input.value = suggestion.label
          // setting a location value to forward user to new document location
          if (suggestion.value && this.searchLocation) {
            this.awesomplete.input.setAttribute(
              'data-search-location',
              suggestion.value
            )
          }
        }
      })

      this.listenerTracker.addListener(this.searchTextInput, 'keyup', event => {
        if (event.target.value.length >= 3) {
          debounce(150, () => {
            const filterPromise = new Promise(resolve => {
              resolve(this.filterEvent())
            })
            filterPromise.then(dataset => {
              this.typeEvent(event, dataset)
            })
          }).call()
        } else if (event.target.value.length === 0) {
          this.filterEvent()
        }
      })
      this.listenerTracker.addListener(
        this.searchTextInput,
        'awesomplete-selectcomplete',
        () => {
          this.filterEvent()
        }
      )
    }
  }

  filterEvent () {
    const filter = this.searchTextInput.value.toUpperCase()
    const rows = document.querySelectorAll('tbody')
    const playerDataset = []
    // Loop through all table rows, and hide those who don't match the search query
    rows.forEach((item, index) => {
      if (rows[index].hasAttribute('data-player-name')) {
        const td = rows[index].getAttribute('data-player-name')
        if (td) {
          const txtValue = td
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            rows[index].style.display = ''
            if (item.parentElement.className === 'LeaderboardTable') {
              playerDataset.push(txtValue)
            }
          } else {
            rows[index].style.display = 'none'
          }
        }
      } else {
        rows[index].style.display = 'none'
      }
    })
    return playerDataset
  }

  typeEvent (e, playerDataset) {
    if (
      e.keyCode !== 37 &&
      e.keyCode !== 38 &&
      e.keyCode !== 39 &&
      e.keyCode !== 40
    ) {
      this.awesomplete.list = playerDataset
    }
  }

  disconnectedCallback () {
    this.listenerTracker.removeListeners()
  }
}
