import { useQuery } from '@apollo/client'
import TickerLeaderboardModule from './TickerLeaderboardModuleReact'
import LASTUPDATED from './../../../../../graphql/versions/v2/queries/scoring/lastupdated.graphql'
import React from 'react'

const TickerLeaderboardModuleEntry = props => {
  const eventId = props.eventId
  let refreshInterval = props.refreshInterval ? props.refreshInterval * 1000 : 0

  const {
    error: lastUpdatedError,
    data: lastUpdatedData,
    loading: lastUpdatedLoading,
    stopPolling: stoplastUpdatedPolling
  } = useQuery(LASTUPDATED, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    pollInterval: refreshInterval,
    variables: {
      id: eventId
    }
  })

  if (lastUpdatedLoading) {
    return ''
  }

  const { timestamp, status } = lastUpdatedData.lastUpdated

  // if an event is completed, stop polling the lastUpdated query and set refreshInterval to 0
  if (
    (status && status === 'Scores Official') ||
    (status && status === 'Completed')
  ) {
    stoplastUpdatedPolling()
    refreshInterval = 0
  }
  return (
    <React.Fragment>
      {lastUpdatedError && console.error(lastUpdatedError)}
      <TickerLeaderboardModule timestamp={timestamp} eventId={eventId} />
    </React.Fragment>
  )
}

export default TickerLeaderboardModuleEntry
