import PlayoffRow from './PlayoffRowReact'
const PlayoffTable = props => {
  const { rows, golfers } = props
  const holeLabel = 'Hole'

  return (
    <table
      className="PlayoffTable"
      // {{dataAttr}} {{#if leaderboard}}data-cut-text="{{format 'cutText'}}"{{/if}} // TODO: is this needed?
    >
      <tbody className="PlayoffTable">
        <tr className="PlayoffTable-headRow">
          <th className="PlayoffTable-headData">{holeLabel}</th>
          {rows &&
            rows[0].playoffHoles.map((hole, i) => (
              <th className="PlayoffTable-headData" key={i}>
                {hole.holeNumber}
              </th>
            ))}
        </tr>
        <tr className="PlayoffTable-rowSpacer">
          <td className="PlayoffTable-space" colSpan="13" />
        </tr>
        <tr className="PlayoffRow">
          <td className="PlayoffRow-data">
            <span className="PlayoffRow-label">Par</span>
          </td>
          {rows &&
            rows[0].playoffHoles.map((hole, i) => (
              <td className="PlayoffRow-data" key={i}>
                <span className="PlayoffRow-par">{hole.par}</span>
              </td>
            ))}
        </tr>
        {rows &&
          rows.map((row, i) => (
            <PlayoffRow
              row={row}
              key={i}
              golfer={
                golfers &&
                golfers.filter(golfer => golfer.golferId === row.golferId)
              }
            />
          ))}
      </tbody>
    </table>
  )
}

export default PlayoffTable
