/* eslint-disable no-unused-vars, no-undef */
export class ArticleLongform extends window.HTMLElement {
  connectedCallback () {
    window.setTimeout(() => {
      this.loadParallax()
    }, 250)
  }

  async loadParallax () {
    try {
      const leadImg = this.querySelector('.ArticleLongform-lead img')
      const { default: SimpleParallax } = await import('simple-parallax-js')
      this.parallax = new SimpleParallax(leadImg, {
        scale: 1.4,
        orientation: 'down'
      })
    } catch (e) {
      console.warn('Error loading simple-parallax-js', e)
    }
  }
}
