import { useState } from 'react'
import PlayoffTable from './PlayoffTableReact'
const PlayoffModule = props => {
  const [toggleIn, setToggleIn] = useState(false)
  const { title, rows, golfers } = props

  const handleToggleNext = () => {
    setToggleIn(true)
    document.body.setAttribute('data-toggle-in', 'playoff-table')
  }

  const handleTogglePrev = () => {
    setToggleIn(false)
    if (document.body.getAttribute('data-toggle-in') === 'playoff-table') {
      document.body.removeAttribute('data-toggle-in', 'playoff-table')
    }
  }

  return (
    <div className="PlayoffModule">
      <div className="PlayoffModule-wrapper">
        <div
          data-toggle="playoff-table"
          data-extraholes={rows[0].playoffHoles.length > 9 ? true : null}
          data-toggle-in={toggleIn ? 'playoff-table' : null}
        >
          <div className="PlayoffModule-header">
            <div className="PlayoffModule-title">{title}</div>
            <div
              className="PlayoffModule-table-paginate"
              data-toggle-trigger="playoff-table"
            >
              <button
                onClick={handleTogglePrev}
                className="PlayoffModule-table-prev"
              >
                <svg className="chevron">
                  <use href="#chevron" />
                </svg>
              </button>
              <button
                onClick={handleToggleNext}
                className="PlayoffModule-table-next"
              >
                <svg className="chevron">
                  <use href="#chevron" />
                </svg>
              </button>
            </div>
          </div>
          {rows && <PlayoffTable rows={rows} golfers={golfers} />}
        </div>
      </div>
    </div>
  )
}

export default PlayoffModule
