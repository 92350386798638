import { Throttle } from 'throttle'
import MediaQueries from 'mediaQueries'
import Flickity from 'flickity'
export class ScorecardRound extends window.HTMLElement {
  connectedCallback () {
    this.row = this.closest('.LeaderboardRow')
    this.carouselSlides = this.querySelector('[class$="-slides"]')
    this.pageModal = this.closest('.Page-modal')
    this.mq = new MediaQueries().mq

    if (this.pageModal === null) {
      this.tab = this.closest('.LeaderboardFlyoutModule-scorecards-tabContent')

      this.holeStart10 = !!this.row.querySelector('[data-hole-10-start]')
      this.thruHole = parseInt(
        this.row.querySelector('.LeaderboardRow-thru').textContent
      )
    }

    setTimeout(() => {
      if (!this.mq['mq-viewport-md'].matches) {
        this.init()
      }
    }, 250)

    // needed in case numerous players are toggled open on page load
    // since the content hasn't fully loaded in time for the settimeout
    window.addEventListener('load', () => {
      this.resizeCarouselOnInit()
    })

    window.addEventListener('resize', () => {
      this.resizeScoreCardRound()
    })

    document.body.addEventListener(
      'PS-TABBED-TARGETS:Tabbed',
      (this._tabbed = () => {
        window.setTimeout(() => {
          if (typeof this.flickity !== 'undefined') {
            this.flickity.resize()
          }
        }, 250)
      })
    )

    this.addEventListener(
      'Back9',
      (this._back9 = e => {
        if (typeof this.flickity !== 'undefined') {
          this.flickity.next()
        }
      })
    )

    this.addEventListener(
      'Front9',
      (this._front9 = e => {
        if (typeof this.flickity !== 'undefined') {
          this.flickity.previous()
        }
      })
    )
  }

  resizeScoreCardRound () {
    Throttle(250, () => {
      if (!this.mq['mq-viewport-md'].matches) {
        this.init()
      } else if (typeof this.flickity !== 'undefined') {
        if (this.hasAttribute('data-toggle-in')) {
          this.removeAttribute('data-toggle-in')
        }
        this.flickity.destroy()
      }
    }).call()
  }

  resizeCarouselOnInit () {
    if (typeof this.flickity !== 'undefined') {
      this.flickity.resize()
    }
  }

  init () {
    this.handleOptions()
    this.flickity = new Flickity(this.carouselSlides, this.carouselOptions)

    this.flickity.on('change', index => {
      if (this.hasAttribute('data-toggle-in')) {
        this.removeAttribute('data-toggle-in')
        return
      }
      this.setAttribute('data-toggle-in', 'scorecard')
    })
  }

  handleOptions () {
    let startSlide = 0

    if (this.pageModal === null) {
      const shotLinkHole1 = this.tab.querySelector('[data-slide-hole="1"]')
      const shotLinkHole10 = this.tab.querySelector('[data-slide-hole="10"]')

      if (
        (!this.holeStart10 && this.thruHole > 9) ||
        (!this.holeStart10 && this.thruHole === 9 && shotLinkHole10 !== null) ||
        (this.holeStart10 && this.thruHole < 9) ||
        (this.holeStart10 && this.thruHole === 9 && shotLinkHole1 === null)
      ) {
        startSlide = 1
        this.setAttribute('data-toggle-in', 'scorecard')
      }
    } else {
      const selectedHole = this.querySelector('[data-shotlink-selected]')

      if (
        selectedHole !== null &&
        !this.mq['mq-viewport-md'].matches &&
        parseInt(selectedHole.getAttribute('data-hole-row')) > 9
      ) {
        startSlide = 1
      }
    }

    this.carouselOptions = {
      adaptiveHeight: true,
      cellAlign: 'left',
      wrapAround: false,
      groupCells: 10,
      imagesLoaded: true,
      pageDots: true,
      prevNextButtons: false,
      contain: false,
      initialIndex: startSlide
    }
  }

  disconnectedCallback () {
    if (typeof this.flickity !== 'undefined') {
      this.flickity.destroy()
    }

    if (this.row !== null) {
      document.body.removeEventListener(
        'PS-TABBED-TARGETS:Tabbed',
        this._tabbed
      )
    }

    window.removeEventListener('resize', this.resizeScoreCardRound)
    window.removeEventListener('load', this.resizeCarouselOnInit())
  }
}
