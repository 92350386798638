export class ExpandableModule extends window.HTMLElement {
  connectedCallback () {
    this.cacheElements()
    this.setListeners()
    this.checkCollapsed()
  }

  cacheElements () {
    this.button = this.querySelector('.ExpandableModule-button')
    this.title = this.querySelector(
      '.ExpandableModule-title'
    ).textContent.toLocaleLowerCase()
    this.expandableStorageName = `pga expandable ${this.title}`
    this.checkedAttr = 'aria-checked'
    this.collapsedAttr = 'data-collapsed'
    this.clickHandler = this.buttonClick.bind(this)
    this.storage = window.localStorage
  }

  checkCollapsed () {
    // if the expandable storage item exists, we want the
    // page to load with module collapsed
    if (this.storage.getItem(this.expandableStorageName)) {
      this.button.click()
    }
  }

  setListeners () {
    this.button.addEventListener('click', this.clickHandler)
  }

  buttonClick (e) {
    const el = e.currentTarget

    if (el.getAttribute(this.checkedAttr) === 'true') {
      el.setAttribute(this.checkedAttr, 'false')
      this.setAttribute(this.collapsedAttr, 'false')
      if (this.storage.getItem(this.expandableStorageName)) {
        this.storage.removeItem(this.expandableStorageName)
      }
    } else {
      el.setAttribute(this.checkedAttr, 'true')
      this.setAttribute(this.collapsedAttr, 'true')
      if (!this.storage.getItem(this.expandableStorageName)) {
        this.storage.setItem(this.expandableStorageName, 'collapsed')
      }
    }
  }

  disconnectedCallback () {
    this.button.removeEventListener('click', this.clickHandler)
  }
}
