import { useEffect } from 'react'
const MpsAdModule = props => {
  const { adSlot, adId } = props.row.ad

  useEffect(() => {
    if (adSlot && adId) {
      const script = document.createElement('script')
      script.innerHTML = `window.mps && window.mps._queue.gptloaded.push(function () { mps.cloneAd('#${adId}', '${adSlot}');});`
      document.getElementById(`${adId}`).parentNode.append(script)
    }
  }, [adSlot, adId])

  return (
    <div className="MpsAd-wrapper">
      <div id={adId} className="MpsAd-ad" data-ad-slot={adSlot} />
    </div>
  )
}

export default MpsAdModule
