/* eslint-disable no-unused-vars */

export class PsTimeAgo extends window.HTMLElement {
  connectedCallback () {
    this.promoLocalization = {
      'promo.hourAgo': '{t} hour ago',
      'promo.minuteAgo': '{t} min ago',
      'promo.hoursAgo': '{t} hours ago',
      'promo.minutesAgo': '{t} min ago',
      maxRelativeTime: '86400000',
      // maxRelative time is set to 24 hours
      timeToken: '{t}'
    }

    let CMSPromoLocalization = ''

    if (window.promoLocalization) {
      CMSPromoLocalization = JSON.parse(window.promoLocalization)
    }

    if (typeof CMSPromoLocalization === 'object') {
      this.promoLocalization = Object.assign(
        this.promoLocalization,
        CMSPromoLocalization
      )
    }

    this.timestamp = this
    this.timeStampOrDate()
  }

  formatTime (d) {
    const time = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short'
    }).format(d)
    return `at ${time}`
  }

  formatDate (date, showFormattedTime) {
    const month = []
    month[0] = 'January'
    month[1] = 'February'
    month[2] = 'March'
    month[3] = 'April'
    month[4] = 'May'
    month[5] = 'June'
    month[6] = 'July'
    month[7] = 'August'
    month[8] = 'September'
    month[9] = 'October'
    month[10] = 'November'
    month[11] = 'December'

    const d = new Date(date)
    const M = month[d.getMonth()]
    const D = d.getDate()
    const YYYY = d.getFullYear()
    const dateStr = `${M} ${D}, ${YYYY}`
    if (showFormattedTime) {
      const weekday = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ]
      const day = weekday[d.getDay()]
      return `${day}, ${dateStr} ${this.formatTime(d)}`
    }
    return dateStr
  }

  timeStampOrDate () {
    const shouldShowDate =
      this.timestamp.getAttribute('data-shouldShowDate') || false
    const shouldShowTime =
      this.timestamp.getAttribute('data-shouldShowTime') || false
    const showFormattedTime =
      this.timestamp.getAttribute('data-showFormattedTime') || false
    const date = this.timestamp.getAttribute('data-date') || false
    const formattedDate = this.formatDate(date, showFormattedTime)
    const self = this
    if (!date) {
      return
    }

    // Helper function to run to redo the timestamp per minute
    function recheckTimestamp () {
      self.renderTimestamp(self.timeStampString)

      if (self.minutesSincePublish > 59) {
        clearInterval(self.timestampCheckInterval)
      }
    }

    // NOTE: If data-showFormattedTime="true" and both
    // data-shouldShowTime="true" and data-showFormattedTime="true"
    // are on the element, the date will show as 4 hours ago, until after a day
    // and then it will show the full date and time published (Ex: 'Thursday, March 1, 2022 at 9:20 AM EST').
    // If you just have data-showFormattedTime="true" and data-showFormattedTime="true", it will
    // always show the formatted date and time

    // if we should show time, go into some logic, otherwise, we show the date
    if (shouldShowTime && date) {
      // if the minutes from publish are more than the max relative time, we will check
      // on the date. If they are under, we should show the time stamp string instead
      // of the date
      if (
        this.minutesSincePublish >
        parseInt(this.promoLocalization.maxRelativeTime) / 60000
      ) {
        if (shouldShowDate === 'true') {
          if (date) {
            this.renderTimestamp(formattedDate)
          }
        }
      } else {
        this.renderTimestamp(this.timeStampString)
        // if we are under 60 minutes, we set an interval, so the time stamp is live
        if (this.minutesSincePublish < 60) {
          this.timestampCheckInterval = setInterval(recheckTimestamp, 5000)
        }
      }
    } else {
      // its possible we do not want the date either, so check.
      if (shouldShowDate === 'true') {
        if (date) {
          this.renderTimestamp(formattedDate)
        }
      }
    }
  }

  get timeStampString () {
    let timestampString = ''

    if (this.minutesSincePublish < 0) {
      return false
    }

    // We use the replacement strings that were left for us in the head
    // to do the string replacement. No JS template strings, as the head is not ES6
    if (this.minutesSincePublish === 0 || this.minutesSincePublish === 1) {
      // if we at or under a minute, we use 1 minute
      timestampString = this.promoLocalization['promo.minuteAgo'].replace(
        /{t}/,
        '1'
      )
      this.timestamp.setAttribute('data-recent', true)
    } else {
      // if we are less than an hour, we do minutes string
      if (this.minutesSincePublish < 60) {
        timestampString = this.promoLocalization['promo.minutesAgo'].replace(
          /{t}/,
          this.minutesSincePublish
        )
        this.timestamp.setAttribute('data-recent', true)
      } else {
        // if over 1 hour but under 2, we do hour string
        if (this.minutesSincePublish < 120) {
          timestampString = this.promoLocalization['promo.hourAgo'].replace(
            /{t}/,
            '1'
          )
          this.timestamp.removeAttribute('data-recent')
        } else {
          // Finally once we are past 120 minutes, we just count the hours
          timestampString = this.promoLocalization['promo.hoursAgo'].replace(
            /{t}/,
            Math.floor(this.minutesSincePublish / 60)
          )
          this.timestamp.removeAttribute('data-recent')
        }
      }
    }

    return timestampString
  }

  renderTimestamp (value) {
    if (value) {
      this.timestamp.innerHTML = value
      this.timestamp.setAttribute('data-show-timestamp', true)
    }
  }

  // Check the timestamp in the promo against the current UTC time and divide to get minutes
  get minutesSincePublish () {
    const promoTimestamp =
      new Date(this.timestamp.getAttribute('data-date')) || 0
    const currentTimestamp = new Date().getTime()
    let minutesSincePublish = 0

    if (promoTimestamp) {
      minutesSincePublish = Math.floor(
        (currentTimestamp - promoTimestamp) / 60000
      )
      return minutesSincePublish
    } else {
      return false
    }
  }
}
