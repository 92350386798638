import MiniLeaderboardRow from './MiniLeaderboardRowReact'
import MpsAdModule from './MpsAdModuleReact'

const MiniLeaderboardTable = props => {
  const { rows, currentRound, golfers } = props
  const posLabel = 'Pos'
  const playerLabel = 'Player'
  const overallLabel = 'Tot'
  const thruLabel = 'Thru'
  const roundLabel = 'Today'

  return (
    <div className="LeaderboardMiniTable">
      <div className="LeaderboardMiniTable-headRow">
        <div className="LeaderboardMiniTable-headData">{posLabel}</div>
        <div className="LeaderboardMiniTable-headData">{playerLabel}</div>
        <div className="LeaderboardMiniTable-headData">{overallLabel}</div>
        <div className="LeaderboardMiniTable-headData">{thruLabel}</div>
        <div className="LeaderboardMiniTable-headData">{roundLabel}</div>
      </div>
      {rows &&
        rows.map((row, i) => {
          return row.ad ? (
            <div className="LeaderboardMiniRow" key={i}>
              <div className="LeaderboardMiniRow-row">
                <div className="LeaderboardMiniRow-data">
                  <MpsAdModule row={row} />
                </div>
              </div>
            </div>
          ) : (
            <MiniLeaderboardRow
              row={row}
              key={row.golferId}
              index={i}
              currentRound={currentRound}
              golfer={
                golfers &&
                golfers.filter(golfer => golfer.golferId === row.golferId)
              }
            />
          )
        })}
    </div>
  )
}

export default MiniLeaderboardTable
