/* eslint-disable no-trailing-spaces */
import { useState, useEffect } from 'react'

export default function Select (props) {
  const [selectedValue, setValue] = useState('')
  const select = props.select

  useEffect(() => {
    select.forEach(option => {
      if (option.selected) {
        setValue(option.value)
      }
    })
  }, [select])

  return (
    <label className="StatsTable-filter">
      <span className="sr-only">Rounds</span>
      <select
        value={selectedValue}
        onChange={e => props.changeParams(e.currentTarget.value)}
      >
        {select.map(options => (
          <option value={options.value} key={options.value}>
            {options.label}
          </option>
        ))}
      </select>
    </label>
  )
}
