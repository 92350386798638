export class ScorecardTableRow extends window.HTMLElement {
  connectedCallback () {
    this.par = this.querySelector('.ScorecardTableRow-par')
    this.score = this.querySelector('.ScorecardTableRow-score')

    // method that calculates par to player score and adds the scoring symbol
    if (this.score.textContent || this.par.textContent) {
      this.updateScoreSymbol(this.score.textContent, this.par.textContent)
    }
  }

  updateScoreSymbol (score, par) {
    const diff = score - par

    if (diff <= -2) {
      this.score.classList.add('eagle')
    } else if (diff === -1) {
      this.score.classList.add('birdie')
    } else if (diff === 1) {
      this.score.classList.add('bogey')
    } else if (diff >= 2) {
      this.score.classList.add('double')
    }
  }

  disconnectedCallback () {
    return false
  }
}
