import GetImage from './util/GetImage'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Affiliation = props => {
  const { affiliation, affiliationDescriptionShow } = props

  const affiliationObjectHelper = () => {
    if (!affiliationDescriptionShow) {
      return affiliation[0]
    } else return affiliation
  }

  const affiliationObject = affiliationObjectHelper()

  return (
    <div className="Affiliation">
      <div className="Affiliation-logo">
        {/* Image Size Affiliation Logo */}
        {affiliationObject.logo &&
        affiliationObject.logo.href &&
        affiliationObject.logo.image ? (
          <a
            aria-label={affiliationObject.logo.ariaLabel}
            href={affiliationObject.logo.href}
            target="_blank"
            rel="noreferrer"
            className="logo-wrapper"
          >
            <LazyLoadImage
              alt={affiliationObject.name | ''}
              height="27"
              src={GetImage.src(affiliationObject.logo.image)}
              width="50"
            />
          </a>
            ) : affiliationObject.logo.image ? (
          <span className="logo-wrapper">
            <LazyLoadImage
              alt={affiliationObject.name | ''}
              height="27"
              src={GetImage.src(affiliationObject.logo.image)}
              width="50"
            />
          </span>
            ) : null}
      </div>
      {affiliationObject.description && affiliationDescriptionShow && (
        <div className="Affiliation-description">
          {affiliationObject.description}
        </div>
      )}
    </div>
  )
}

export default Affiliation
