import CookieJar from '../../util/CookieJar'

export class CookieNotification extends window.HTMLElement {
  connectedCallback () {
    this.COOKIE_NAME = 'bsp.pgaCookiePolicyClosed'
    this.SHOW_ATTR = 'data-show'
    this.checkCookie()
  }

  checkCookie () {
    if (!CookieJar.hasCookie(this.COOKIE_NAME)) {
      this.closeBtn = this.getElementsByClassName('CookieNotification-close')[0]

      window.setTimeout(() => {
        this.setAttribute(this.SHOW_ATTR, '')
      }, 1500)

      this.closeBtn.addEventListener(
        'click',
        (this._closeNotification = this.closeNotification.bind(this))
      )
    }
  }

  closeNotification () {
    CookieJar.createCookie(
      this.COOKIE_NAME,
      true,
      null,
      document.location.hostname
    )

    if (this.hasAttribute(this.SHOW_ATTR)) {
      this.removeAttribute(this.SHOW_ATTR)
    }
  }

  removeEventListener () {
    if (this._closeNotification !== null && this.closeBtn !== null) {
      this.closeBtn.removeEventListener('click', this._closeNotification)
    }
  }

  disconnectedCallback () {
    this.removeEventListener()
  }
}
