export function Delegate (elSelector, eventName, selector, fn) {
  const element =
    typeof elSelector === 'object'
      ? elSelector
      : document.querySelector(elSelector)
  element.addEventListener(eventName, e => {
    const possibleTargets = element.querySelectorAll(selector)
    const target = e.target

    for (let i = 0, l = possibleTargets.length; i < l; i++) {
      let el = target
      const p = possibleTargets[i]

      while (el && el !== element) {
        if (el === p) {
          return fn.call(p, e)
        }

        el = el.parentNode
      }
    }
  })
}

/**
 * For when you need to keep track of the listener (eg. you want to remove it lager),
 * you can just create a delegate listener, and ad it yourself to the element.
 *
 * @param {string | HTMLElement} elSelector element or element selector in dom
 * @param {*} eventName name of event
 * @param {*} selector name of element in event path
 * @param {*} fn to execute when event happens
 */
export function DelegateListener (elSelector, eventName, selector, fn) {
  const element =
    typeof elSelector === 'object'
      ? elSelector
      : document.querySelector(elSelector)

  return e => {
    const possibleTargets = element.querySelectorAll(selector)
    const target = e.target

    for (let i = 0, l = possibleTargets.length; i < l; i++) {
      let el = target
      const p = possibleTargets[i]

      while (el && el !== element) {
        if (el === p) {
          return fn.call(p, e)
        }

        el = el.parentNode
      }
    }
  }
}
