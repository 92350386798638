import React from 'react'
import ReactDOM from 'react-dom'
import GetImage from './util/GetImage'
import ScoreCardRoundBasic from './scorecard-react/ScorecardRoundBasicReact'

const ModalHighlightBasic = props => {
  const {
    handleModalClose,
    firstName,
    lastName,
    image,
    overallPar,
    round,
    teeData,
    video,
    golferId,
    eventId
  } = props

  return ReactDOM.createPortal(
    <div className="Page-modal" onClick={handleModalClose}>
      <div
        className="Page-modal-wrapper"
        data-modal-highlight
        onClick={handleModalClose}
        onKeyDown={handleModalClose}
      >
        <div
          className="Page-modal-content"
          data-highlight
          onClick={e => e.stopPropagation()}
        >
          <button
            className="Page-modal-close"
            aria-label="close"
            onClick={handleModalClose}
          >
            <svg className="close-x">
              <use href="#close-x" />
            </svg>
          </button>
          <div className="ModalHighlights">
            <div className="ModalHighlights-top">
              <div className="ModalHighlights-playerInfo">
                {image && (
                  <div className="ModalHighlights-image">
                    <img
                      src={GetImage.src(image)}
                      alt={`Headshot of ${firstName || ''} ${lastName || ''}`}
                    />
                  </div>
                )}
                {firstName && lastName && (
                  <div className="ModalHighlights-playerName">
                    <div className="ModalHighlights-firstname">{firstName}</div>
                    <div className="ModalHighlights-lastname">{lastName}</div>
                  </div>
                )}
              </div>
              {overallPar && (
                <div className="ModalHighlights-info">
                  <div className="LeaderboardRow-overallPar">{overallPar}</div>
                </div>
              )}
            </div>
            <div className="ModalHighlights-middle">
              {video && (
                <React.Fragment>
                  <div className="ModalHighlights-title">{video.title}</div>
                  <div className="ModalHighlights-player">
                    <div className="MPXVideoPlayer">
                      <div
                        className="MPXVideoPlayer-screen"
                        data-aspect-ratio="16x9"
                      >
                        <div className="MPXVideoPlayer-iframe-container">
                          <iframe
                            src={video.videoUri}
                            style={{ border: '0' }}
                            allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
                            allowFullScreen
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <ScoreCardRoundBasic
                round={round}
                teeData={teeData}
                eventId={eventId}
                golferId={golferId}
              />
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  )
}

export default ModalHighlightBasic
