import Flickity from 'flickity'

export class ListCarousel extends window.HTMLElement {
  connectedCallback () {
    this.carouselSlides = this.querySelector('[class$="-items"]') || this

    window.setTimeout(() => {
      this.init()
    }, 250)

    // needed in case numerous players are toggled open on page load
    // since the content hasn't fully loaded in time for the settimeout
    window.addEventListener('load', () => {
      this.resizeCarousel()
    })

    document.body.addEventListener(
      'PS-TABBED-TARGETS:Tabbed',
      (this._tabbed = () => {
        window.setTimeout(() => {
          if (this.offsetWidth > 0) {
            this.resizeCarousel()
          }
        }, 250)
      })
    )
  }

  init () {
    this.handleOptions()
    this.flickity = new Flickity(this.carouselSlides, this.carouselOptions)
  }

  getMediaQuery () {
    const mqValue =
      window
        .getComputedStyle(document.querySelector('body'), '::before')
        .getPropertyValue('content') || false

    if (mqValue) {
      return mqValue.replace(/["']/g, '')
    } else {
      return false
    }
  }

  // we set or reset options here based on the current media query
  // if a conditional has mq specific options, we check to see if the mq
  // changed, and if it did, we set a global changed var so that we
  // can rebuild the carousel

  handleOptions () {
    this.optionsChanged = false

    this.carouselOptions = {
      pageDots: false,
      lazyLoad: 2,
      adaptiveHeight: true,
      imagesLoaded: true,
      prevNextButtons: true,
      cellAlign: 'left'
    }
  }

  resizeCarousel () {
    if (typeof this.flickity !== 'undefined') {
      this.flickity.resize()
    }
  }

  disconnectedCallback () {
    if (typeof this.flickity !== 'undefined') {
      this.flickity.destroy()
    }
    window.removeEventListener('load', this.resizeCarousel())
  }
}
