import React, { useState, useEffect } from 'react'
import ScorecardRound from './scorecard-react/ScorecardRoundReact'
import ShotlinkModule from './shotlink-react/ShotlinkModuleReact'
import ScorecardMediaModule from './scorecardmedia-react/ScorecardMediaModuleReact'
import LeaderboardLegend from './LeaderboardLegendReact'
import ModalHighlight from './ModalHighlightReact'
// import LoadingSpinner from './LoadingSpinnerReact'
import SCORECARDMEDIA from './../../../../../graphql/versions/v2/queries/scoring/scorecardmedia.graphql'
import SHOTLINK from './../../../../../graphql/versions/v2/queries/scoring/shotlink.graphql'
import SCORECARD from './../../../../../graphql/versions/v2/queries/scoring/scorecard.graphql'
import { useQuery } from '@apollo/client'
import { removeStorageItem } from 'storageHooks'

const LeaderboardFlyoutModule = props => {
  const {
    timestamp,
    lastUpdatedTimestamp,
    currentRound,
    round1Tees,
    round2Tees,
    round3Tees,
    round4Tees,
    setFlyoutOpen,
    eventId,
    hole10Start,
    shotlinkImages,
    disableShotlink,
    disableScorecardMedia,
    highlightVideo,
    eventStatus,
    playerPageUrl,
    image,
    multiCourse,
    rowStyle,
    status
  } = props

  const {
    round1GolferRoundScore,
    round2GolferRoundScore,
    round3GolferRoundScore,
    round4GolferRoundScore,
    round1GolferRoundTeeTime,
    round2GolferRoundTeeTime,
    round3GolferRoundTeeTime,
    round4GolferRoundTeeTime,
    golferFirstName,
    golferLastName,
    golferId,
    overallPar
  } = props.row

  let fetchPolicy = 'cache-and-network'

  // temporary for dev because we use the same round 4 data 4 times and react seems
  // to freak out on this
  if (window.location.href.indexOf('localhost') >= -1) {
    fetchPolicy = 'cache-first'
  }

  // hole selector for scorecard. If the event is done, we mark hole 1 always
  // if a golfer is finished, always 1, regardless of start
  // if they are prestart, show 1 or 10 depending on hole10start
  // or if in progress, one hole past where the golfer is, including logic
  // for hole 10 start
  const holeSelector = () => {
    function hole10StartHelper (thru) {
      if (thru === 'F') {
        return 1
      }

      if (thru === '18') {
        return 1
      }

      if (thru === '-') {
        if (hole10Start) {
          return 10
        } else {
          return 1
        }
      } else {
        if (hole10Start) {
          if (!thru) {
            return 10
          }

          if (parseInt(thru) > 8) {
            return parseInt(thru) - 8
          } else {
            return parseInt(thru) + 10
          }
        } else {
          if (!thru) {
            return 1
          }

          return parseInt(thru) + 1
        }
      }
    }

    if (
      (eventStatus && eventStatus === 'Scores Official') ||
      (eventStatus && eventStatus === 'Completed')
    ) {
      return 1
    }

    if (roundSelect === 1 && round1GolferRoundScore) {
      return hole10StartHelper(round1GolferRoundScore.thru)
    }

    if (roundSelect === 2 && round2GolferRoundScore) {
      return hole10StartHelper(round2GolferRoundScore.thru)
    }

    if (roundSelect === 3 && round3GolferRoundScore) {
      return hole10StartHelper(round3GolferRoundScore.thru)
    }

    if (roundSelect === 4 && round4GolferRoundScore) {
      return hole10StartHelper(round4GolferRoundScore.thru)
    }

    return 1
  }

  // to get filteredRound to determine active tab for players with disqualifying status
  const roundGolferRoundScore =
    currentRound && currentRound === 1
      ? round1GolferRoundScore
      : currentRound && currentRound === 2
        ? round2GolferRoundScore
        : currentRound && currentRound === 3
          ? round3GolferRoundScore
          : currentRound && currentRound === 4
            ? round4GolferRoundScore
            : null

  const roundGolferRoundScoresArray = []
  roundGolferRoundScoresArray.push(
    round1GolferRoundScore &&
      round1GolferRoundScore.roundScore &&
      round1GolferRoundScore.roundScore !== '-'
      ? parseInt(round1GolferRoundScore.roundScore)
      : null
  )
  roundGolferRoundScoresArray.push(
    round2GolferRoundScore &&
      round2GolferRoundScore.roundScore &&
      round2GolferRoundScore.roundScore !== '-'
      ? parseInt(round2GolferRoundScore.roundScore)
      : null
  )
  roundGolferRoundScoresArray.push(
    round3GolferRoundScore &&
      round3GolferRoundScore.roundScore &&
      round3GolferRoundScore.roundScore !== '-'
      ? parseInt(round3GolferRoundScore.roundScore)
      : null
  )
  roundGolferRoundScoresArray.push(
    round4GolferRoundScore &&
      round4GolferRoundScore.roundScore &&
      round4GolferRoundScore.roundScore !== '-'
      ? parseInt(round4GolferRoundScore.roundScore)
      : null
  )

  function filteredRoundGolferRoundScoreHelper () {
    if (status !== '' || null) {
      for (let i = 0; i < roundGolferRoundScoresArray.length; i++) {
        if (roundGolferRoundScoresArray[i] === null) {
          if (i === 0) {
            return 1
          }
          return i // return the last round that was not null. If the first round was null, return 1
        }
      }
    }
    return currentRound
  }

  const filteredRound = filteredRoundGolferRoundScoreHelper()

  const [visualSelect, setVisualSelect] = useState(null)
  // used to deal with selecting round tabs
  const [roundSelect, setRoundSelect] = useState(filteredRound)
  // used to deal with selecting a hole in the scorecard. Keep track of it here, so we can pass this
  // data into the shotlink module
  const [holeSelect, setHoleSelect] = useState(holeSelector)
  // used to deal with open and closing the modal
  const [modalOpen, setModalOpen] = useState(false)
  // used to deal with the video carousel selector between all and best videos
  const [selectedVideos, setSelectedVideos] = useState('all4')
  // these are used to mark the original gql calls as completed so we can prefetch more round data
  const [scorecardCompleted, setScorecardCompleted] = useState(false)
  const [scorecardMediaCompleted, setScorecardMediaCompleted] = useState(false)
  const [shotlinkCompleted, setShotlinkCompleted] = useState(false)

  // This creates an array of rounds to prefetch, based on the current round
  let rounds = []
  const roundsArrayCreator = () => {
    if (roundSelect === 1) {
      rounds = []
    }
    if (roundSelect === 2) {
      rounds = [1]
    }
    if (roundSelect === 3) {
      rounds = [1, 2]
    }
    if (roundSelect === 4) {
      rounds = [1, 2, 3]
    }
    return rounds
  }
  const roundsArray = roundsArrayCreator()

  const {
    error: scorecardError,
    data: scorecardData,
    previousData: previousScorecardData,
    loading: scorecardLoading,
    client: scorecardClient,
    refetch: scorecardRefetch
  } = useQuery(SCORECARD, {
    fetchPolicy: fetchPolicy,
    errorPolicy: 'all',
    onCompleted: () => setScorecardCompleted(true),
    variables: {
      id: eventId,
      golferId: golferId,
      round: roundSelect,
      timestamp: timestamp
    }
  })

  const {
    error: scorecardMediaError,
    data: scorecardMediaData,
    previousData: previousScorecardMediaData,
    loading: scorecardMediaLoading,
    client: scorecardMediaClient,
    refetch: scorecardMediaRefetch
  } = useQuery(SCORECARDMEDIA, {
    fetchPolicy: fetchPolicy,
    errorPolicy: 'all',
    onCompleted: () => setScorecardMediaCompleted(true),
    variables: {
      id: eventId,
      golferId: golferId,
      round: roundSelect,
      timestamp: timestamp
    }
  })

  const {
    error: shotlinkError,
    data: shotlinkData,
    previousData: previousShotlinkData,
    loading: shotlinkLoading,
    client: shotlinkClient,
    refetch: shotlinkRefetch
  } = useQuery(SHOTLINK, {
    fetchPolicy: fetchPolicy,
    errorPolicy: 'all',
    onCompleted: () => setShotlinkCompleted(true),
    variables: {
      id: eventId,
      golferId: golferId,
      round: roundSelect,
      timestamp: timestamp
    }
  })

  useEffect(() => {
    if (lastUpdatedTimestamp !== timestamp) {
      scorecardRefetch()
      scorecardMediaRefetch()
      shotlinkRefetch()
    }
  }, [
    timestamp,
    lastUpdatedTimestamp,
    scorecardRefetch,
    shotlinkRefetch,
    scorecardMediaRefetch
  ])

  useEffect(() => {
    if (
      !disableScorecardMedia &&
      scorecardMediaData &&
      scorecardMediaData.scorecardMedia &&
      ((scorecardMediaData.scorecardMedia.bestShotsVideos &&
        scorecardMediaData.scorecardMedia.bestShotsVideos.length > 0) ||
        (scorecardMediaData.scorecardMedia.scorecardVideos &&
          scorecardMediaData.scorecardMedia.scorecardVideos.length > 0))
    ) {
      setVisualSelect('highlights')
    } else if (shotlinkImages && !disableShotlink && shotlinkData) {
      setVisualSelect('shotlink')
    } else {
      setVisualSelect(null)
    }
  }, [
    scorecardMediaData,
    disableScorecardMedia,
    disableShotlink,
    shotlinkImages,
    shotlinkData
  ])

  console.log('scorecard data: ' + (scorecardData ? 'yes' : 'no'))
  console.log(
    'previous scorecard data: ' + (previousScorecardData ? 'yes' : 'no')
  )
  console.log('scorecard media data: ' + (scorecardMediaData ? 'yes' : 'no'))
  console.log(
    'previous scorecard media data: ' +
      (previousScorecardMediaData ? 'yes' : 'no')
  )
  console.log('shotlink data: ' + (shotlinkData ? 'yes' : 'no'))
  console.log(
    'previous shotlink data: ' + (previousShotlinkData ? 'yes' : 'no')
  )

  if (shotlinkLoading || scorecardMediaLoading || scorecardLoading) {
    return (
      <div
        className="LeaderboardFlyoutModule-loading"
        data-video-carousel={disableScorecardMedia ? null : true}
      >
        <div className="LeaderboardFlyoutModule-loading-circle" />
      </div>
    )
  }

  // used to prefetch all of the rounds in the flyout
  if (scorecardCompleted) {
    for (let i = 0; i < roundsArray.length; i++) {
      scorecardClient.query({
        query: SCORECARD,
        variables: {
          id: eventId,
          golferId: golferId,
          round: roundsArray[i],
          timestamp: timestamp
        }
      })
    }
  }
  if (shotlinkCompleted) {
    for (let i = 0; i < roundsArray.length; i++) {
      shotlinkClient.query({
        query: SHOTLINK,
        variables: {
          id: eventId,
          golferId: golferId,
          round: roundsArray[i],
          timestamp: timestamp
        }
      })
    }
  }
  if (scorecardMediaCompleted) {
    for (let i = 0; i < roundsArray.length; i++) {
      scorecardMediaClient.query({
        query: SCORECARDMEDIA,
        variables: {
          id: eventId,
          golferId: golferId,
          round: roundsArray[i],
          timestamp: timestamp
        }
      })
    }
  }

  const round1Label = 'Round 1'
  const round2Label = 'Round 2'
  const round3Label = 'Round 3'
  const round4Label = 'Round 4'

  const round1LabelMobile = 'Rd 1'
  const round2LabelMobile = 'Rd 2'
  const round3LabelMobile = 'Rd 3'
  const round4LabelMobile = 'Rd 4'

  // creates an array of all the shotlink images to pass to shotlink (since they exit in the leaderboard query)
  const shotlinkShotImages = shotlinkImages
  const shotlinkHoleImages = []
  for (let i = 1; i < 19; i++) {
    if (shotlinkShotImages && shotlinkShotImages[`hole${i}`]) {
      shotlinkHoleImages.push(shotlinkShotImages[`hole${i}`])
    }
  }

  // for multiple courses determine course to use
  function roundTeesSelector (round) {
    if (round === 1) {
      // if only one course return course that is not null
      if (!multiCourse) {
        if (
          round1Tees &&
          round1Tees.roundTees &&
          round1Tees.roundTees.length > 0 &&
          round1GolferRoundTeeTime
        ) {
          return Array(round1Tees.roundTees[0])
        } else {
          return null
        }
      }
      if (multiCourse) {
        if (
          round1Tees &&
          round1Tees.roundTees &&
          round1Tees.roundTees.length > 0 &&
          round1GolferRoundTeeTime
        ) {
          const filteredRound1Tees = round1Tees.roundTees.filter(
            round1Tee => round1Tee.id === round1GolferRoundTeeTime.roundTeeId
          )
          return filteredRound1Tees
        }
      } else {
        return null
      }
    }
    if (round === 2) {
      if (!multiCourse) {
        if (
          round2Tees &&
          round2Tees.roundTees &&
          round2Tees.roundTees.length > 0 &&
          round2GolferRoundTeeTime
        ) {
          return Array(round2Tees.roundTees[0])
        } else {
          return null
        }
      } else if (multiCourse) {
        if (
          round2Tees &&
          round2Tees.roundTees &&
          round2Tees.roundTees.length > 0 &&
          round2GolferRoundTeeTime
        ) {
          const filteredRound2Tees = round2Tees.roundTees.filter(
            round2Tee => round2Tee.id === round2GolferRoundTeeTime.roundTeeId
          )
          return filteredRound2Tees
        }
      } else {
        return null
      }
    }
    if (round === 3) {
      if (!multiCourse) {
        if (
          round3Tees &&
          round3Tees.roundTees &&
          round3Tees.roundTees.length > 0 &&
          round3GolferRoundTeeTime
        ) {
          return Array(round3Tees.roundTees[0])
        } else {
          return null
        }
      } else if (multiCourse) {
        if (
          round3Tees &&
          round3Tees.roundTees &&
          round3Tees.roundTees.length > 0 &&
          round3GolferRoundTeeTime
        ) {
          const filteredRound3Tees = round3Tees.roundTees.filter(
            round3Tee => round3Tee.id === round3GolferRoundTeeTime.roundTeeId
          )
          return filteredRound3Tees
        }
      } else {
        return null
      }
    }
    if (round === 4) {
      if (!multiCourse) {
        if (
          round4Tees &&
          round4Tees.roundTees &&
          round4Tees.roundTees.length > 0 &&
          round4GolferRoundTeeTime
        ) {
          return Array(round4Tees.roundTees[0])
        } else {
          return null
        }
      } else if (multiCourse) {
        if (
          round4Tees &&
          round4Tees.roundTees &&
          round4Tees.roundTees.length > 0 &&
          round4GolferRoundTeeTime
        ) {
          const filteredRound4Tees = round4Tees.roundTees.filter(round4Tee => {
            return round4Tee.id === round4GolferRoundTeeTime.roundTeeId
          })
          return filteredRound4Tees
        }
      } else {
        return null
      }
    }
    return null
  }

  const filteredTeeData = roundTeesSelector(roundSelect)
  const closeFlyout = () => {
    setFlyoutOpen(false)
    removeStorageItem(sessionStorage, 'pgaFlyout', golferId)
  }

  function handleSelectedScorecardRound (selectedRound) {
    setRoundSelect(selectedRound)
  }

  const handleModalOpen = videoObject => {
    setModalOpen(videoObject)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  return (
    <React.Fragment>
      {shotlinkError && console.error(shotlinkError)}
      {scorecardMediaError && console.error(scorecardMediaError)}
      {scorecardError && console.error(scorecardError)}
      <div className="LeaderboardFlyoutModule" style={rowStyle}>
        <button className="LeaderboardFlyoutModule-close" onClick={closeFlyout}>
          <svg className="close">
            <use href="#close-x" />
          </svg>
        </button>
        <div className="LeaderboardFlyoutModule-rounds">
          <div className="LeaderboardFlyoutModule-scorecards">
            <div className="LeaderboardFlyoutModule-scorecards-tabs">
              {round1GolferRoundScore || round1GolferRoundTeeTime ? (
                <button
                  className="LeaderboardFlyoutModule-scorecards-tab"
                  data-round="1"
                  data-tabbed-target-in={roundSelect === 1 ? 'show-tab' : null}
                  onClick={() => handleSelectedScorecardRound(1)}
                >
                  <span className="LeaderboardFlyoutModule-scorecards-tab-text-mobile">
                    {round1LabelMobile}
                  </span>
                  <span className="LeaderboardFlyoutModule-scorecards-tab-text-large">
                    {round1Label}
                  </span>
                </button>
              ) : null}
              {(round2GolferRoundTeeTime && currentRound > 1) ||
              (round2GolferRoundScore && currentRound > 1) ? (
                <button
                  className="LeaderboardFlyoutModule-scorecards-tab"
                  data-round="2"
                  data-tabbed-target-in={roundSelect === 2 ? 'show-tab' : null}
                  onClick={() => handleSelectedScorecardRound(2)}
                >
                  <span className="LeaderboardFlyoutModule-scorecards-tab-text-mobile">
                    {round2LabelMobile}
                  </span>
                  <span className="LeaderboardFlyoutModule-scorecards-tab-text-large">
                    {round2Label}
                  </span>
                </button>
                  ) : null}
              {(round3GolferRoundTeeTime && currentRound > 2) ||
              (round3GolferRoundScore && currentRound > 2) ? (
                <button
                  className="LeaderboardFlyoutModule-scorecards-tab"
                  data-round="3"
                  data-tabbed-target-in={roundSelect === 3 ? 'show-tab' : null}
                  onClick={() => handleSelectedScorecardRound(3)}
                >
                  <span className="LeaderboardFlyoutModule-scorecards-tab-text-mobile">
                    {round3LabelMobile}
                  </span>
                  <span className="LeaderboardFlyoutModule-scorecards-tab-text-large">
                    {round3Label}
                  </span>
                </button>
                  ) : null}
              {(round4GolferRoundTeeTime && currentRound > 3) ||
              (round4GolferRoundScore && currentRound > 3) ? (
                <button
                  className="LeaderboardFlyoutModule-scorecards-tab"
                  data-round="4"
                  data-tabbed-target-in={roundSelect === 4 ? 'show-tab' : null}
                  onClick={() => handleSelectedScorecardRound(4)}
                >
                  <span className="LeaderboardFlyoutModule-scorecards-tab-text-mobile">
                    {round4LabelMobile}
                  </span>
                  <span className="LeaderboardFlyoutModule-scorecards-tab-text-large">
                    {round4Label}
                  </span>
                </button>
                  ) : null}
            </div>
            <div className="LeaderboardFlyoutModule-scorecards-tabContent">
              <div
                className="LeaderboardFlyoutModule-scoreWrapper"
                data-media={!disableScorecardMedia}
                data-no-shotlink={
                  disableShotlink ||
                  !shotlinkData ||
                  shotlinkHoleImages.length <= 0
                    ? 'true'
                    : null
                }
              >
                {scorecardData && (
                  <React.Fragment>
                    <ScorecardRound
                      status={status}
                      roundGolferRoundScore={
                        roundSelect === 1
                          ? round1GolferRoundScore
                          : roundSelect === 2
                            ? round2GolferRoundScore
                            : roundSelect === 3
                              ? round3GolferRoundScore
                              : roundSelect === 4
                                ? round4GolferRoundScore
                                : null
                      }
                      round={roundSelect}
                      modalScorecard={false}
                      hole10Start={hole10Start || null}
                      setHoleSelect={setHoleSelect}
                      selectedVideos={selectedVideos}
                      holeSelect={holeSelect}
                      disableScorecardMedia={disableScorecardMedia}
                      disableShotlink={disableShotlink}
                      handleModalOpen={handleModalOpen}
                      scorecard={scorecardData.scorecard}
                      teeData={
                        filteredTeeData && filteredTeeData.length > 0
                          ? filteredTeeData[0]
                          : null
                      }
                    />
                    <LeaderboardLegend playerPageUrl={playerPageUrl} />
                  </React.Fragment>
                )}
              </div>
              <div className="LeaderboardFlyoutModule-visuals-tabs">
                <button
                  data-visual-tab-active={
                    visualSelect === 'highlights' ? 'highlights' : null
                  }
                  onClick={() => setVisualSelect('highlights')}
                  className="LeaderboardFlyoutModule-visuals-tab"
                  data-no-video-carousel={
                    disableScorecardMedia ||
                    !scorecardMediaData.scorecardMedia ||
                    ((!scorecardMediaData.scorecardMedia.bestShotsVideos ||
                      scorecardMediaData.scorecardMedia.bestShotsVideos
                        .length === 0) &&
                      (!scorecardMediaData.scorecardMedia.scorecardVideos ||
                        scorecardMediaData.scorecardMedia.scorecardVideos
                          .length === 0))
                      ? true
                      : null
                  }
                >
                  Highlights
                </button>
                <button
                  onClick={() => setVisualSelect('shotlink')}
                  className="LeaderboardFlyoutModule-visuals-tab"
                  data-no-shotlink={
                    disableShotlink ||
                    !shotlinkData ||
                    shotlinkHoleImages.length <= 0
                      ? 'true'
                      : null
                  }
                  data-visual-tab-active={
                    visualSelect === 'shotlink' ? 'shotlink' : null
                  }
                >
                  Shot Tracker
                </button>
              </div>
              {disableShotlink ||
              !shotlinkData ||
              visualSelect !== 'shotlink' ||
              shotlinkHoleImages.length <= 0 ? null : (
                <ShotlinkModule
                  shotlinks={shotlinkData.shotlink}
                  holeSelect={holeSelect}
                  setHoleSelect={setHoleSelect}
                  shotlinkHoleImages={shotlinkHoleImages}
                  teeData={
                    filteredTeeData && filteredTeeData.length > 0
                      ? filteredTeeData[0]
                      : null
                  }
                />
                  )}
              {disableScorecardMedia || visualSelect !== 'highlights'
                ? null
                : scorecardMediaData &&
                  scorecardMediaData.scorecardMedia && (
                    <ScorecardMediaModule
                      scorecardMedia={scorecardMediaData.scorecardMedia}
                      highlightVideo={highlightVideo}
                      setHoleSelect={setHoleSelect}
                      handleModalOpen={handleModalOpen}
                      modalOpen={modalOpen}
                      selectedVideos={selectedVideos}
                      setSelectedVideos={setSelectedVideos}
                      modalScorecard={false}
                    />
                )}
            </div>
          </div>
        </div>
      </div>
      {modalOpen !== false && (
        <ModalHighlight
          roundGolferRoundScore={roundGolferRoundScore}
          allVideos={scorecardMediaData.scorecardMedia.scorecardVideos}
          bestShotsVideos={scorecardMediaData.scorecardMedia.bestShotsVideos}
          highlightVideo={highlightVideo}
          modalOpen={modalOpen}
          handleModalOpen={handleModalOpen}
          handleModalClose={handleModalClose}
          firstName={golferFirstName}
          lastName={golferLastName}
          image={image}
          overallPar={overallPar}
          scorecardMedia={scorecardMediaData.scorecardMedia}
          selectedVideos={selectedVideos}
          // following are props to pass to ScorecardRound
          round={roundSelect}
          hole10Start={hole10Start || null}
          holeSelect={holeSelect}
          setHoleSelect={setHoleSelect}
          scorecard={scorecardData.scorecard}
          status={status}
          teeData={
            filteredTeeData && filteredTeeData.length > 0
              ? filteredTeeData[0]
              : null
          }
        />
      )}
    </React.Fragment>
  )
}

export default LeaderboardFlyoutModule
