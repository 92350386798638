/* eslint-disable dot-notation */
import React, { useEffect, useState, useRef } from 'react'
import ScorecardTableRowBasic from './ScorecardTableRowBasicReact'
import { useQuery } from '@apollo/client'
import SCORECARD from './../../../../../../graphql/versions/v2/queries/scoring/scorecard.graphql'
import MediaQueries from 'mediaQueries'
import Flickity from 'react-flickity-component'
import { debounce } from 'debounce'

const ScoreCardRoundBasic = props => {
  const mq = new MediaQueries().mq
  const flkty = useRef(null)
  const { round, eventId, golferId, teeData } = props

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth
  })

  useEffect(() => {
    const debouncedHandleResize = debounce(
      150,
      function handleResize () {
        setScreenSize({ width: window.innerWidth })
      },
      true
    )
    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [screenSize])

  let fetchPolicy = 'cache-and-network'

  // temporary for dev because we use the same round 4 data 4 times and react seems
  // to freak out on this
  if (window.location.href.indexOf('localhost') >= -1) {
    fetchPolicy = 'cache-first'
  }

  const {
    error: scorecardError,
    data: scorecardData,
    previousData: previousScorecardData,
    loading: scorecardLoading
  } = useQuery(SCORECARD, {
    fetchPolicy: fetchPolicy,
    errorPolicy: 'all',
    pollInterval: 0,
    variables: {
      id: eventId,
      golferId: golferId,
      round: round
    },
    notifyOnNetworkStatusChange: true
  })

  console.log('scorecard data: ' + (scorecardData ? 'yes' : 'no'))
  console.log(
    'previous scorecard data: ' + (previousScorecardData ? 'yes' : 'no')
  )

  if (!scorecardData && !previousScorecardData) {
    if (scorecardLoading) {
      console.log('Scorecard Loading State rendering')
      return <div>Loading...</div>
    } else {
      console.log('scorecard loading state not rendering, but no data')
    }
  } else {
    console.log('We have some scorecard data')
  }

  if (scorecardError) {
    console.error(scorecardError)
    return <div>An error occurred fetching the scorecard data...</div>
  }

  const scorecard = scorecardData
    ? scorecardData.scorecard
    : previousScorecardData
      ? previousScorecardData.scorecard
      : null

  const scores = []
  const parseScores = () => {
    for (let i = 1; i <= 18; i++) {
      if (scorecard) {
        if (Object.prototype.hasOwnProperty.call(scorecard, 'hole' + i)) {
          const obj = {}
          obj['score'] = scorecard['hole' + i]
            ? scorecard['hole' + i].strokes
            : null
          scores.push(obj)
        }
      }
    }
  }

  parseScores()

  const pars = [
    teeData && teeData.hole1 ? teeData.hole1.par : null,
    teeData && teeData.hole2 ? teeData.hole2.par : null,
    teeData && teeData.hole3 ? teeData.hole3.par : null,
    teeData && teeData.hole4 ? teeData.hole4.par : null,
    teeData && teeData.hole5 ? teeData.hole5.par : null,
    teeData && teeData.hole6 ? teeData.hole6.par : null,
    teeData && teeData.hole7 ? teeData.hole7.par : null,
    teeData && teeData.hole8 ? teeData.hole8.par : null,
    teeData && teeData.hole9 ? teeData.hole9.par : null,
    teeData && teeData.hole10 ? teeData.hole10.par : null,
    teeData && teeData.hole11 ? teeData.hole11.par : null,
    teeData && teeData.hole12 ? teeData.hole12.par : null,
    teeData && teeData.hole13 ? teeData.hole13.par : null,
    teeData && teeData.hole14 ? teeData.hole14.par : null,
    teeData && teeData.hole15 ? teeData.hole15.par : null,
    teeData && teeData.hole16 ? teeData.hole16.par : null,
    teeData && teeData.hole17 ? teeData.hole17.par : null,
    teeData && teeData.hole18 ? teeData.hole18.par : null
  ]

  const outPar =
    teeData &&
    teeData.hole1 &&
    teeData.hole1.par + teeData &&
    teeData.hole2 &&
    teeData.hole2.par + teeData &&
    teeData.hole3 &&
    teeData.hole3.par + teeData &&
    teeData.hole4 &&
    teeData.hole4.par + teeData &&
    teeData.hole5 &&
    teeData.hole5.par + teeData &&
    teeData.hole6 &&
    teeData.hole6.par + teeData &&
    teeData.hole7 &&
    teeData.hole7.par + teeData &&
    teeData.hole8 &&
    teeData.hole8.par + teeData &&
    teeData.hole9 &&
    teeData.hole9.par

  const inPar =
    teeData &&
    teeData.hole10 &&
    teeData.hole10.par + teeData &&
    teeData.hole11 &&
    teeData.hole11.par + teeData &&
    teeData.hole12 &&
    teeData.hole12.par + teeData &&
    teeData.hole13 &&
    teeData.hole13.par + teeData &&
    teeData.hole14 &&
    teeData.hole14.par + teeData &&
    teeData.hole15 &&
    teeData.hole15.par + teeData &&
    teeData.hole16 &&
    teeData.hole16.par + teeData &&
    teeData.hole17 &&
    teeData.hole17.par + teeData &&
    teeData.hole18 &&
    teeData.hole18.par

  const outYards =
    teeData &&
    teeData.hole1 &&
    teeData.hole1.yardage + teeData &&
    teeData.hole2 &&
    teeData.hole2.yardage + teeData &&
    teeData.hole3 &&
    teeData.hole3.yardage + teeData &&
    teeData.hole4 &&
    teeData.hole4.yardage + teeData &&
    teeData.hole5 &&
    teeData.hole5.yardage + teeData &&
    teeData.hole6 &&
    teeData.hole6.yardage + teeData &&
    teeData.hole7 &&
    teeData.hole7.yardage + teeData &&
    teeData.hole8 &&
    teeData.hole8.yardage + teeData &&
    teeData.hole9 &&
    teeData.hole9.yardage
  const inYards =
    teeData &&
    teeData.hole10 &&
    teeData.hole10.yardage + teeData &&
    teeData.hole11 &&
    teeData.hole11.yardage + teeData &&
    teeData.hole12 &&
    teeData.hole12.yardage + teeData &&
    teeData.hole13 &&
    teeData.hole13.yardage + teeData &&
    teeData.hole14 &&
    teeData.hole14.yardage + teeData &&
    teeData.hole15 &&
    teeData.hole15.yardage + teeData &&
    teeData.hole16 &&
    teeData.hole16.yardage + teeData &&
    teeData.hole17 &&
    teeData.hole17.yardage + teeData &&
    teeData.hole18 &&
    teeData.hole18.yardage
  const yardage = [
    teeData && teeData.hole1 && teeData.hole1.yardage,
    teeData && teeData.hole2 && teeData.hole2.yardage,
    teeData && teeData.hole3 && teeData.hole3.yardage,
    teeData && teeData.hole4 && teeData.hole4.yardage,
    teeData && teeData.hole5 && teeData.hole5.yardage,
    teeData && teeData.hole6 && teeData.hole6.yardage,
    teeData && teeData.hole7 && teeData.hole7.yardage,
    teeData && teeData.hole8 && teeData.hole8.yardage,
    teeData && teeData.hole9 && teeData.hole9.yardage,
    teeData && teeData.hole10 && teeData.hole10.yardage,
    teeData && teeData.hole11 && teeData.hole11.yardage,
    teeData && teeData.hole12 && teeData.hole12.yardage,
    teeData && teeData.hole13 && teeData.hole13.yardage,
    teeData && teeData.hole14 && teeData.hole14.yardage,
    teeData && teeData.hole15 && teeData.hole15.yardage,
    teeData && teeData.hole16 && teeData.hole16.yardage,
    teeData && teeData.hole17 && teeData.hole17.yardage,
    teeData && teeData.hole18 && teeData.hole18.yardage
  ]

  const totalPar = outPar + inPar
  const totalYards = outYards + inYards

  const hole = 'Hole'
  const par = 'Par'
  const yards = 'Yards'
  const score = 'Score'
  const totalAbv = 'Tot'
  const inAbv = 'In'
  const outAbv = 'Out'

  if (scorecard) {
    return (
      <React.Fragment>
        <div data-toggle="scorecard" className="ScorecardRound-table">
          <div className="ScorecardRound-tableBody">
            <div className="ScorecardRound-tableHead">
              <div className="ScorecardRound-tableHead-label">
                <span className="ScorecardRound-tableHead-label-text">
                  {hole}
                </span>
              </div>
              <div className="ScorecardRound-tableHead-label">
                <span className="ScorecardRound-tableHead-label-text">
                  {par}
                </span>
              </div>
              <div className="ScorecardRound-tableHead-label yards">
                <span className="ScorecardRound-tableHead-label-text">
                  {yards}
                </span>
              </div>
              <div className="ScorecardRound-tableHead-label score">
                <span className="ScorecardRound-tableHead-label-text">
                  {score}
                </span>
              </div>
            </div>
            {!mq['mq-viewport-md'].matches ? (
              <Flickity
                className={
                  'ScorecardRound-tablesScores ScorecardRound-slides flickity-enabled'
                }
                elementType={'div'}
                flickityRef={carouselRef => {
                  flkty.current = carouselRef
                }}
                options={{
                  adaptiveHeight: true,
                  cellAlign: 'left',
                  wrapAround: false,
                  groupCells: 10,
                  imagesLoaded: true,
                  pageDots: true,
                  prevNextButtons: false,
                  contain: true, // false is creating a gap on right side
                  initialIndex: 1,
                  reloadOnUpdate: true
                }}
              >
                {pars &&
                  pars.map((par, i) =>
                    i === 8 ? (
                      <React.Fragment key={i}>
                        <ScorecardTableRowBasic
                          score={scores[i] ? scores[i].score : null}
                          par={par}
                          key={i}
                          index={i}
                          hole={i + 1}
                          yardage={yardage[i]}
                        />
                        <div className="ScorecardRound-tableColumn-out ScorecardRound-slide">
                          <div className="ScorecardRound-tableColumn-data">
                            {outAbv || '-'}
                          </div>
                          <div className="ScorecardRound-tableColumn-data">
                            {outPar || '-'}
                          </div>
                          <div className="ScorecardRound-tableColumn-data ScorecardRound-tableColumn-yards">
                            {outYards || '-'}
                          </div>
                          <div className="ScorecardRound-tableColumn-data">
                            {scorecard
                              ? scorecard.roundScore.roundOut || '-'
                              : null}
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <ScorecardTableRowBasic
                        score={scores[i] ? scores[i].score : null}
                        par={par}
                        key={i}
                        index={i}
                        hole={i + 1}
                        yardage={yardage[i]}
                      />
                    )
                  )}
                <div className="ScorecardRound-tableColumn-in">
                  <div className="ScorecardRound-tableColumn-data">{inAbv}</div>
                  <div className="ScorecardRound-tableColumn-data">
                    {inPar || '-'}
                  </div>
                  <div className="ScorecardRound-tableColumn-data ScorecardRound-tableColumn-yards">
                    {inYards || '-'}
                  </div>
                  <div className="ScorecardRound-tableColumn-data">
                    {scorecard ? scorecard.roundScore.roundIn || '-' : null}
                  </div>
                </div>
              </Flickity>
            ) : (
              <div className="ScorecardRound-tablesScores ScorecardRound-slides">
                {pars &&
                  pars.map((par, i) =>
                    i === 8 ? (
                      <React.Fragment key={i}>
                        <ScorecardTableRowBasic
                          score={scores[i] ? scores[i].score : null}
                          par={par}
                          key={i}
                          index={i}
                          hole={i + 1}
                          yardage={yardage[i]}
                          round={round}
                        />
                        <div className="ScorecardRound-tableColumn-out ScorecardRound-slide">
                          <div className="ScorecardRound-tableColumn-data">
                            {outAbv}
                          </div>
                          <div className="ScorecardRound-tableColumn-data">
                            {outPar || '-'}
                          </div>
                          <div className="ScorecardRound-tableColumn-data ScorecardRound-tableColumn-yards">
                            {outYards || '-'}
                          </div>
                          <div className="ScorecardRound-tableColumn-data">
                            {scorecard
                              ? scorecard.roundScore
                                  ? scorecard.roundScore.roundOut || '-'
                                  : '-'
                              : '-'}
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <ScorecardTableRowBasic
                        score={scores[i] ? scores[i].score : null}
                        par={par}
                        key={i}
                        index={i}
                        hole={i + 1}
                        yardage={yardage[i]}
                      />
                    )
                  )}
                <div className="ScorecardRound-tableColumn-in">
                  <div className="ScorecardRound-tableColumn-data">{inAbv}</div>
                  <div className="ScorecardRound-tableColumn-data">
                    {inPar || '-'}
                  </div>
                  <div className="ScorecardRound-tableColumn-data ScorecardRound-tableColumn-yards">
                    {inYards}
                  </div>
                  <div className="ScorecardRound-tableColumn-data">
                    {scorecard
                      ? scorecard.roundScore
                          ? scorecard.roundScore.roundIn || '-'
                          : '-'
                      : '-'}
                  </div>
                </div>
              </div>
            )}
            <div className="ScorecardRound-tableColumn-total">
              <div className="ScorecardRound-tableColumn-data">{totalAbv}</div>
              <div className="ScorecardRound-tableColumn-data">
                {totalPar || '-'}
              </div>
              <div className="ScorecardRound-tableColumn-data ScorecardRound-tableColumn-yards">
                {totalYards || '-'}
              </div>
              <div className="ScorecardRound-tableColumn-data">
                {scorecard
                  ? scorecard.roundScore
                      ? scorecard.roundScore.roundTotal || '-'
                      : '-'
                  : '-'}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  } else {
    return null
  }
}
export default ScoreCardRoundBasic
