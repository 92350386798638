import { useRef, useEffect } from 'react'

const Canvas = props => {
  const { draw, width, height } = props
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')

    draw(context)
  })

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      style={{ width: '98%' }}
    />
  )
}

export default Canvas
