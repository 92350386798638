import algoliasearch from 'algoliasearch'
import { useEffect, useState, useCallback } from 'react'
import GetImage from './util/GetImage'

const CoachModule = props => {
  const profileFallbackAlt = 'Coach fallback image'
  const ctaText = 'Meet My Coach'
  const yourCoachInText = 'Your coach in '
  const headshot = 'headshot'

  const {
    apiKey,
    appId,
    description,
    fallbackImage,
    href,
    index,
    logo
  } = props.row.coachItem

  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    city: '',
    state: '',
    profilePhoto: GetImage.src(fallbackImage),
    profileAlt: profileFallbackAlt
  })

  const getClosestCoachByIp = useCallback(
    index => {
      const oneHundredMiles = 100 * 1609 // 160900
      const twentyFiveMiles = 25 * 1609 // 40225
      const getRandomInt = max => Math.floor(Math.random() * max)

      index
        .search('', {
          aroundLatLngViaIP: true,
          aroundRadius: oneHundredMiles,
          aroundPrecision: [{ from: 0, value: twentyFiveMiles }],
          getRankingInfo: true
        })
        .then(({ hits }) => {
          if (!hits || hits.length === 0) return null

          if (hits.length === 1) return hits[0]

          const hitsWithin25Miles = hits.filter(hit => {
            return hit._rankingInfo.geoDistance === 0
          })

          if (hitsWithin25Miles.length === 0) return hits[0]

          const randomHitNumberWithin25Miles = getRandomInt(
            hitsWithin25Miles.length
          )

          const coach = hitsWithin25Miles[randomHitNumberWithin25Miles]

          coach.constructedLink = href
            .replace('{customSlug}', coach.customSlug)
            .replace('{firstName}', coach.firstName)
            .replace('{lastName}', coach.lastName)
          coach.profileAlt =
            coach.firstName + ' ' + coach.lastName + ' ' + headshot
          setData(coach)
        })
    },
    [href]
  )

  useEffect(() => {
    if (!appId || !apiKey) {
      return
    }
    const client = algoliasearch(appId, apiKey)
    const clientIndex = client.initIndex(index)
    getClosestCoachByIp(clientIndex)
  }, [appId, apiKey, index, getClosestCoachByIp])

  return (
    <div className="CoachPromo">
      <div className="CoachPromo-location">
        {yourCoachInText} {data.city}, {data.state}
      </div>
      <div className="CoachPromo-logo">
        <img src={GetImage.src(logo)} alt="logo for coach" />
      </div>
      <div className="LeaderboardRow-player">
        <div className="LeaderboardRow-image">
          <img src={data.profilePhoto} alt={data.profileAlt} />
        </div>
        <a
          className="LeaderboardRow-playerInfo"
          href={data.constructedLink}
          target="_blank"
          rel="noreferrer"
          data-has-flag="false"
        >
          <span className="LeaderboardRow-firstName">{data.firstName}</span>
          &nbsp;
          <span className="LeaderboardRow-lastName">{data.lastName}</span>
        </a>
      </div>
      <div className="CoachPromo-description">{description}</div>
      <a
        className="CoachPromo-cta"
        href={data.constructedLink}
        target="_blank"
        rel="noreferrer"
      >
        <span className="sr-only">{ctaText}</span>
      </a>
    </div>
  )
}

export default CoachModule
